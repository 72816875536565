import { colors } from "@utility/colors";
import { IconProps } from "@utility/types";
import React from "react";

const CartIcon = ({
  width = 25,
  height = 25,
  color = colors.white,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 25 25"
      fill="none"
    >
      <path
        d="M18.8169 6.33685H16.8169C16.8169 4.12685 15.0269 2.33685 12.8169 2.33685C10.6069 2.33685 8.81689 4.12685 8.81689 6.33685H6.81689C5.71689 6.33685 4.81689 7.23685 4.81689 8.33685V20.3369C4.81689 21.4369 5.71689 22.3369 6.81689 22.3369H18.8169C19.9169 22.3369 20.8169 21.4369 20.8169 20.3369V8.33685C20.8169 7.23685 19.9169 6.33685 18.8169 6.33685ZM12.8169 4.33685C13.9169 4.33685 14.8169 5.23685 14.8169 6.33685H10.8169C10.8169 5.23685 11.7169 4.33685 12.8169 4.33685ZM18.8169 20.3369H6.81689V8.33685H8.81689V10.3369C8.81689 10.8869 9.26689 11.3369 9.81689 11.3369C10.3669 11.3369 10.8169 10.8869 10.8169 10.3369V8.33685H14.8169V10.3369C14.8169 10.8869 15.2669 11.3369 15.8169 11.3369C16.3669 11.3369 16.8169 10.8869 16.8169 10.3369V8.33685H18.8169V20.3369Z"
        fill={color}
      />
    </svg>
  );
};

export default CartIcon;
