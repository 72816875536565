import authSlice from "./authSlice";
import orderSlice from "./orderSlice";
import productSlice from "./productSlice";
import voucherSlice from "./voucherSlice";
import provinceSlice from "./provinceSlice";


export const rooteReducer = {
    auth: authSlice,
    products: productSlice,
    vouchers: voucherSlice,
    orders: orderSlice,
    province: provinceSlice
}