
import { useLocalStorage } from "@hooks/useLocalStorage"
import AuthService from "@services/AuthServices"
import CartServices, { CartItemRequest } from "@services/CartServices"
import ProductServices from "@services/ProductServices"
import { ProductColor, ProductItem, ProductSize } from "@services/ProductServices"
import { createContext, ReactNode, useContext, useEffect, useState } from "react"

type CartProviderProps = {
  children: ReactNode
}

export type CartItemNew = {
  productId: string,
  productDetailId: string,
  productDetailNameKr: string,
  productDetailNameVn: string,
  image: string,
  priceDetail: number,
  promoDetail: number,
  actualPriceDetail: number,
  stockQuantity: number,
  soldQuantity: number,
  addressWarehouse: string,
  imageDetailUrl: string,
  attributes: any,
  attributesKr: any,
  choose?: boolean,
  quantitySelected: number
}

type CartContext = {
  getItemQuantity: (id: string) => number
  chooseProduct: (id: string, status: boolean) => void
  onChangeItem: (id: string, itemNew: CartItemNew, oldItem?: CartItemNew) => void
  cartQuantity: number
  totalPriceChoose: number
  cartItems: CartItemNew[],
  setCartItems: (cartItems: CartItemNew[]) => void,
  handleSaveCartToServer: (cartItems: CartItemNew[]) => void,
  setCartItemsLocalStorage: (cartItems: CartItemNew[]) => void
}

const CartContext = createContext({} as CartContext)

export function useCart() {
  return useContext(CartContext)
}

export function CartProvider({ children }: CartProviderProps) {
  const [cartItemsLocalStorage, setCartItemsLocalStorage] = useLocalStorage<CartItemNew[]>(
    "supershop-cart",
    []
  )

  const [cartItems, setCartItems] = useState<CartItemNew[]>([])

  useEffect(() => {
    if (AuthService.isLoggedIn()) {
      handleGetCartFromServer();
    } else {
      setCartItems(cartItemsLocalStorage);
    }
  }, [])

  useEffect(() => {
    setCartItemsLocalStorage(cartItems)
    const updateCart = () => {
      if (AuthService.isLoggedIn()) {
        handleSaveCartToServer(cartItems);
      }
    };
    const delay = 1000;
    const timer = setTimeout(updateCart, delay);
    return () => clearTimeout(timer);
  }, [cartItems])

  const handleGetCartFromServer = async () => {
    const cartIts = await CartServices.getAllCartItems();
    const newCartItems = cartIts.map((cartIt: CartItemNew) => {
      return {
        ...cartIt,
        attributes: cartIt.attributes.map((it: any) => {
          return {
            attributeNameVn: it.attributeNameVn,
            valueVn: it.valueVn
          }
        }),
        attributesKr: cartIt.attributes.map((it: any) => {
          return {
            attributeNameKr: it.attributeNameKr,
            valueKr: it.valueKr
          }
        })
      }
    })
    const arr = mergedCart(cartItemsLocalStorage, newCartItems)
    setCartItems(arr)
  }

  const mergedCart = (cartItemsLocal: CartItemNew[], cartItemsServer: CartItemNew[]) => {
    try {
      const uniqueValues = [...cartItemsLocal, ...cartItemsServer];
      const ids = uniqueValues.map((cart) => cart.productDetailId);
      const filtered = uniqueValues.filter((cart, index) =>
        !ids.includes(cart.productDetailId, index + 1));
      return filtered;
    } catch (error) {
      console.error("Error merging carts:", error);
      throw error;
    }
  };

  const handleSaveCartToServer = async (cartItems: CartItemNew[]) => {
    try {
      await CartServices.changeCartItems(cartItems)
    } catch (error) {
      console.log(error);
    }
  }

  const totalPriceChoose = cartItems.reduce(
    (price, item) => (item.choose ? item.quantitySelected * item.actualPriceDetail : 0) + price,
    0
  )

  const cartQuantity = cartItems.length

  function getItemQuantity(id: string) {
    return cartItems.find(item => item.productDetailId === id)?.quantitySelected || 0
  }

  function onChangeItem(id: string, itemNew: CartItemNew) {
    var cartItemsNew: CartItemNew[] = [];
    if (itemNew.quantitySelected <= 0) {
      cartItemsNew = cartItems.filter(item => item.productDetailId != id)
    } else if (cartItems.find(item => item.productDetailId == id) == null) {
      cartItemsNew = [...cartItems, { ...itemNew }]
    } else {
      cartItemsNew = [...cartItems.map(item => {
        if (item.productDetailId === id) {
          return { ...itemNew }
        } else {
          return item
        }
      })]
    }
    setCartItems(cartItemsNew)
  }

  function chooseProduct(id: string, status: boolean) {
    const cartItemsNew = cartItems.map(item => {
      if (item.productDetailId === id) {
        return { ...item, choose: status }
      } else {
        return item
      }
    })
    setCartItems(cartItemsNew)
  }

  return (
    <CartContext.Provider
      value={{
        getItemQuantity,
        chooseProduct,
        onChangeItem,
        cartItems,
        totalPriceChoose,
        cartQuantity,
        setCartItems,
        handleSaveCartToServer,
        setCartItemsLocalStorage
      }}
    >
      {children}
    </CartContext.Provider>
  )
}