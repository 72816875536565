import SearchProductCard from "@components/Card/SearchProductCard";
import { Link, useNavigate } from "react-router-dom";
import { memo, useCallback, useEffect, useState } from "react";
import ProductServices, {
  ProductItemV2,
  ResponseProductItems,
} from "@services/ProductServices";
import useI18n from "@hooks/useI18n";
import { ROUTES } from "@utility/constants";
import { debounce } from "lodash";
import LoadingPage from "@components/LoadingPage";
import CloseIcon from "@assets/iconElements/CloseIcon";
import { ItemProps, ItemResearch } from "@utility/types";

interface Props {
  close: () => void;
  setKeyword: (item: any) => void;
  keyword: string;
  show: Boolean
}
const ResultItem = memo(({ name, onDeleteItem }: ItemProps) => {
  return (
    <p className="px-4 w-fit py-1 text_base flex gap-1 border border-aqua-aq02 rounded-full">
      {name}
      <span className="cursor-pointer" onClick={onDeleteItem}>
        <CloseIcon />
      </span>
    </p>
  );
});

const ItemProductSearch = memo(({ item, handleclick }: ItemResearch) => {
  const { t } = useI18n();
  return (
    <div
      onClick={handleclick}
      className="max-w-scr268 flex items-center gap-1 bg-white rounded-lg shadow-shd020  xl:w-[280px] "

    >
      <img
        src={
          item.image ||
          "https://inanbaobihcm.com/upload/images/m%E1%BA%ABu%20h%E1%BB%99p%20tr%C3%A0%20%C4%91%E1%BA%B9p(1).jpg"
        }
        alt=""
        className="w-spc80 h-spc80 rounded-l-lg"
      />
      <div className="w-[1px] h-[55px] border-l border-l-gray-200 p-1 "></div>
      <div className="px-2 max-w-2/3 flex flex-col justify-between">
        <p className="text_base line-clamp-2">{item.name}</p>
        <p className="text-sm font-bold text-main">
          {item.price} {t("global.currency")}
        </p>
      </div>
    </div>
  );
});
const SearchDropdown = ({ keyword, close, setKeyword, show }: Props) => {
  const { t } = useI18n();

  const [loading, setloading] = useState(false);
  const [productResponse, setproductResponse] =
    useState<ResponseProductItems>();
  const listKeyHistory = localStorage.getItem("history-research");
  const pareKey = listKeyHistory ? JSON.parse(listKeyHistory) : [];
  const [listHistoryResearch, setHistoryResearch] = useState<string[]>(pareKey);
  const json = localStorage.getItem("search");
  const [listProduct, setListProduct] =
    useState<any[]>([]);
  const handleSearch = (value: string) => {
    if (value == "" || listHistoryResearch.includes(value)) return;
    localStorage.setItem(
      "history-research",
      pareKey
        ? JSON.stringify([...listHistoryResearch, value])
        : JSON.stringify([value])
    );
  };
  const [history, setHistory] = useState<any>([]);
  const navigate = useNavigate();
  const handleDeleteHistrKey = (index: number) => {
    const newHistory = [...listHistoryResearch];
    newHistory.splice(index, 1);
    setHistoryResearch(newHistory);
    localStorage.setItem("history-research", JSON.stringify(newHistory));
  };
  const callSearchApi = (key: string) => {
    handleSearch(key);
    ProductServices.searchHeader({ page: 0, size: 5, keyword: key })
      .then((data) => {
        setproductResponse(data);
      })
      .finally(() => {
        setloading(false);
      });
  };
  const debounceDropDown = useCallback(
    debounce((key) => callSearchApi(key), 700),
    []
  );
  const { isVn } = useI18n();

  const getApi = () => {

    ProductServices.searchPoduct({
      "page": 0,
      "size": 10,
      "sort": "id,desc",
      "keyword": keyword,
      "address": json === null ? "Thành phố Hà Nội" : JSON.parse(json).name
    }).then(data => {
      // handleCountProduct(data.totalElements)
      setListProduct(data.content)
      console.log(data)
    })

  }

  useEffect(() => {
    setHistory(json ? JSON.parse(json) : [])
    let timer = setTimeout(() => {

      if (keyword)
        getApi()

    }, 500)
    return () => clearTimeout(timer)
  }, [keyword]);

  const removeHistory = (to: any) => {
    if (history.length > 0) {
      const arr = history.filter((item: any) => item !== to)
      setHistory(arr)
      localStorage.setItem("search", JSON.stringify(arr))
    }
  }
  const handleHistory = (item: any) => {
    console.log("item", item)
    close();
    setKeyword(item)
    navigate("/search/" + item)
  }
  const handleProduct = (item: any) => {
    close();
    setKeyword("")
    navigate("/product/" + item.id)
  }



  return (
    <div className="header__dropdown_shadow bg-gray-100 fixed pt-4 pb-6 left-0 top-[127px] w-screen xl:h-spc300 sm:h-spc510 shadow-shd020 z-max">

      {
        show && <div className="xl:w-2/3 w-3/4 max-w-scr970 mx-auto pb-2 ">
          <p className="text-lg font-bold text-black-bl01">
            {t("header.search.history")}
          </p>
          <div className="py-3 flex items-center gap-4 flex-wrap">
            {history.map((item: any) => <p className="px-4 w-fit py-1 text_base flex gap-1 border border-aqua-aq02 rounded-full">
              <p className="hover:text-main cursor-pointer" onClick={() => handleHistory(item)}>{item}</p>
              <span className="cursor-pointer hover:text-main" onClick={() => removeHistory(item)} >
                <CloseIcon />
              </span>
            </p>)}
          </div>
        </div>
      }
      {
        !show &&
        <div className="xl:w-2/3 w-3/4 max-w-scr970 mx-auto pt-10px ">
          <div className="">
            {listProduct.length === 0 ? t("global.search-size") :
              <p className="pt-[0px] max-md:text-[14px] text-[18px]">  <b>{t("global.search")}</b> {listProduct.length} {t("global.footer.produc_special.index")} </p>
            }
            <div className="flex flex-col w-full mt-[10px]  xl:h-spc230 overflow-y-auto pr-[5px]">
              {listProduct.map((item) => <div className="w-full px-4 items-center mb-[7px] justify-start h-[90px]  py-1 text_base flex  gap-1 rounded-[20px] bg-white cursor-pointer"
                onClick={() => handleProduct(item)}>
                <img src={item.images[0].url} alt="" className="w-[80px] h-[70px]" />
                <div className="flex flex-col items-start justify-center pl-[5px]">
                  <p className="cursor-pointer text-[14px]"  >{isVn ? item.productNameVn : item.productNameKr}</p>
                  <p className="text-main text-[14px]">{item.price} đ</p>
                </div>
              </div>)}
            </div>


          </div>
        </div>
      }

      <div>
      </div>
    </div>
  );
};

export default SearchDropdown;
