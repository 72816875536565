import { Link, useNavigate, useSearchParams } from "react-router-dom";
import CategoryDropdown from "./CategoryDropdow";
import useFocusOut from "@hooks/useFocusOut";
import { firstUpper, some } from "@utility/helper";
import { useState, useEffect } from "react";
import clsx from "clsx";
import { ROUTES } from "@utility/constants";
import { CategoryHeader } from "./Header";
import { CategoryType } from "@services/Types/category";
import useI18n from "@hooks/useI18n";

interface Props {
  data: CategoryType;
}

const CategoryItem = ({ data }: Props) => {
  const { isVn } = useI18n();
  const { hoverShow, setHoverShow, ref: dropdownRef } = useFocusOut();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [navbarParam, setNavbarParam] = useState<string>("");

  useEffect(() => {
    setNavbarParam(searchParams.get("navbar") || "");
  }, [searchParams]);

  // const handleClickParrent = () => {
  //   navigate(`${ROUTES.search.filter}?navbar=` + data.text);
  // };

  return (
    <div
      className={clsx(
        "py-3 flex items-center justify-center text-normal1 w-[250px] truncate hover:text-main border-b-2 hover:border-b-main cursor-pointer",
        {
          // "text-main border-b-main": navbarParam == data.text,
          // "border-b-transparent": navbarParam != data.text,
        }
      )}
      ref={(ref) => (dropdownRef.current = ref)}
    >
      <p className="font-semibold normal-case"
      // onClick={handleClickParrent}

      >
        <Link to={`/category/${data.id}`}>{isVn ? firstUpper(data.categoryNameVn) : firstUpper(data.categoryNameKr)}</Link>
      </p>
      {
        data.subCategoryList.length > 0 && (hoverShow && <CategoryDropdown items={data.subCategoryList} navbar={data.id} name={data.id} />)
      }
    </div>
  );
};

export default CategoryItem;
