import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ProductItem } from "@services/Types/product";
import { IRespone } from "@services/Types/respone";
import { ListProductType, Product } from "commons/contannt";
import { ThunkProduclist } from "redux/thunk/productAction";

interface initialProps {
    filter: boolean,
    isloading: boolean,
    currentPage: number,
    totalElement: number,
    listProducts: ProductItem[],
    error: string | null
}

const initialState: initialProps = {
    filter: true,
    isloading: false,
    currentPage: 1,
    totalElement: 0,
    listProducts: [],
    error: null
}

const produtcSlice = createSlice({
    name: 'productSlice',
    initialState,
    reducers: {
        setCurrenPage: (state, actions: PayloadAction<number>) => {
            state.currentPage = actions.payload
        },
        setFilter: (state, actions: PayloadAction<boolean>) => {
            state.filter = actions.payload
            state.currentPage = 1
        },
        setLisProduct: (state, actions: PayloadAction<any>) => {
            const { content, totalElements } = actions.payload
            // state.currentPage = pageNumber
            // state.filter = true
            state.listProducts = content
            state.totalElement = totalElements
        }
    },
    extraReducers: (builder) => {
        builder.addCase(ThunkProduclist.fulfilled, (state, actions: PayloadAction<IRespone>) => {
            const { content, totalElements } = actions.payload
            state.isloading = false;
            state.totalElement = totalElements;
            state.listProducts = content
            state.error = null
        })
        builder.addCase(ThunkProduclist.pending, (state, actions: PayloadAction<any>) => {
            state.isloading = true
        })
        builder.addCase(ThunkProduclist.rejected, (state, actions: PayloadAction<any>) => {
            state.isloading = false
            state.error = "Có lỗi không thể lấy dữ liệu"
        })
    }
});

export const { setCurrenPage, setFilter, setLisProduct } = produtcSlice.actions
export default produtcSlice.reducer