export const HELPER = {}

export interface some {
    [key: string]: any,
}


export function firstUpper(str: string) {
    if (!str || str.length == 0) return "";
    return str.charAt(0).toUpperCase() + str.slice(1).toLocaleLowerCase();
}

export function formatMoney(price: number): string {
    if (typeof price !== 'number' || isNaN(price)) {
        return '0₫';
    }

    // Định dạng số tiền thành chuỗi và thêm ký tự đồng "₫"
    const formattedPrice = price.toLocaleString('en-EN') + '₫';

    return formattedPrice;
}

export const nl2br = (text: string) => {
    if (!text) {
        return text;
    }

    return text.replace(/(?:\r\n|\r|\n)/g, "<br />");
};