import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import clsx from "clsx";
import SwiperComponent from "commons/SwiperComponent";
import { memo, useEffect, useState } from "react";
import { Autoplay, Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import CardItemSlideCategory from "./CardItemSlideCategory";
import ProductFeaturedSlide from "@components/product/ProductFeaturedSlide";
import { Link } from "react-router-dom";
import useI18n from "@hooks/useI18n";

interface Props {
    size?: number;
    row?: number;
    classPaginate?: string;
    breackPoin?: any;
    slideItems: any[];
    slideFeatured: any[];
}
const breack = {
    350: {
        slidesPerView: 2.5,
        spaceBetween: 8,
    },
    768: {
        slidesPerView: 3.5,
        spaceBetween: 26,
    },
    1280: {
        slidesPerView: 3.5,
        spaceBetween: 26,
    },
};

export const SwiperCategory = memo(({ slideFeatured }: Props) => {
    const {
        navigationPrevRef,
        navigationNextRef,
        NavigationElement,
        onActiveIndexChange,
        currentIndex
    } = useSwiperNavigationRef();

    const [currentItemId, setCurrentItemId] = useState<number>();
    const { t } = useI18n();

    useEffect(() => {
        const item = slideFeatured[0];
        if (item) {
            setCurrentItemId(item.id)
        }
    }, [slideFeatured])

    useEffect(() => {
        const item = slideFeatured[currentIndex];
        if (item) {
            setCurrentItemId(item.id)
        }
    }, [currentIndex])

    return (
        <div className="w-[90%] xl:h-2/3 h-5/6 p-3">
            <SwiperComponent
                onActiveIndexChange={onActiveIndexChange}
                navigationNextRef={navigationNextRef}
                navigationPrevRef={navigationPrevRef}
                slidesPerView={1}
                className={clsx("w-full h-full", {})}
                autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                }}
                modules={[Autoplay, Pagination, Navigation]}
            >

                {slideFeatured.map((item: any, index: number) => {
                    return (
                        <SwiperSlide key={index} className="w-full overflow-y-auto hiddenScroll">
                            <ProductFeaturedSlide item={item} stt={index} />
                        </SwiperSlide>
                    );
                })}

            </SwiperComponent>
            {slideFeatured.length > 0 && <>{NavigationElement}</>}
            <div className="hidden xl:block xl:relative xl:z-1">
                <div className="flex mt-3 justify-end gap-1">
                    {
                        slideFeatured.map((item, index) => {
                            return (
                                <div>
                                    <svg width="18" height="4" viewBox="0 0 22 4" fill={currentIndex === index ? "#FF7D03" : "#C4C4C4"} xmlns="http://www.w3.org/2000/svg">
                                        <rect width="18" height="4" rx="2" />
                                    </svg>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            <div className="xl:mt-16 mt-2  border border-[#00C3AB] rounded-lg w-fit px-8 py-2 font-bold text-[#00C3AB]">
                <Link to={`/product/${currentItemId}`}>
                    {t("text.button.see_more")}
                </Link>
            </div>

        </div>
    )
})


const SlideCategory = memo(({ breackPoin, slideItems, slideFeatured }: Props) => {
    const {
        navigationPrevRef,
        navigationNextRef,
        NavigationElement,
        onActiveIndexChange,
        currentIndex
    } = useSwiperNavigationRef();

    return (
        <div className={"relative xl:flex gap-4"}>
            <div className="relative xl:w-2/6 xl:bg-inherit xl:h-[450px] h-[330px] cursor-pointer bg-[#DAF1E7] rounded-tl-lg mb-4">
                <SwiperCategory slideFeatured={slideFeatured} breackPoin={breackPoin} slideItems={slideItems} />
            </div>
            <div className="xl:w-4/6 xl:mt-0 mt-10 xl:ml-0 ml-10">
                <SwiperComponent
                    onActiveIndexChange={onActiveIndexChange}
                    navigationNextRef={navigationNextRef}
                    navigationPrevRef={navigationPrevRef}
                    breakpoints={breackPoin ? breackPoin : breack}
                    slidesPerView={1}
                    className={clsx("w-full h-full", {})}
                    spaceBetween={12}
                    autoplay={{
                        delay: 3000,
                        disableOnInteraction: false,
                    }}
                    modules={[Autoplay, Pagination, Navigation]}
                >

                    {slideItems.map((item: any, index: number) => {
                        return (
                            <SwiperSlide key={index} className="max-sm:h-[202px] mb-2">
                                <CardItemSlideCategory key={index} item={item} stt={index} />
                            </SwiperSlide>
                        );
                    })}
                </SwiperComponent>
                {slideItems.length > 0 && <>{NavigationElement}</>}
            </div>
            <div className="hidden xl:block ">
                <div className="absolute top-0 left-0 w-full h-full  bg-[#DAF1E7] rounded-lg xl:h-3/4 ">
                </div>
            </div>
        </div>
    )

})

export default SlideCategory