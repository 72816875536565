import TitleSession from "@components/common/TitleSession";
import SocialHelpBox from "commons/components/SocialHelpBox";
import React, { memo, useCallback, useContext, useEffect, useRef, useState, ReactNode } from "react";
import { RadioButton } from "@components/AdminComponents/VoucherEditComponent";
import SortProduct from "@components/filters/product/SortProduct";
import useI18n from "@hooks/useI18n";
import CardItem from "@components/Card/CardItem";
import ProductServices, { ProductItemV2, ResponseProduct } from "@services/ProductServices";
import { IRespone } from "@services/Types/respone";
import DynamicButton from "@components/Buttons/DynamicButton";
import { useParams } from "react-router-dom";

const SearchPageNew = () => {

    // const [choose, setChoose] = useState<string>("id,desc");
    const { t } = useI18n();


    const [listProduct, setListProduct] = useState<ProductItemV2[]>([]);
    const [page, setPage] = useState<number>(0);
    const [totalPage, setTotalPage] = useState<number>(0);
    const [count, setCount] = useState<number>(0);
    const param = useParams();
    const key = param.key;
    useEffect(() => {
        const json = localStorage.getItem("province");

        const search = localStorage.getItem("search");
        if (search === null || search.length === 0) {
            let arr = Array();
            console.warn("hello")
            arr.push(key)
            localStorage.setItem("search", JSON.stringify(arr));
        } else {
            const arr = JSON.parse(search).filter((item: any) => item === key)
            if (arr.length === 0) {
                let last = JSON.parse(search).concat(key)
                localStorage.setItem("search", JSON.stringify(last))
            }
        }


        ProductServices.searchPoduct({
            "page": page,
            "size": 8,
            "sort": "id,desc",
            "keyword": key,
            "address": json === null ? "Thành phố Hà Nội" : JSON.parse(json).name
        }).then(data => {
            setCount(data.totalElements)
            // handleCountProduct(data.totalElements)
            setTotalPage(data.totalPages)
            page === 0 ? setListProduct(data.content) :
                setListProduct([...listProduct, ...data.content])
        })

    }, [page, key])



    const onShow = () => {
        setPage(page + 1);
    }

    return (


        <>

            <div className="product_box max-md:pt-[20px] pt-[40px] min-h-[800px] bg-gray-100">
                <p className="pt-[20px] max-md:text-[14px] text-[18px]">    <b>{t("global.search")}</b> {count} {t("global.footer.produc_special.index")} </p>
                <hr className="w-full h-[2px] bg-main" />

                <div className="grid grid-cols-4 gap-4 pt-[30px] max-md:pt-[15px] max-lg:grid-cols-3 max-sm:grid-cols-2">
                    {
                        listProduct && listProduct.map((item) => {
                            return <CardItem item={item} />
                        })
                    }
                </div>

                <div className="flex justify-center pt-[30px]  ">
                    {(page + 1 < totalPage) &&
                        <DynamicButton
                            onClick={() => onShow()}
                            // (page > totalPage) > ? t('button.hide_away') : 
                            text={t('button.see_more')}
                            className="!rounded-[30px] !px-[6px] !py-2 !min-w-[142px] !w-[142px] text-[16px] leading-normal font-bold font-NunitoSans"
                        />
                    }
                </div>

                {/* <SlideProducts typeSlide="sale" row={2} size={4} categoryId={categoryId} />
      {isBtn && <SocialHelpBox />} */}
            </div>
        </>


    );
}
// );

export default SearchPageNew;
