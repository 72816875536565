import CartProductNew from "@components/Cart/CartProductNew";
import BtnLoading from "@components/btn-loading/BtnLoading";
import { useCart } from "@contexts/CartContext";
import { ToastContex } from "@contexts/ToastContex";
import useI18n from "@hooks/useI18n";
import { formatMoney } from "@utility/helper";
import clsx from "clsx";
import { useState, useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { PaymentCartIcon, RemoveCartIcon, TickIcon } from "@assets/icons";
import VoucherServices from "@services/voucherService";
import { IVoucher } from "@services/Types/voucher";
import AuthService from "@services/AuthServices";

const CartPage = () => {

  const { t } = useI18n()
  const navigate = useNavigate()
  const { onAddToast } = useContext(ToastContex);
  const { cartQuantity, totalPriceChoose, cartItems, setCartItems } = useCart()
  const [voucherSelected, setVoucherSelected] = useState<string>("")
  const [moneyVoucher, setmoneyVoucher] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [checked, setChecked] = useState<boolean>(false);
  const [listVouchers, setListVouchers] = useState<IVoucher[]>([]);
  // const [selectedVoucherId, setSelectedVoucherId] = useState<number>();

  const callApiCheckVoucher = async () => {
    try {
      const ids = cartItems.map((it) => it.productDetailId);
      const res: any = await VoucherServices.getVoucherOrder(ids, totalPriceChoose);
      if (res) {
        setListVouchers(res)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleApplyVoucher = async (voucher?: IVoucher) => {
    if (voucher?.voucherCode != voucherSelected || voucher == null) {
      setVoucherSelected("")
      setmoneyVoucher(0)
    } else {
      if (voucher.total <= 0) {
        onAddToast({ type: "warn", message: t("cart.payment_info.voucher_sold") });
        return
      }
      setVoucherSelected(voucher.voucherCode)
      if (totalPriceChoose >= voucher.minBill) {
        if (voucher.typeVoucher === "MONEY") {
          setmoneyVoucher(voucher.maxValue > 0 ? (voucher.value <= voucher.maxValue ? voucher.value : voucher.maxValue) : voucher.value)
        } else {
          const priceVoucher = totalPriceChoose * (voucher.value / 100);
          setmoneyVoucher(voucher.maxValue > 0 ? (priceVoucher <= voucher.maxValue ? priceVoucher : voucher.maxValue) : priceVoucher)
        }
      } else {
        setmoneyVoucher(0)
      }
    }
  }

  useEffect(() => {
    const selectedItems = cartItems.filter(item => item.choose);
    if (selectedItems.length === cartItems.length) {
      setChecked(false);
    } else {
      setChecked(true);
    }
    callApiCheckVoucher();
  }, [cartItems]);

  const handleClickToPayment = () => {
      let c = cartItems.filter(c => c.choose)
      if (c && c.length > 0) {
        navigate("/cart/payment", { state: { voucher: voucherSelected || "", moneyVoucher: moneyVoucher } })
      } else {
        onAddToast({ type: "warn", message: t("cart.payment_info.required_select_product") });
      }
  }

  const handleSetChecked = () => {
    setChecked(!checked);
    const updatedCartItems = cartItems.map((cartItem) => {
      return {
        ...cartItem,
        choose: cartItem.stockQuantity > 0 ? checked : false,
      };
    });
    setCartItems(updatedCartItems);
  };

  const handleCheckVoucher = () => {
    if (!voucherSelected) {
      onAddToast({ type: "warn", message: t("cart.payment_info.required_voucher_code") });
    } else {
      callApiCheckVoucher()
      const voucherSelect = listVouchers.find((v) => v.voucherCode == voucherSelected)
      if (voucherSelect && voucherSelect.total > 0) {
        handleApplyVoucher(voucherSelect)
      } else {
        onAddToast({ type: "warn", message: t("cart.payment_info.entered_wrong_voucher") });
      }
    }
  }

  const handleRemoveCart = () => {
    setCartItems([])
  }

  return (
    <div className="container  px-4 lg:px-8" >
      <div className="mt-6 font-['Nunito_Sans'] ">
        {t("cart.cart_title")}
      </div>
      <div className="rounded-lg pt-6 flex flex-col xl:flex-row xl:gap-10">
        <div className="flex-1 rounded-[20px]">
          {cartItems.length > 0 &&
            <div className="lg:grid lg:grid-cols-[300px_200px_100px_100px_80px_100px]  pl-1 justify-between items-center rounded-lg border py-3 bg-white">
              <div className="flex justify-between items-center">
                <div className="flex flex-cols ">
                  <input
                    className="hidden"
                    type="checkbox"
                  />
                  <div onClick={() => handleSetChecked()}
                    className={`w-6 h-6 sm:w-7 sm:h-7 aspect-square p-1  border-2 flex items-center justify-center  rounded-sm ${!checked ? "bg-main" : ""}`} >
                    <TickIcon />
                  </div>
                  <div className="p-1 ml-2 truncate">
                    {t("cart.payment_info.label_total_items", { total: cartItems.length })}
                  </div>
                </div>
                <div className="visible lg:invisible flex justify-end">
                  <RemoveCartIcon onClick={handleRemoveCart} />
                </div>
              </div>
              <div className="invisible lg:visible lg:h-auto h-[0]"><span>{t("cart.payment_info.title_classify")}</span></div>
              <div className="invisible lg:visible lg:h-auto h-[0]">{t("cart.payment_info.title_price_per_unit")}</div>
              <div className="invisible lg:visible lg:h-auto h-[0]">{t("cart.payment_info.title_total_quantity")}</div>
              <div className="invisible lg:visible lg:h-auto h-[0]">{t("cart.payment_info.title_total_price_per_product")}</div>
              <div className="lg:visible invisible lg:h-auto flex justify-end lg:h-auto h-[0]">
                <RemoveCartIcon onClick={handleRemoveCart} />
              </div>
            </div>
          }
          <div className="flex-1 rounded-lg my-2 bg-white border relative">
            <div className="overflow-y-auto max-h-[500px] rounded-lg hiddenScroll" >
              {cartQuantity > 0 ? cartItems.map((it, idx) => (
                <div key={it.productDetailId + idx} className="">
                  <div className="hidden lg:block">
                    <CartProductNew itemCart={it} size="normal" />
                  </div>
                  <div className="block lg:hidden">
                    <CartProductNew itemCart={it} size="cart" />
                  </div>
                </div>
              )) : (
                <div className="w-full flex justify-center mt-8">
                  <img src="/images-raw/empty-cart.png" alt="empty-cart" />
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="py-4 px-3 w-full xl:w-1/4 bg-white rounded-md h-fit mt-8 lg:mt-0">
          <div className="bg-[#DAF1E7] rounded-md p-3 mb-4">
            <p className="text-wap-regular2 text-text mb-2 font-bold">{t("cart.payment_info.title")}</p>
            <div className="h-8 flex gap-1">
              <div className="flex-1">
                <input type="text" value={voucherSelected} onChange={e => setVoucherSelected(e.target.value)} name="promotion" className="w-full h-full text-text rounded-lg border bg-[#DAF1E7] placeholder:text-[#626262] text-wap-regular2 px-2" placeholder={t("cart.payment_info.placehoder_promotion") || ""} />
              </div>
              <BtnLoading
                onClick={handleCheckVoucher}
                isLoading={isLoading}
                className={clsx("btn text-wap-regular2 rounded-lg bg-header px-2 w-fit h-full text-center text-white", {
                  "opacity-70 cursor-default": isLoading
                })}
              > {t("cart.payment_info.promotion_btn")}</BtnLoading>
            </div>
          </div>
          <div className="flex flex-wrap  mb-4">
            {
              listVouchers && listVouchers.length > 0 &&
              listVouchers.map((voucher, index) => {
                return (
                  <p key={index} onClick={() => {
                    setVoucherSelected(voucher.voucherCode)
                    if (voucher.voucherCode !== voucherSelected) {
                      setmoneyVoucher(0)
                    }
                  }} className={`px-4 w-fit py-1 m-2 text_base flex gap-1 border border-aqua-aq02 rounded-full ${voucher.voucherCode === voucherSelected ? "bg-[#DAF1E7]" : ""}`}>
                    <span className="cursor-pointer">
                      {voucher.voucherName}
                    </span>
                  </p>
                )
              })
            }
          </div>

          <div className="flex justify-between pb-4 ">
            <h1 className="text-wap-regular2  text-text">
              {t("cart.payment_info.total_price_title")}
            </h1>
            <div className="text-wap-regular2 text-black">
              {formatMoney(totalPriceChoose)}
            </div>
          </div>
          <div className="flex justify-between pb-4 ">
            <h1 className="text-wap-regular2  text-text">
              {t("cart.payment_info.total_discount")}
            </h1>
            <div className="text-wap-regular2 text-main">
              {formatMoney(moneyVoucher)}
            </div>
          </div>
          <div className="flex justify-between py-4 mb-6 border-t-[1px] border-gray-100">
            <h1 className="text-wap-regular2  text-text font-semibold">
              {t("cart.payment_info.total_price")}
            </h1>
            <div className="text-normal text-black font-semibold">
              {formatMoney(totalPriceChoose - moneyVoucher < 0 ? 0 : totalPriceChoose - moneyVoucher)}
            </div>
          </div>
          <button className={clsx("btn text-normal font-medium rounded-lg bg-header w-full h-9 text-center text-white my-3", {
            "opacity-60 cursor-default": cartQuantity <= 0
          })} onClick={handleClickToPayment}>
            <PaymentCartIcon />
            {t("cart.payment_info.btn_process_payment")}
          </button>
          <Link to={"/"} className="btn text-main font-bold rounded-lg border-[#00c3ab] border-2 w-full h-9 text-center text-black ">
            {t("cart.payment_info.btn_buy_more")}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CartPage;
