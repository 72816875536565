import {
  ArrowForward,
  Bct,
  // BocongthuonIcon,
  FaceRound,
  FacebookFilledIcon,
  HotlineIcon,
  InstagramOutlineIcon,
  LocationIcon,
  Logo,
  MailOutlineIcon,
  PhoneOutlineIcon,
  SendIcon,
  TikTokIcon,
  WebsiteOutlineIcon,
  WhiteLogo,
  YoutubeOutlineIcon,
  YtbRound,
  Instagram,
  Facebook,
  Shoppe,
  LogoPlusMark
} from "@assets/icons";
import ContactFooterForm from "@components/Form/ContactFooterForm";
import useI18n from "@hooks/useI18n";
import FooterServices, {
  ContentFooter,
  ResponseFooter,
} from "@services/FooterService";
import PolicyServices, { PagePolicy } from "@services/PolicyServices";
import { IRespone } from "@services/Types/respone";
import { ROUTES } from "@utility/constants";
import { firstUpper } from "@utility/helper";
import { data } from "autoprefixer";
import clsx from "clsx";
import { memo, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

interface Props { }
interface SubProps {
  typeBox: "face" | "instar" | "shopee";
  counts?: number;
  onClickForWard: () => void;
}
const SubFooterBox = memo(({ typeBox, counts, onClickForWard }: SubProps) => {
  const { t } = useI18n();

  const navigator = useNavigate();

  return (
    <div className="max-xl:mt-[20px] max-xl:w-full max-xl:px-[5%]  ">
      <p className="text-[14px] text-white font-normal pb-[18px]">
        {t(
          typeBox == "face"
            ? "global.footer.faceb_message"
            : typeBox == "instar"
              ? "global.footer.instar_message"
              : "global.footer.shopee_message"
        )}
      </p>
      <div className="max-xl:w-[100%] max-sm:h-[45px] sm:h-[40px] 2xl:w-[320px] 2xl:h-[50px]  rounded-[30px] bg-white px-1 flex items-center justify-between">
        <div className="flex items-center gap-1">
          {typeBox == "face" && <img src={Facebook} className="w-[36px] h-[35px]" alt="" />}
          {typeBox == "instar" && <img src={Instagram} className="w-[36px] h-[35px] rounded-[50%]" alt="" />}
          {typeBox == "shopee" && <img src={Shoppe} className="w-[36px] h-[35px] rounded-[50%]" alt="" />}

          {typeBox == "face" && (
            <p className={clsx("text-[14px] text-blue-b01")}>
              {t("global.facebook")}
            </p>
          )}
          {typeBox == "instar" && (
            <p className={clsx("text-[14px] text-red-r01")}>
              {t("global.instagram")}
            </p>
          )}
          {typeBox == "shopee" && (
            <p className={clsx("text-[14px] text-[#FE5722]")}>
              {t("global.shoppe")}
            </p>
          )}
        </div>
        {typeBox == "face" && (
          <a className="cursor-pointer" href="https://www.facebook.com/markemoa/" target="_blank" >
            <ArrowForward />
          </a>
        )}
        {typeBox == "instar" && (
          <a className="cursor-pointer" href="https://www.instagram.com/hanoi.marketmoa/ " target="_blank">
            <ArrowForward />
          </a>
        )}
        {typeBox == "shopee" && (
          <a className="cursor-pointer" href="https://shopee.vn/shop/906644805" target="_blank">
            <ArrowForward />
          </a>
        )}

      </div>
    </div>
  );
});

const listContentFooter = [
  {
    name: "global.footer.market_moa.name",
    contents: [
      "global.footer.market_moa.intro",
      // "global.footer.market_moa.culture",
      // "global.footer.market_moa.factory",
      // "global.footer.market_moa.service_center",
      // "global.footer.market_moa.electronic_warranly",
      // "global.footer.market_moa.shop_system",
      // "global.footer.market_moa.contact_us",
    ],
    type: true
  },
  {
    name: "global.footer.support_customer.name",
    contents: [

    ],
    type: false
  }
];
const listAddress: {
  name: string;
  address?: string;
  phoneNumber?: string;
  hotline?: string;
  address1?: string,
  address2?: string,
  address3?: string,
  address4?: string,
  email?: string;
  type: "address" | "hotline";
}[] = [
    {
      name: "global.footer.subfooter.office.name",
      address: "global.footer.subfooter.office.address",
      phoneNumber: "global.footer.subfooter.office.phone_number",
      type: "address",
    },
    {
      name: "global.footer.subfooter.factory.name",
      address1: "global.footer.subfooter.factory.address1",
      address2: "global.footer.subfooter.factory.address2",
      address3: "global.footer.subfooter.factory.address3",
      address4: "global.footer.subfooter.factory.address4",
      type: "address"
    },
    // {
    //   name: "global.footer.subfooter.head_quater.name",
    //   address: "global.footer.subfooter.head_quater.address",
    //   type: "address",
    // },
    {
      name: "global.footer.subfooter.infocontact.name",
      hotline: "global.footer.subfooter.infocontact.hotline",
      email: "global.footer.subfooter.infocontact.email",
      type: "hotline",
    },
  ];
const Footer = (props: Props) => {
  const { t, isVn } = useI18n();
  const [policy, setPolicy] = useState<PagePolicy>();
  const [footerInfo, setFooterInfo] = useState<ContentFooter>();

  const routeAboutus = [
    {
      name: t("about_us.introduce_title"),
      link: "/about-us",
    },
    {
      name: t("about_us.news_title"),
      link: "/about-us/news",
    },
    {
      name: t("about_us.contact_title"),
      link: "/about-us/contact",
    },
  ];

  useEffect(() => {
    try {
      PolicyServices.getAll().then((data) => {
        setPolicy(data);
      });
    } catch (error) { }
  }, []);

  const handleForward = () => {

  }
  return (
    <div className="bg-footer">
      <div className="xl:pl-[103px] pt-8">
        {/* <div className="pl-[103px] lg:px-8 pt-8 pb-4"> */}
        <div className=" pb-6 flex w-full items-center justify-between max-xl:flex-col max-xl:justify-center max-sm:pb-0">
          <Link to={ROUTES["homepage"]} className="max-xl:pb-[20px]">
            <img src={LogoPlusMark} alt="" width={114} height={106} />
            {/* <Logo className="" fill="white" width={114} height={106} /> */}
          </Link>
          <div className="flex items-center w-full justify-evenly max-sm:flex-col max-xl:pb-[35px]">
            <SubFooterBox
              onClickForWard={handleForward}
              counts={1200}
              typeBox={"instar"}
            />
            <SubFooterBox
              onClickForWard={handleForward}
              counts={500000}
              typeBox={"face"}
            />
            <SubFooterBox onClickForWard={handleForward} typeBox={"shopee"} />
          </div>
        </div>

        <div className="rounded-tl-[100px] bg-aqua-aq01 pl-[165px] max-md:pl-[50px] pr-[5%] pt-11 py-2 max-sm:pl-[20px]">
          <div className="grid grid-cols-2 max-md:grid-cols-1 gap-[26px] mb-8">
            <div className="grid grid-cols-2  gap-[26px]">
              {listContentFooter.map((item, index) => {
                return (
                  <div key={index} className="h-full">
                    <h5 className="max-md:text-[14px] text-[16px]  font-bold text-white mb-3">
                      {t(item.name)}
                    </h5>
                    <div>
                      {item.type && item.contents.map((itemContent, index) => {
                        return (
                          <Link to={"/about-us"}
                            key={index}
                            className="max-md:text-[12px] text-[14px] font-normal text-white hover:underline"
                          >
                            {t(itemContent)}
                          </Link>
                        );
                      })}

                      {!item.type && policy?.content.map((itemContent, index) => {
                        return (
                          <p
                            key={index}
                            className="max-md:text-[12px] text-[14px] font-normal text-white hover:underline"
                          >
                            <Link to={`about-us/policy/${itemContent.id}`}>  {isVn ? itemContent.titleVn : itemContent.titleKr}</Link>
                          </p>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
            <div className="grid grid-cols-2 max-md:grid-cols-2  gap-[26px]">
              {listAddress.map((item, index) => {
                return (
                  <div key={index}>
                    <h5 className="max-md:text-[14px] text-[16px] font-bold text-white mb-3 ">
                      <span className="w-6 mr-1 inline-block align-middle">
                        {item.type == "address" ? (
                          <LocationIcon />
                        ) : (
                          <HotlineIcon />
                        )}
                      </span>
                      {t(item.name || "")}
                    </h5>
                    <p className="max-md:text-[12px] text-[14px] font-normal text-white">
                      {t(item.address || "")}
                    </p>
                    <p className="max-md:text-[12px] text-[14px] font-normal text-white">
                      {t(item.address1 || "")}
                    </p>
                    <p className="max-md:text-[12px] text-[14px] font-normal text-white">
                      {t(item.address2 || "")}
                    </p>
                    <p className="max-md:text-[12px] text-[14px] font-normal text-white">
                      {t(item.address3 || "")}
                    </p>
                    <p className="max-md:text-[12px] text-[14px] font-normal text-white">
                      {t(item.address4 || "")}
                    </p>
                    <p className="max-md:text-[12px] text-[14px] font-normal text-white">
                      {t(item.phoneNumber || "")}
                    </p>
                    <p className="max-md:text-[12px] text-[14px] font-normal text-white">
                      {t(item.hotline || "")}
                    </p>
                    <p className="max-md:text-[12px] text-[14px] font-normal text-white">
                      {t(item.email || "")}
                    </p>
                  </div>
                );
              })}
            </div>
          </div>

          {/* <div className="mt-10">
            <p className="text-center text-white text-wap-regular2 mt-6 lg:mt-0">
              {t("footer.copyright")}
            </p>
          </div> */}
        </div>

        {/* <div className="grid grid-cols-1 lg:grid-cols-4 lg:gap-2 xl:gap-10">
          <div>
            <WhiteLogo className="w-48 xl:w-56" />
            <div className="pt-4 lg:pt-6 text-wap-regular2 text-white">
              <p className="">{footerInfo?.comanyName}</p>
              <div className="pt-4">
                {footerInfo?.address.map((a, i) => {
                  return (
                    <p className="mt-1" key={i}>
                      {a}
                    </p>
                  );
                })}
              </div>
              <p className="pt-5">
                <span className="font-semibold">{t("footer.phone")} </span>
                {footerInfo?.phoneNumber.map((p, i) => {
                  if (i < footerInfo?.phoneNumber.length - 1) p += " / ";
                  return (
                    <a
                      key={i}
                      href={`tel:${p.replace(/\s/g, "").replace("/", "")}`}
                      className=""
                    >
                      {p}
                    </a>
                  );
                })}
              </p>
              <div className="pt-6 flex items-center gap-5">
                {footerInfo?.email && footerInfo?.email.length > 0 && (
                  <a href={`mailto:${footerInfo?.email}`}>
                    <MailOutlineIcon />
                  </a>
                )}

                {footerInfo?.facebook && footerInfo?.facebook.length > 0 && (
                  <a target="_blank" href={`${footerInfo?.facebook}`}>
                    <FacebookFilledIcon />
                  </a>
                )}

                {footerInfo?.youtube && footerInfo?.youtube.length > 0 && (
                  <a target="_blank" href={`${footerInfo?.youtube}`}>
                    <YoutubeOutlineIcon />
                  </a>
                )}

                {footerInfo?.instagram && footerInfo?.instagram.length > 0 && (
                  <a target="_blank" href={`${footerInfo?.instagram}`}>
                    <InstagramOutlineIcon />
                  </a>
                )}

                {footerInfo?.tiktok && footerInfo?.tiktok.length > 0 && (
                  <a target="_blank" href={`${footerInfo?.tiktok}`}>
                    <TikTokIcon />
                  </a>
                )}
              </div>
            </div>
          </div>
          <div className="pt-6 lg:pt-0">
            <h1 className="text-normal1 font-semibold text-white">
              {t("footer.about")}
            </h1>
            <div className="pt-6">
              {routeAboutus.map((it, idx) => (
                <Link to={it.link} key={idx}>
                  <p className="pb-5 text-wap-regular2 text-white font-semibold">
                    {it.name}
                  </p>
                </Link>
              ))}
            </div>
          </div>
          <div className="">
            <h1 className="text-normal1 font-semibold text-white">
              {t("footer.policy")}
            </h1>
            <div className="pt-6">
              {policy.map((it: any, idx: any) => (
                <Link to={`/about-us/policy/${it.id}`} key={idx}>
                  <p className="pb-5 text-wap-regular2 text-white font-semibold">
                    {firstUpper(it.title)}
                  </p>
                </Link>
              ))}
            </div>
          </div>
          <div className="">
            <ContactFooterForm />
          </div>
        </div> */}
        {/* <div>
          <p className="text-center text-white text-wap-regular2 mt-6 lg:mt-0">
            {t("footer.copyright")}
          </p>
        </div> */}
      </div>
      <div className="max-xl:pl-[165px] h-auto pl-[265px] max-md:pl-[50px] pr-[5%] flex items-center justify-between  bg-white max-sm:pl-[20px] max-sm:py-[5px] max-sm:items-start">
        <div className="max-sm:pr-[5px]">
          <p className="text-[14px] max-sm:text-[8px] pt-3   text-[#000000] max-sm:leading-[10px]  max-sm:pt-0">
            {t("global.footer.subfooter.rigistration_license.company_name")}
          </p>
          <p className="text-[14px] max-sm:text-[8px] pb-3 max-sm:pb-[2px] text-[#000000] max-sm:leading-[10px]">
            {t("global.footer.subfooter.rigistration_license.license")}
          </p>
        </div>
        <img src={Bct} alt="" className="w-[82px] h-[31px]" />
      </div>
    </div>
  );
};

export default Footer;
