import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import SwiperComponent from "commons/SwiperComponent";
import Swiper, { Autoplay, Grid, Navigation, Pagination } from "swiper";
import React, { memo, useEffect, useState } from "react";
import { SwiperSlide } from "swiper/react";
import CardItem from "@components/Card/CardItem";
import CricleButton from "@components/Buttons/CricleButton";
import PrevIconElm from "@assets/iconElements/PrevIconElm";
import clsx from "clsx";
import ProductServices, { ProductItem } from "@services/ProductServices";
import LoadingPage from "@components/LoadingPage";
import useViewport from "@hooks/useViewPort";
import DynamicButton from "@components/Buttons/DynamicButton";
import { IRespone } from "@services/Types/respone";
import { log } from "console";

interface Props {
  typeSlide: "new" | "sale" | "viewed" | "sameCategory";
  size?: number;
  numberPreview?: number;
  row?: number;
  breackPoin?: any;
  categoryId?: string
}
const initialBreak = {
  480: {
    slidesPerView: 2,
    spaceBetween: 12,
  },
  // 768: {
  //   slidesPerView: 4,
  //   spaceBetween: 26,
  // },
  // 768: {
  //   slidesPerView: 4,
  //   spaceBetween: 26,
  // },
  768: {
    slidesPerView: 4,
    spaceBetween: 26,
  },
  1024: {
    slidesPerView: 4,
    spaceBetween: 26,
  },
  1536: {
    slidesPerView: 4,
    spaceBetween: 26,
  },
};

const SlideProducts = memo(
  ({ typeSlide, size = 4, row = 1, breackPoin = initialBreak, categoryId }: Props) => {
    const { width } = useViewport();
    const [listproduct, setListProduct] = useState<IRespone[]>([]);
    const [totalPage, setTotalPage] = useState<number>(0);
    const [slide, setSlide] = useState<number>(0);

    const {
      navigationPrevRef,
      navigationNextRef,
      handleNext,
      handlePre,
      NavigationElement,
      onActiveIndexChange,
    } = useSwiperNavigationRef();

    const callApi = async () => {
      try {
        // setLoading(true);

        const json = localStorage.getItem("province");
        let result;

        if (width > 1280) {
          result = typeSlide === "new" ?
            await ProductServices.getAllProducts({
              page: 0,
              size: 32,
              sort: "id,desc",
              categoryId: categoryId !== undefined ? categoryId : "",
              address: json === null ? "Thành phố Hà Nội" : JSON.parse(json).name
            }) :
            await ProductServices.getAllProducts({
              page: 0,
              size: 16,
              sort: "promo,desc",
              categoryId: categoryId !== undefined ? categoryId : "",
              address: json === null ? "Thành phố Hà Nội" : JSON.parse(json).name
            })
          if (result) {
            setListProduct(result.content);
          }
        } else {
          result = typeSlide === "new" ?
            await ProductServices.getAllProducts({
              page: totalPage,
              size: 8,
              sort: "id,desc",
              categoryId: categoryId !== undefined ? categoryId : "",
              address: json === null ? "Thành phố Hà Nội" : JSON.parse(json).name
            }) :
            await ProductServices.getAllProducts({
              page: totalPage,
              size: 8,
              sort: "promo,desc",
              categoryId: categoryId !== undefined ? categoryId : "",
              address: json === null ? "Thành phố Hà Nội" : JSON.parse(json).name
            })
          if (result) {
            setListProduct([...listproduct, ...result.content]);
          }
        }


      } catch (error) { }
    };
    const handleAdd = () => {
      setTotalPage(totalPage + 1)
    }


    useEffect(() => {
      callApi();
    }, [totalPage, categoryId]);

    if (width < 1280)
      return (
        <>
          <div className="grid grid-cols-2 md:grid-cols-3 lg:gap-6 sc480:gap-5 gap-3 max-sm:gap-2 ">
            {listproduct.map((item: any, index: number) => {
              return <CardItem key={index}
                item={item}
                description={`${index}`} />;
            })}
          </div>
          {totalPage * 8 <= listproduct.length &&
            <div className="flex justify-center mt-5">
              <DynamicButton
                text="button.see_more"
                className="w-spc136"
                onClick={handleAdd}
              />
            </div>
          }

        </>
      );
    return (
      <div className="relative 2xl:mx-auto xl:block hidden">
        <SwiperComponent
          onActiveIndexChange={onActiveIndexChange}
          navigationNextRef={navigationNextRef}
          navigationPrevRef={navigationPrevRef}
          slidesPerView={size}
          grid={row ? { rows: row, fill: "row" } : undefined}
          className={clsx("w-full h-full", {})}
          spaceBetween={8}
          modules={[Grid, Autoplay, Pagination, Navigation]}

          breakpoints={breackPoin}
        >
          {listproduct.map((item: any, index: number) => {
            return (
              <SwiperSlide key={index} className={clsx("", { "h-1/2": row })}>
                <CardItem description={`${index}`} item={item} />
              </SwiperSlide>
            );
          })}
        </SwiperComponent>
        <>
          <CricleButton
            onClick={() => {
              handlePre()
              // console.log("pre" + slide)
              // typeSlide === "sale" ? (
              //   slide > 0 &&
              //   setSlide(slide - 10)
              // ) : (
              //   slide > 0 &&
              //   setSlide(slide - 1)
              // )

            }}
            className={`absolute -left-[5%] top-1/2 -translate-y-1/2 z-10`}
            icon={<PrevIconElm />}
          />
          <CricleButton
            onClick={() => {
              handleNext()
              // console.log("next" + slide)
              // typeSlide === "sale" ? (
              //   slide + 8 <= listproduct.length &&
              //     slide <= 0 ? setSlide(10) : setSlide(slide + 2)
              // ) : (
              //   slide <= listproduct.length &&
              //   setSlide(slide + 1)
              // )
            }}
            className=
            {`absolute -right-[5%] top-1/2 -translate-y-1/2 z-10 `}
          />
          {NavigationElement}
        </>
      </div>
    );
  }
);

export default SlideProducts;
