import TitleSession from "@components/common/TitleSession";
import SocialHelpBox from "commons/components/SocialHelpBox";
import React, { memo, useCallback, useContext, useEffect, useRef, useState, ReactNode } from "react";
import SlideProducts from "./SlideProducts";
import { RadioButton } from "@components/AdminComponents/VoucherEditComponent";
import SortProduct from "@components/filters/product/SortProduct";
import useI18n from "@hooks/useI18n";
import CardItem from "@components/Card/CardItem";
import ProductServices, { ProductItemV2, ResponseProduct } from "@services/ProductServices";
import { IRespone } from "@services/Types/respone";
import DynamicButton from "@components/Buttons/DynamicButton";

interface Props {
  name?: string,
  idCategory?: any
  idSubCategory?: any
  handleCountProduct: (count: number) => void
  isText?: boolean
}
const ProductSearch = memo(({ name = "...", idCategory, idSubCategory = "", handleCountProduct, isText = true }: Props) => {

  const [choose, setChoose] = useState<string>("id,desc");
  const { t } = useI18n();

  const handleChange = (e: any) => {
    setChoose(e.target.value)
    setPage(0)
    setListProduct([])
  }

  const [listProduct, setListProduct] = useState<ProductItemV2[]>([]);
  const [page, setPage] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);


  useEffect(() => {
    const json = localStorage.getItem("province");
    ProductServices.getAllProduct({
      "page": page,
      "size": 8,
      "sort": choose,
      "categoryId": idCategory,
      "subCategoryId": idSubCategory,
      "address": json === null ? "Thành phố Hà Nội" : JSON.parse(json).name
    }).then(data => {
      handleCountProduct(data.totalElements)
      setTotalPage(data.totalPages)
      page === 0 ? setListProduct(data.content) :
        setListProduct([...listProduct, ...data.content])
    })

  }, [choose, page, idCategory])



  const onShow = () => {
    setPage(page + 1);
  }

  return (
    <div className="product_box max-md:pt-[20px] pt-[40px] pb-[58px] bg-gray-100  min-h-screen ">
      {

        isText &&
        <TitleSession
          text={name}
          className="w-full mb-[35px] uppercase max-sm:mb-[10px] "
        />


      }
      <div>
        <SortProduct handleChange={handleChange} choose={choose} />
      </div>

      <div className="grid grid-cols-4 gap-4 max-sm:grid-cols-2 max-md:grid-cols-3 pt-[40px] max-md:pt-[20px]">
        {
          listProduct && listProduct.map((item) => {
            return <CardItem item={item} />
          })
        }
      </div>

      <div className="flex justify-center pt-[30px] ">
        {(page + 1 < totalPage) &&
          <DynamicButton
            onClick={() => onShow()}
            // (page > totalPage) > ? t('button.hide_away') : 
            text={t('button.see_more')}
            className="!rounded-[30px] !px-[6px] !py-2 !min-w-[142px] !w-[142px] text-[16px] leading-normal font-bold font-NunitoSans"
          />
        }
      </div>

      {/* <SlideProducts typeSlide="sale" row={2} size={4} categoryId={categoryId} />
      {isBtn && <SocialHelpBox />} */}
    </div>
  );
});

export default ProductSearch;
