import PlusLinerIcon from "@assets/iconElements/PlusLinerIcon";
import useI18n from "@hooks/useI18n";
import CategoryList from "./CategoryList";
import categoryServices from "@services/categoryService";
import { useContext, useEffect, useState } from "react";
import { CategoryType } from "@services/Types/category";
import { useShowConfirm } from "@components/Modal/DiglogComfirm";
import LinearButton from "@components/Buttons/LinearButton";
import { useShowMessage } from "@components/Modal/DialogMessage";
import { ModalContext } from "@contexts/contextModal";
import EditCategory from "./EditCategory";
import { TrashIconAdvice } from "@assets/icons";

const ManagerCategory = () => {
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const { showConfirm } = useShowConfirm();
  const { showError, showSuccess, showWarning } = useShowMessage();
  const { setShowModal, closeModal, setContentModal } =
    useContext(ModalContext);
  const { t } = useI18n();
  const [listIdDelete, setListIdDelete] = useState<number[]>([]);

  const getCategory = async () => {
    try {
      const result = await categoryServices.getAllCategory();
      setCategories(result);
    } catch (error) { }
  };

  const deleteItem = async (id: number) => {
    try {
      const deleted = await categoryServices.deleteCategory(id);
      showSuccess("success.deleted");
      getCategory();
    } catch (error) {
      showError("error.deleted_category_error");
    }
  };
  const handleDelete = async (id: number) => {
    showConfirm("confirm.delete_category", () => deleteItem(id));
  };

  const handleAddCategory = () => {
    const handleAddCategory = () => {
      showSuccess("success.posted");
      getCategory();
    };
    setContentModal(<EditCategory onCreated={handleAddCategory} />);
    setShowModal(true);
  };
  const handleEditCategory = (item: CategoryType) => {
    const handleEdited = () => {
      showSuccess("success.updated");
      getCategory();
    };
    setContentModal(<EditCategory onEdit={handleEdited} item={item} />);
    setShowModal(true);
  };
  useEffect(() => {
    getCategory();
  }, []);

  const handleAddManyId = () => {
    if (categories.length > 0 && listIdDelete.length !== categories.length) {
      const newListId: any = categories.map((item) => {
        return item.id;
      });
      setListIdDelete(newListId);
      return;
    }
    setListIdDelete([]);
    return;
  };

  const handleAdIdDelete = (id: number) => {
    const checkIdAlready = listIdDelete.includes(id);
    if (checkIdAlready) {
      const newListId = listIdDelete.filter((item) => {
        return item != id;
      });
      setListIdDelete(newListId);
    } else {
      setListIdDelete([...listIdDelete, id]);
    }
  };

  const handleShowConfirmDelete = () => {
    showConfirm("confirm.delete_category", () => onDeleteCategory());
  };

  const onDeleteCategory = async () => {
    try {
      if (listIdDelete.length > 0) {
        await categoryServices.deleteMany(listIdDelete);
        showSuccess("success.deleted")
        setListIdDelete([]);
      }
    } catch (error) {
      showError("error.deleted_category_error")
    } finally {
      getCategory();
    }
  };

  return (
    <div className="py-14">
      <div className="flex items-center justify-between">
        <p className="text-2xl font-bold leading-[150%]">
          {t("text.title.category")}
        </p>
        <div className="flex  gap-2">
          <button
            onClick={handleShowConfirmDelete}
            disabled={listIdDelete.length === 0}
            className={
              "w-fit h-full  border-[#E00] border-[1px] font-bold text-[#E00] px-5 py-3  flex items-center justify-between gap-3" +
              (listIdDelete.length === 0
                ? "cursor-not-allowed bg-inherit"
                : "cursor-pointer bg-white")
            }
          >
            <TrashIconAdvice /> 
            <span>{t("text.button.delete")}</span>
          </button>
          <LinearButton
            text="button.add_category"
            iconLeft={<PlusLinerIcon />}
            className="w-[170px] h-13"
            onClick={handleAddCategory}
          />
        </div>
      </div>

      <div className="pt-10">
        {categories.length > 0 ? (
          <CategoryList
            category={categories}
            handleEdit={handleEditCategory}
            onDelete={handleDelete}
            handlSelectAll={handleAddManyId}
            handleSelect={handleAdIdDelete}
            listIdDelete={listIdDelete}
          />
        ) : (
          <div>Không có dữ liệu</div>
        )}
      </div>
    </div>
  );
};

export default ManagerCategory;
