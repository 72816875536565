import RightArrow from "@assets/iconElements/RightArrow";
import CricleButton from "@components/Buttons/CricleButton";
import useI18n from "@hooks/useI18n";
import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import { SubCategory } from "@services/Types/category";
import SwiperComponent from "commons/SwiperComponent";
import { CategoryType } from "commons/contannt";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
interface Props {
  item: any,
  stt: number
}
const CartISlideCategory = memo(({ item, stt }: Props) => {
  const {
    navigationPrevRef,
    navigationNextRef,
    handleNext,
    handlePre,
    NavigationElement,
    currentIndex,
    onActiveIndexChange,
    activeThumb,
    setThumbActive,
  } = useSwiperNavigationRef();

  const { isVn, t } = useI18n();

  return (
    <div className="rounded-[10px] pr-[22px] lg:pt-6 flex flex-col gap-1 w-[379px] max-sm:w-[107px] lg:rounded-lg  shadow-shd020  bg-gray-100">
      <Link to={`/category/${item?.categoryId}/${item.imagesSubcategory[0]?.id}`} className="w-full pt-[14px]">
        <div className="flex justify-start w-[379px] h-[259px] max-sm:w-[107px] max-sm:!h-[73px]">

          <img
            src={item.imagesSubcategory[0] && item.imagesSubcategory[0].url}
            alt=""
            className="rounded-[12px] w-full h-full shadow-shd020 object-cover"
          />

        </div>
        <div className="xl:text-lg text-lg font-thin ] hover:text-main max-sm:text-[16px] mt-[25px] mas-sm:mt-[5px] flex justify-start items-start h-[70px]">
          <p className="max-sm:leading-[20px] text-[32px] max-md:h-[20px] pt-[2px] h-full   line-clamp-1 font-semibold text-black max-sm:text-[14px] max-sm:font-medium"> {isVn ? item.subCategoryNameVn : item.subCategoryNameKr}</p>
        </div>
      </Link>
    </div>
  );
});

export default CartISlideCategory;
