import { OrderType, paramOrder } from "commons/contannt"
import api from "./api"
import queryString from "query-string"

const path = '/order'
const OrderService = {
    getAllOrder: async (): Promise<any> => {
        return await api.get(path)

    },
    getOrder: async (param: paramOrder): Promise<any> => {
        return await api.get(`${path}?${queryString.stringify(param, { skipNull: true })}`)
    },
    getOrderByKeyword: async (param: any): Promise<any> => {
        return (await api.get(`${path}/search?${queryString.stringify(param, { skipNull: true })}`))
    },
    updateOrder: async (id: number, data: OrderType): Promise<OrderType> => {
        return (await api.put(`${path}/${id}`, data))
    },
    deleteManyOrders : async(data: any) => {
        return await api.delete(`${path}?${queryString.stringify({ids: data},{ arrayFormat: "comma", skipNull: true })}`)
    },
}

export default OrderService