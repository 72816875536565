import { colors } from "@utility/colors";
import { IconProps } from "@utility/types";
import React from "react";

const NextIcon = ({
  width = 24,
  height = 24,
  color = colors.aqua02,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 50"
      fill={color}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.08405 0.679717L23.3644 23.1213C23.7206 23.5155 23.9199 24.0442 23.9199 24.5947C23.9199 25.1451 23.7206 25.6738 23.3644 26.068L3.08405 48.518C2.91813 48.7019 2.71976 48.848 2.50063 48.9477C2.2815 49.0475 2.04607 49.0989 1.80822 49.0989C1.57038 49.0989 1.33494 49.0475 1.11581 48.9477C0.896683 48.848 0.698305 48.7019 0.532383 48.518C0.191029 48.1411 -7.62939e-05 47.635 -7.62939e-05 47.108C-7.62939e-05 46.5811 0.191029 46.075 0.532383 45.6981L19.6034 24.5947L0.532383 3.49547C0.1922 3.11869 0.00183487 2.61354 0.00183487 2.0876C0.00183487 1.56165 0.1922 1.0565 0.532383 0.679717C0.698305 0.495898 0.896683 0.349795 1.11581 0.250035C1.33494 0.150274 1.57038 0.098877 1.80822 0.098877C2.04607 0.098877 2.2815 0.150274 2.50063 0.250035C2.71976 0.349795 2.91813 0.495898 3.08405 0.679717Z"
        fill="current"
      />
    </svg>
  );
};

export default NextIcon;
