import useI18n from "@hooks/useI18n";
import React, { memo, useState, useEffect } from "react";
import clsx from "clsx";
import { OrderType } from "commons/contannt";
import { PrevIcon } from "@assets/icons/plust-mark/PrevIcon";

interface Props {
    order: OrderType,
    handleChange: (id: number, value: string) => void;
}



const OrderStatusDropDown = memo(({ handleChange, order }: Props) => {
    const { isVn, t } = useI18n();
    const options = [
        { value: 'COMPLETED', label: t("text.manage_order_product.option_status_success") },
        { value: 'CANCELLED', label: t("text.manage_order_product.option_status_cancel") },
        { value: 'WAITING', label: t("text.manage_order_product.option_status_waiting") },
    ];
    const [isShow, setShow] = useState<boolean>(false);
    const [selectedOption, setSelectedOption] = useState<string>(order.status);
    const [selectedLabel, setSelectedLabel] = useState<string>(options.find((o) => o.value === order.status)?.label || "");
    useEffect(() => {
        setSelectedOption(order.status);
    }, [order.status, isVn]);

    const handleOptionClick = (value: string) => {
        setSelectedOption(value);
        setShow(false);
        handleChange(order.id, value);
        setSelectedLabel(options.find((o) => o.value === value)?.label || "")
    };
    return (
        <div>
            <div
                onClick={() => selectedOption === "WAITING" ? setShow(!isShow) : {}}
                className={clsx("flex relative")}
            >
                <div className="flex justify-between gap-10 w-[150px]">
                    <span className={`normal_text line-clamp-1 font-bold ${selectedOption === "WAITING" ? "text-[#FF5A1F]" : selectedOption === "COMPLETED" ? "text-[#01A63E]" : "text-[#E73F3F]"}`}>{selectedLabel}</span>
                    <span
                        className={
                            "transition-all ease-in " +
                            (isShow ? "-rotate-90" : "-rotate-180")
                        }
                    >
                        <PrevIcon width={18} height={18} color="black" />
                    </span>
                </div>
                <ul className={clsx("absolute top-[110%] w-full py-0 rounded-sm border border-neutra-neutra80 bg-white shadow-tooltip cursor-pointer z-20 list-none", { 'hidden': !isShow })}>
                    {options.map((o) => (
                        <li
                            key={o.value}
                            onClick={() => selectedOption === "WAITING" ? handleOptionClick(o.value) : {}}
                            className="cursor-pointer w-full border-b"
                        >
                            {o.label}
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    )
})

export default OrderStatusDropDown;
