import { colors } from "@utility/colors";
import { IconProps } from "@utility/types";
import React from "react";

const PrevIconElm = ({
  width = 24,
  height = 24,
  color = colors.aqua02,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 50"
      fill="white"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8359 0.679717L0.555541 23.1213C0.199348 23.5155 0 24.0442 0 24.5947C0 25.1451 0.199348 25.6738 0.555541 26.068L20.8359 48.518C21.0018 48.7019 21.2002 48.848 21.4193 48.9477C21.6384 49.0475 21.8739 49.0989 22.1117 49.0989C22.3495 49.0989 22.585 49.0475 22.8041 48.9477C23.0232 48.848 23.2216 48.7019 23.3875 48.518C23.7289 48.1411 23.92 47.635 23.92 47.108C23.92 46.5811 23.7289 46.075 23.3875 45.6981L4.31652 24.5947L23.3875 3.49547C23.7277 3.11869 23.9181 2.61354 23.9181 2.0876C23.9181 1.56165 23.7277 1.0565 23.3875 0.679717C23.2216 0.495898 23.0232 0.349795 22.8041 0.250035C22.585 0.150274 22.3495 0.098877 22.1117 0.098877C21.8739 0.098877 21.6384 0.150274 21.4193 0.250035C21.2002 0.349795 21.0018 0.495898 20.8359 0.679717Z"
        fill={color}
      />
    </svg>
  );
};

export default PrevIconElm;
