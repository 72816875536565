import { useTranslation } from "react-i18next";
import { initReactI18next } from "react-i18next";
const useI18n = () => {
  const { t, i18n } = useTranslation();
  const lang = localStorage.getItem("lang");
  const isVn = i18n.language == "vi";
  const changeLanguage = (flag: "vi" | "ksl") => {
    i18n.changeLanguage(flag);
  };
  return { t, lang: i18n.language, changeLanguage, isVn, i18n };
};

export default useI18n;
