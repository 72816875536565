import PrevIconElm from "@assets/iconElements/PrevIconElm";
import { NextArrowIcon, PrevArrowIcon } from "@assets/icons";
import CricleButton from "@components/Buttons/CricleButton";
import CardItem from "@components/Card/CardItem";
import ProductCard from "@components/Card/ProductCard";
import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import useViewport from "@hooks/useViewPort";
import { IProduct } from "@pages/Product/ProductDetailNew";
import { ProductItem, ProductItemV2 } from "@services/ProductServices";
import clsx from "clsx";
import SwiperComponent from "commons/SwiperComponent";
import { useCallback, useEffect, useRef } from "react";
import { Autoplay, Grid, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

type Props = {
  product?: ProductItemV2[]
  productSeen?: IProduct[]
  type: string,
  id: number | undefined
}

const ProductCarousel = ({ product, productSeen, type, id }: Props) => {
  const {
    navigationPrevRef,
    navigationNextRef,
    handleNext,
    handlePre,
    NavigationElement,
    currentIndex,
    onActiveIndexChange,
    activeThumb,
    setThumbActive,
  } = useSwiperNavigationRef();
  useEffect(() => {
    const json = localStorage.getItem("ProductWatched");
    switch (json) {
      case undefined:
      case null:
        break;
      default:

    }
  })

  const { width } = useViewport();
  return (
    <>
      <div className="relative">
        {
          width >= 1536 && (
            type == "seen" ?
              product && (product?.length < 4 ? "" :
                <CricleButton
                  onClick={() => handlePre()}
                  className="absolute 3xl:-left-[5%] 2xl:-left-[6%] top-1/2 -translate-y-1/2 z-10 "
                  icon={<PrevIconElm />}
                />)
              :
              productSeen && (productSeen?.length < 4 ? "" :
                <CricleButton
                  onClick={() => handlePre()}
                  className="absolute 3xl:-left-[5%] 2xl:-left-[6%] top-1/2 -translate-y-1/2 z-10 "
                  icon={<PrevIconElm />}
                />)
          )
        }
        <SwiperComponent
          onActiveIndexChange={onActiveIndexChange}
          navigationNextRef={navigationNextRef}
          navigationPrevRef={navigationPrevRef}
          slidesPerView={width >= 1280 ? 5 : width >= 750 ? 3 : 2}
          modules={[Grid, Autoplay, Pagination, Navigation]}
          className={clsx("w-full h-full", {})}
          grid={{ rows: width >= 1024 ? 1 : 2, fill: "row" }}
          spaceBetween={width >= 1024 ? 26 : 8}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          loop

        >
          {type == "seen" ?
            (product ?? []).map((item: ProductItemV2, index: number) => {
              if (Number(item.id) !== id)
                return (
                  <SwiperSlide key={index} className={clsx("")}>
                    <CardItem description={`${index}`} item={item} />
                  </SwiperSlide>
                );
            })
            :
            (productSeen ?? []).map((item: IProduct, index: number) => {

              if (item.id !== id)

                return (
                  <SwiperSlide key={index} className={clsx("")}>
                    <CardItem description={`${index}`} item={item} />
                  </SwiperSlide>
                );
            })
          }
        </SwiperComponent>
        {
          width >= 1536 &&


          (
            type == "seen" ?
              product && (product?.length < 4 ? "" :
                <CricleButton
                  onClick={() => handleNext()}
                  className={
                    "absolute 3xl:-right-[5%] 2xl:-right-[7%] top-1/2 -translate-y-1/2 z-10 "
                  }
                />
              ) :
              productSeen && (productSeen?.length < 4 ? "" :
                <CricleButton
                  onClick={() => handleNext()}
                  className={
                    "absolute 3xl:-right-[5%] 2xl:-right-[7%] top-1/2 -translate-y-1/2 z-10 "
                  }
                />
              )
          )}
        {NavigationElement}
      </div>
    </>
  );
};

export default ProductCarousel;
