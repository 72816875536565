import { CarlendarIcon, TrashIconAdvice } from "@assets/icons";
import OrderModal from "@components/ManagerModal/OrderModal";
import Pagination from "@components/Pagination";
import { ToastContex } from "@contexts/ToastContex";
import { ModalContext } from "@contexts/contextModal";
import { useAppDispatch, useAppSelector } from "@hooks/reduxHook";
import OrderService from "@services/orderServices";
import { formatMoney } from "@utility/helper";
import { DatePicker } from "antd";
import { OrderType, itemCard } from "commons/contannt";
import { dayFormat } from "commons/dayfomat";
import dayjs from "dayjs";
import { useContext, useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";

import DowloadFile from "@services/DowloadFile";
import {
  setCurrentPage,
  setLoading,
  setOrdersList,
} from "redux/reducer/orderSlice";
import { ThunkGetListOrder } from "redux/thunk/orderAction";
import InputChecboxElement from "commons/components/InputComponent/InputChecboxElement";
import { useShowConfirm } from "@components/Modal/DiglogComfirm";
import { useShowMessage } from "@components/Modal/DialogMessage";
import useI18n from "@hooks/useI18n";
import OrderStatusDropDown from "./ComponentOrder/OrderStatusDropDown";

const { RangePicker } = DatePicker;

function ManageOrder() {
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const { onAddToast } = useContext(ToastContex);
  const { setShowModal, setContentModal } = useContext(ModalContext);
  const { listOrders, currentPage, totalElement } =
    useAppSelector((state) => state.orders);
  const [inputSearch, setInputSearch] = useState<string>("");
  const date = new Date().getTime();
  const OfsetDate = date - 24 * 60 * 60 * 1000 * 5;
  const { showConfirm } = useShowConfirm();
  const { showError, showSuccess, showWarning } = useShowMessage();
  const { t } = useI18n();

  const [rangeTimeValue, setRangeTimeValue] = useState({
    startDate: OfsetDate,
    endDate: date,
  });
  const [listIdDelete, setListIdDelete] = useState<number[]>([]);

  const options = [
    { value: 'COMPLETED', label: 'Hoàn thành' },
    { value: 'CANCELLED', label: 'Đã hủy' },
    { value: 'WAITING', label: 'Chờ xác nhận' },
  ];

  const handleAddManyId = () => {
    if (listOrders.length > 0 && listIdDelete.length !== listOrders.length) {
      const newListId = listOrders.map((item: any) => {
        return item.id;
      });
      setListIdDelete(newListId);
      return;
    }
    setListIdDelete([]);
    return;
  };

  const handleShowConfirmDelete = () => {
    showConfirm("confirm.delete_products", () => onDeleteOrder());
  };

  const onDeleteOrder = async () => {
    try {
      if (listIdDelete.length > 0) {
        await OrderService.deleteManyOrders(listIdDelete);
        setListIdDelete([]);
        if (inputSearch === "") {
          const param = {
            startDate: rangeTimeValue.startDate,
            endDate: rangeTimeValue.endDate,
            page: currentPage - 1,
            size: 20,
            sort: "orderTime,desc",
          };
          dispatch(ThunkGetListOrder(param));
        }
      }
      showSuccess("success.deleted")
      return;
    } catch (error) {
      showError("error.deleted_error")
    }
  };

  const handleAdIdDelete = (id: number) => {
    const checkIdAlready = listIdDelete.includes(id);
    if (checkIdAlready) {
      const newListId = listIdDelete.filter((item) => {
        return item != id;
      });
      setListIdDelete(newListId);
    } else {
      setListIdDelete([...listIdDelete, id]);
    }
  };

  const handleChangeInput = (value: string) => {
    setInputSearch(value);
    onSearchOrder(value);
  };
  const showDetailOrder = (item: OrderType) => {
    setShowModal(true);
    setContentModal(<OrderModal itemOrder={item} />);
  };
  const listName = [
    t("text.manage_order_product.title_order_code"),
    t("text.manage_order_product.title_order_name"),
    t("text.manage_order_product.title_phone"),
    t("text.manage_order_product.title_order_address"),
    t("text.manage_order_product.title_order_date"),
    t("text.manage_order_product.title_order_status"),
  ];

  const dowloadExcel = async () => {
    await DowloadFile.dowloadExcel(rangeTimeValue)
      .then((response) => {
        const blob = new Blob([response]);
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `Order-${dayFormat(new Date().getTime())}.xlsx`;
        link.click();
        return link;
      })
      .then((link) => {
        window.URL.revokeObjectURL(link.href);
        link.remove();
        onAddToast({
          type: "success",
          message: `Exported ${totalElement} orders`,
        });
      })
      .catch((error) => {
        onAddToast({ type: "error", message: "Cannot exported " });
      });
  };

  const handlePagination = (page: number) => {
    dispatch(setCurrentPage(page));
  };

  const handleChangeTimePicker = (value: any, dateString: any) => {
    let startDates = new Date(value[0].valueOf());
    startDates.setHours(0);
    startDates.setMinutes(0);
    startDates.setSeconds(0);
    let endDate = new Date(value[1].valueOf());
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

    setRangeTimeValue({
      startDate: startDates.getTime(),
      endDate: endDate.getTime(),
    });
  };

  const onSearchOrder = async (value: string) => {
    if (value === "") {
      dispatch(setCurrentPage(1));
      return;
    }
    setRangeTimeValue({
      startDate: OfsetDate,
      endDate: date,
    });
    const param = {
      page: currentPage - 1,
      size: 20,
      sort: "orderTime,desc",
      keyword: value
    };
    const order = await OrderService.getOrderByKeyword(param)
    dispatch(setOrdersList(order));
    dispatch(setLoading(true));
  };

  useEffect(() => {
    if (searchParams.get("page")) {
      const page = Number(searchParams.get("page"));
      setCurrentPage(page);
    }
    return () => { };
  }, [searchParams]);

  useEffect(() => {
    if (inputSearch === "") {
      const param = {
        startDate: rangeTimeValue.startDate,
        endDate: rangeTimeValue.endDate,
        page: currentPage - 1,
        size: 20,
        sort: "orderTime,desc",
      };
      dispatch(ThunkGetListOrder(param));
    }
  }, [rangeTimeValue, currentPage, inputSearch]);


  const setStatusOrder = async (id: number, value: string) => {
    try {
      const index = listOrders.findIndex((order: any) => order.id === id);
      const orderUpdate = {
        ...listOrders[index],
        status: value
      }
      await OrderService.updateOrder(id, orderUpdate);
      showSuccess("success.updated")
    } catch (error) {
      showSuccess("error.update_error")
    }
  }

  return (
    <div className="w-full pt-9 pb-10px">
      <div className="">
        <h2 className="titlePage mb-4">{t("text.manage_order_product.title")}</h2>
        <div className="flex items-center justify-between mb-25">
          <div className="flex items-center mb-4 w-[50%]">
            <input
              value={inputSearch}
              onChange={(event) => handleChangeInput(event.target.value)}
              placeholder={t("text.manage_order_product.placholder_search")}
              className="border rounded-xs p-2 w-full text-base"
            />
          </div>
          <div className="w-[50%] flex justify-between ml-2 gap-2">
            <div className="mb-4 flex items-center ">
              <button
                disabled={listIdDelete.length === 0}
                onClick={handleShowConfirmDelete}
                className={
                  "w-fit border-[#E00] border-[1px] font-bold text-[#E00] p-2  flex items-center gap-1 rounded-xs" +
                  (listIdDelete.length === 0
                    ? "cursor-not-allowed bg-inherit"
                    : "cursor-pointer bg-white")
                }
              >
                <TrashIconAdvice />  <span className="text-[13px]">{t("text.manage_order_product.button_delete")}</span>
              </button>
            </div>
            <div className="mb-4 flex items-center">
              <div className="flex font-semibold text-main w-[85%]">
                <RangePicker
                  suffixIcon={<CarlendarIcon />}
                  size="small"
                  id="date"
                  name="date"
                  value={[
                    dayjs(rangeTimeValue.startDate),
                    dayjs(rangeTimeValue.endDate),
                  ]}
                  allowClear={false}
                  format="YYYY-MM-DD"
                  onChange={handleChangeTimePicker}
                  className="border border-main rounded-xs p-2 text-main w-fit"
                />
              </div>
            </div>
            <div className="mb-4 flex items-center  ">
              {totalElement > 0 && inputSearch === "" && (
                <button
                  onClick={() => dowloadExcel()}
                  className="textInput py-[11px] px-2 bg-white font-semibold border-main text-main rounded-xs"
                >
                  {t("text.manage_order_product.export_excel")}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>

      <div>
        {listOrders.length > 0 && (
          <div className="xl:w-full flex">
            <table className="xl:w-full">
              <thead >
                <tr>
                  <th className="text-base font-bold tracking-[.03] border-b py-4">
                    <InputChecboxElement
                      isCheck={
                        listOrders.length > 0 &&
                        listIdDelete.length === listOrders.length
                      }
                      name={"checkAll"}
                      onHandleChange={handleAddManyId}
                      sizeBox="w-4 h-4 rounded-[4px]"
                    />
                  </th>
                  {listName.map((item, index) => {
                    return (
                      <th
                        key={item + index}
                        className="text-base font-bold tracking-[.03] border-b py-4"
                      >
                        <div className="flex justify-between ">
                          {item}
                        </div>
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {listOrders.map((itemOrder: any) => {
                  return (
                    <tr
                      key={itemOrder.id}
                      className="cursor-pointer "
                    >
                      <td className="text-sm font-normal tracking-[.03] text-center border-b py-4 pr-2">
                        <InputChecboxElement
                          isCheck={listIdDelete.includes(itemOrder.id)}
                          name={itemOrder.id + "Input"}
                          onHandleChange={() => handleAdIdDelete(itemOrder.id)}
                          sizeBox="w-4 h-4 rounded-[4px]"
                        />
                      </td>
                      <td className="text-sm font-normal tracking-[.03] text-center border-b py-4  w-[20%]" onClick={() => showDetailOrder(itemOrder)}>
                        <div className="flex flex-col justify-start items-start">
                          <span>{itemOrder.orderCode}</span>
                          <span className="text-[#A1A0A3] text-xs mt-2">{dayFormat(itemOrder.orderTime)}</span>
                        </div>
                      </td>
                      <td className="text-sm font-normal tracking-[.03] text-center border-b py-4 w-[15%]" onClick={() => showDetailOrder(itemOrder)}>
                        <div className="flex flex-col justify-start items-start">
                          {itemOrder.fullName}
                        </div>
                      </td>
                      <td className="text-sm font-normal tracking-[.03] text-center border-b py-4 w-[15%]" onClick={() => showDetailOrder(itemOrder)}>
                        <div className="flex flex-col justify-start items-start">
                          {itemOrder.phoneNumber}
                        </div>
                      </td>
                      <td className="text-sm font-normal tracking-[.03] border-b py-4 w-[20%]" onClick={() => showDetailOrder(itemOrder)}>
                        <div className="flex justify-start items-center">
                          {itemOrder.address}
                        </div>
                      </td>
                      <td className="text-sm font-normal tracking-[.03] text-center  border-b py-4 w-[20%]" onClick={() => showDetailOrder(itemOrder)}>
                        <div className="flex flex-col justify-start items-start">
                          {dayFormat(itemOrder.orderTime)}
                        </div>
                      </td>
                      <td className="text-sm font-normal tracking-[.03] text-center border-b py-4 w-[15%]">
                        <div className="flex justify-start">
                          <OrderStatusDropDown handleChange={setStatusOrder} order={itemOrder} />
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
      </div>
      {inputSearch === "" && listOrders.length > 0 && (
        <div className="flex justify-end mt-5">
          <div>
            <Pagination
              currenPage={currentPage}
              setCurrentPage={handlePagination}
              total={Math.ceil(totalElement / 20)}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default ManageOrder;
