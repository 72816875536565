import React from 'react'

const SearchIcon = ({ width = 28, height = 28, color = "white", check = 1 }: { width: number, height: number, color: any, check: number }) => {

    switch (check) {
        case 1:
            return (
                <svg width={width} height={height} viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g id="icon/IcBaselineSearch 2">
                        <path id="Vector" d="M16.7917 15.1667H15.9358L15.6325 14.8742C16.7309 13.6002 17.3345 11.9738 17.3333 10.2917C17.3333 8.89896 16.9204 7.53753 16.1466 6.37953C15.3729 5.22153 14.2731 4.31899 12.9864 3.78602C11.6997 3.25305 10.2839 3.1136 8.91791 3.38531C7.55196 3.65701 6.29725 4.32766 5.31246 5.31246C4.32766 6.29725 3.65701 7.55196 3.38531 8.91791C3.1136 10.2839 3.25305 11.6997 3.78602 12.9864C4.31899 14.2731 5.22153 15.3729 6.37953 16.1466C7.53753 16.9204 8.89896 17.3333 10.2917 17.3333C12.0358 17.3333 13.6392 16.6942 14.8742 15.6325L15.1667 15.9358V16.7917L20.5833 22.1975L22.1975 20.5833L16.7917 15.1667ZM10.2917 15.1667C7.59417 15.1667 5.41667 12.9892 5.41667 10.2917C5.41667 7.59417 7.59417 5.41667 10.2917 5.41667C12.9892 5.41667 15.1667 7.59417 15.1667 10.2917C15.1667 12.9892 12.9892 15.1667 10.2917 15.1667Z" fill={color} />
                    </g>
                </svg>



            )
        case 2:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 9 9" fill="none">
                    <path d="M1 8L4.5 4.5L8 8M8 1L4.49933 4.5L1 1" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            )
        case 3:
            return <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 8 8" fill="none">
                <path d="M3.75382 0C1.67964 0 0 1.792 0 4C0 6.208 1.67964 8 3.75382 8C5.83176 8 7.51515 6.208 7.51515 4C7.51515 1.792 5.83176 0 3.75382 0ZM5.26061 5.6C5.22584 5.63708 5.18455 5.6665 5.1391 5.68657C5.09364 5.70665 5.04491 5.71698 4.9957 5.71698C4.94648 5.71698 4.89776 5.70665 4.8523 5.68657C4.80684 5.6665 4.76555 5.63708 4.73079 5.6L3.49455 4.284C3.45904 4.24695 3.43079 4.20278 3.41144 4.15403C3.39209 4.10528 3.38202 4.05292 3.38182 4V2.4C3.38182 2.18 3.55091 2 3.75758 2C3.96424 2 4.13333 2.18 4.13333 2.4V3.836L5.26061 5.036C5.40715 5.192 5.40715 5.444 5.26061 5.6Z" fill={color} />
            </svg>
        default:
            return (
                <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 8 14" fill="none">
                    <path d="M7 13L1 7L7 1" stroke={color} stroke-linecap="round" stroke-linejoin="round" />
                </svg>
            )

    }

}

export default SearchIcon