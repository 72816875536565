import SwiperComponent from "commons/SwiperComponent";
import { Autoplay, Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import "./banner.css";
import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import CricleButton from "@components/Buttons/CricleButton";
import PrevIconElm from "@assets/iconElements/PrevIconElm";
import { colors } from "@utility/colors";
import NextIcon from "@assets/iconElements/NextIcon";


const Banner = ({ className, images, ...props }: any) => {
  const {
    navigationPrevRef,
    navigationNextRef,
    handleNext,
    handlePre,
    NavigationElement,
  } = useSwiperNavigationRef();

  return (
    <div className={`w-full h-full relative ${className}`} {...props}>
      <SwiperComponent
        navigationNextRef={navigationNextRef}
        navigationPrevRef={navigationPrevRef}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 2000,
          disableOnInteraction: false,
        }}
        modules={[Autoplay]}
        className="w-full h-full"
      >
        {images && images.map((src: any, i: any) => {
          return (
            <SwiperSlide key={i}>
              <img
                src={src?.url}
                className="w-full h-full object-cover"
                alt="image banner homepage"
              />
            </SwiperSlide>
          );
        })}
      </SwiperComponent>
      {/* {images && images.length > 1 && ( */}
      <div>
        <div className="hidden md:block">
          {/* <CricleButton
            onClick={() => handlePre()}
            className="absolute left-[5%] top-1/2 -translate-y-1/2 z-10 !border-white "
            icon={<PrevIconElm color={colors.white} />}
          />
          <CricleButton
            icon={<NextIcon color={colors.white} />}
            onClick={() => {
              handleNext()
            }}
            className="absolute right-[5%] top-1/2 -translate-y-1/2 z-10 !border-white"
          /> */}
        </div>
        {NavigationElement}
      </div>
      {/* )} */}
    </div>
  );
};

export default Banner;
