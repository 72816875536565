import CloseIconElm from "@assets/iconElements/CloseIconElm";
import DynamicButton from "@components/Buttons/DynamicButton";
import CricleButton from "@components/Buttons/CricleButton";
import useI18n from "@hooks/useI18n";
import CardItem from "@components/Card/CardItem";
import province_data from "../../utility/province_date.json";
import { useEffect, useState } from "react";
import Item from "antd/es/list/Item";
import clsx from "clsx";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { update } from "redux/reducer/provinceSlice";
import { SearchIcon } from "@assets/icons";
// "proxy": "http://192.168.1.123:8888",

interface Props {
  onClose?: () => void;
  closeRef?: any,
  handleChoose: () => void;
}

export type ProvinceType = {
  Id: string
  name: string
}
export const initProvince: ProvinceType = {
  Id: "01",
  name: "Thành phố Hà Nội"
}
export type store = {
  province: any
}


const LocationBox = React.forwardRef(({ onClose, closeRef, handleChoose }: Props) => {

  const { t } = useI18n();
  const [provinces, setProvince] = useState<{ Id: string; name: string }[]>([]);
  const [choose, setChoose] = useState<ProvinceType>(initProvince);


  useEffect(() => {

    const json = localStorage.getItem("province")

    if (json === null) {
      localStorage.setItem("province", JSON.stringify(initProvince))
    } else {
      setChoose(JSON.parse(json))
    }

    setProvince(
      province_data.map((items) => {
        return {
          Id: items.Id,
          name: items.Name,
        };
      })
    );
  }, []);

  function removeVietnameseTones(str: string) {
    str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
    str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
    str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
    str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
    str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
    str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
    str = str.replace(/đ/g, "d");
    str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
    str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
    str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
    str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
    str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
    str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
    str = str.replace(/Đ/g, "D");
    // Some system encode vietnamese combining accent as individual utf-8 characters
    // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
    str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
    str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
    // Remove extra spaces
    // Bỏ các khoảng trắng liền nhau
    str = str.replace(/ + /g, " ");
    str = str.trim();
    // Remove punctuations
    // Bỏ dấu câu, kí tự đặc biệt
    str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
    return str;
  }
  const handleSearch = (e: any) => {
    const query = e.target.value;
    var
      updatedList = province_data.map((items) => {
        return {
          Id: items.Id,
          name: items.Name,
        };
      })

    updatedList = updatedList.filter((item) => {
      if (removeVietnameseTones(item.name.toLowerCase())
        .includes(removeVietnameseTones(query.toLowerCase()))) { return item; }
    })
    setProvince(updatedList)
  }

  const handleProvince = (Id: string, name: string) => {
    const chooseProvince: ProvinceType = {
      Id: Id,
      name: name
    }
    localStorage.setItem("province", JSON.stringify(chooseProvince))
    setChoose(chooseProvince)
    handleChoose()
    //@ts-ignore
    window.location = "/"
  }

  return (
    <div className="w-full shadow">
      <div className="p-spc14 bg-aqua-aq02">
        <div className="flex items-center justify-between">
          <p className="text_base text-white max-w-3/4">
            {t("text.title.get_location")}
          </p>
          <div ref={closeRef}>
            <CricleButton className="!border-white " icon={<CloseIconElm />} />
          </div>
        </div>
        <div className="mt-6 relative">
          <input
            className="w-full rounded-full px-3 h-8 text-xs placeholder:text-text-disable"
            placeholder={t("placeholder.search_location")} onChange={(e) => handleSearch(e)}
          />
          <SearchIcon className="absolute top-[0px] right-[10px] z-10 max-sm:hidden" />
        </div>
      </div>

      <div className="bg-white">
        <p className="text-base font-bold text-center text-text-disable py-3">
          {t("text.title.chose_city_or_province")}
        </p>
        <div className="h-[500px] overflow-y-auto aqua_scroll">
          <div className="grid grid-cols-2 border-t ">
            {provinces.map((provin, index) => {
              return (
                <div
                  key={provin.Id}
                  className={clsx(
                    "sc-480:text-base text-[16px] max-md:text-[14px] font-normal pl-3 pr-5 h-spc50 ",
                    {
                      "border-none": index + 1 == provinces.length,
                      "border-b": index + 1 != provinces.length,
                    }
                  )}
                >
                  <div
                    onClick={() => handleProvince(provin.Id, provin.name)}
                    className={clsx(
                      "px-2 h-full w-full text-text-main flex items-center cursor-pointer",
                      {
                        "rounded-lg bg-aqua-aq03 ": provin.Id == choose.Id,
                      }
                    )}
                  >
                    {provin.name}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
});

export default LocationBox;
