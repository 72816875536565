import { PrevIcon } from '@assets/icons/plust-mark/PrevIcon';
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Autoplay, FreeMode, Navigation, Pagination, Thumbs } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
interface IImage {
  url: string
}
const SwiperNew = ({ video, images }: { video: any, images: IImage[] }) => {
  const slideRef = useRef<any>(null);

  const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
  const nextSlide = useCallback(() => {
    if (slideRef.current) {
      slideRef.current.swiper.slideNext();
    }
  }, []);
  const prevSlide = useCallback(() => {
    if (slideRef.current) {
      slideRef.current.swiper.slidePrev();
    }
  }, []);

  useEffect(() => {
    console.log(video)
    console.log(images)
  }, [])

  return (
    <>
      <Swiper
        // style={{
        //   '--swiper-navigation-color': '#fff',
        //   '--swiper-pagination-color': '#fff',
        // }}
        spaceBetween={10}
        navigation={true}
        thumbs={{ swiper: thumbsSwiper }}
        modules={[FreeMode, Navigation, Thumbs]}
        className="w-full hello object-contain bg-white lg:rounded-lg contact "
      >

        {
          video && <SwiperSlide>
            <div className='lg:h-[460px] xl:h-[550px] ssm:h-[370px] relative lg:rounded-lg shadow-shd020 flex justify-center items-center bg-white'>
              <video
                src={video} muted loop={true} className=' rounded-md w-full h-full ' controls={true}
              ></video>
            </div>
          </SwiperSlide>
        }
        {
          images.map((item, index) => {
            return <SwiperSlide key={index}>
              <div className='lg:h-[460px] xl:h-[550px] ssm:h-[370px] relative lg:rounded-lg shadow-shd020 flex justify-center items-center bg-white'>
                <img src={item.url} className='w-full h-full object-contain bg-white lg:rounded-lg contact'></img>
              </div>
            </SwiperSlide>
          })
        }
      </Swiper>
      <div className="flex items-center">
        <button
          className="section2 flex items-center justify-center lg:w-12 lg:h-12 ssm:w-[30px] ssm:h-[30px] border border-neutra-neutra80 rounded-full"
          onClick={prevSlide}
        >
          <div className=" ssm:-rotate-90">
            <PrevIcon />
          </div>
        </button>
        <Swiper
          ref={slideRef}
          onSwiper={(e) => setThumbsSwiper(e)}
          loop={true}
          breakpoints={{
            100: {
              slidesPerView: 3,
              spaceBetween: 10,
            },
            992: {
              slidesPerView: 4,
              spaceBetween: 10,
            },
            1280: {
              slidesPerView: 6,
              spaceBetween: 20,
            },
            1536: {
              slidesPerView: 8,
              spaceBetween: 20,
            }
          }}
          freeMode={true}
          watchSlidesProgress={true}
          modules={[FreeMode, Navigation, Thumbs]}
          className="phan ssm:w-[80%] sm:w-[90%] cursor-pointer lg:w-[80%] xl:w-[90%]   516px] ssm:h-[66px] lg:h-[100px] flex items-center justify-center -z-1 w-full"
        >

          {
            video && <SwiperSlide>
              <video
                src={video} className='object-cover rounded-md w-full h-full '
              ></video>
            </SwiperSlide>
          }
          {
            images && images.map((item, index) => {
              return <SwiperSlide key={index}>
                <img src={item.url} className='object-contain bg-white rounded-md w-full h-full cursor-pointer ' />
              </SwiperSlide>
            })
          }



        </Swiper>


        <button
          className="section2 flex items-center justify-center lg:w-12 lg:h-12 ssm:w-[30px] ssm:h-[30px] border border-neutra-neutra80 rounded-full"
          onClick={nextSlide}
        >
          <div className=" ssm:rotate-90">
            <PrevIcon />
          </div>
        </button>
      </div>


    </>
  )
}

export default SwiperNew;
