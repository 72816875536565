import BreakCrumb, { BreadcrumbType } from "@components/Breadcrumb";
import { useParams } from "react-router-dom";
import React, { memo, useCallback, useContext, useEffect, useRef, useState, ReactNode } from "react";
import useViewport from "@hooks/useViewPort";
import categoryServices from "@services/categoryService";
import useI18n from "@hooks/useI18n";
import ProductSearch from "@components/product/ProductSearch";
import { IProduct } from "@pages/Product/ProductDetailNew";
import TitleSession from "@components/common/TitleSession";
import { first } from "lodash";
import { firstUpper } from "@utility/helper";
import CardItem from "@components/Card/CardItem";
import { data } from "autoprefixer";



export const initValue: ICategoryType[] = [
    { id: 0, data: { categoryNameVn: "...", categoryNameKr: "..." } },
    { id: 0, data: { categoryNameVn: "...", categoryNameKr: "..." } }
]
export type ICategoryType = {
    id?: number;
    data: { categoryNameVn: string, categoryNameKr?: string }
}
const CategorySubPage = () => {
    const [category, setCategory] = useState<ICategoryType[]>(initValue);
    const param = useParams();
    const [lastBreakCrumb, setlastBreakCrumb] = useState<BreadcrumbType>();
    const { width } = useViewport();
    const [countProduct, setCountProduct] = useState<number>(0)
    const [note, setNote] = useState<{ vn: string, kr?: string }>({ vn: "...", kr: "" })
    const { t, isVn } = useI18n();
    const [productSeen, setproductSeen] = useState<IProduct[]>([]);

    const breakcrumData: BreadcrumbType[] = [
        {
            name: { categoryNameVn: "Trang chủ", categoryNameKr: "홈페이지" },
            clickable: true,
            active: false,
            link: "/",
        },
        {
            name: category[0].data,
            clickable: true,
            active: false,
            link: "/category/" + category[0].id,
        },
        {
            name: category[1].data,
            clickable: true,
            active: false,
            link: "/category/" + category[0].id + "/" + category[1].id,
        },

    ]
    function findId(id?: number, idCategory?: string) {
        return id?.toString() === idCategory
    }


    useEffect(() => {

        if (localStorage.getItem("ProductWatched") == undefined) {
            localStorage.setItem("ProductWatched", JSON.stringify([]))
        } else {
            var item = new Array<IProduct>;
            const json = localStorage.getItem("ProductWatched");
            item = JSON.parse(json == null ? "" : json)
            setproductSeen(item)
        }

        const categoryId = param.id;
        const subCategoryId = param.idCategory;
        console.log("s", subCategoryId)
        if (
            categoryId
        ) {
            categoryServices.getCategoryByIdNew(categoryId).then(data => {
                console.log(data)
                const subCategory = data.subCategoryList.filter((item) => findId(item.id, subCategoryId))
                setNote({ vn: subCategory[0].noteSubVn !== null ? subCategory[0].noteSubVn : "...", kr: subCategory[0].noteSubKr !== null ? subCategory[0].noteSubKr : "..." })
                const level2: ICategoryType = {
                    id: subCategory[0].id,
                    data: { categoryNameVn: subCategory[0].subCategoryNameVn, categoryNameKr: subCategory[0].subCategoryNameKr }
                }
                const level: ICategoryType = { id: data.id, data: { categoryNameVn: data.categoryNameVn, categoryNameKr: data.categoryNameKr } }
                if (subCategory !== null)
                    setCategory([level, level2])
            })
        }
        console.log(category)
    }, [param])

    const handleCountProduct = (count: number) => {
        setCountProduct(count)
    }

    return (
        <div className="pt-[50px] max-sm:pt-[0px] bg-gray-100">


            <div className="product_box">
                {
                    width >= 1024 && (
                        <BreakCrumb data={breakcrumData} lastData={lastBreakCrumb} normalClass="text-normal1" activeClass="font-bold" />
                    )
                }
                <TitleSession
                    text={isVn ? category[1].data.categoryNameVn : (category[1].data.categoryNameKr
                        ? category[1].data.categoryNameKr : '')}
                    check="!hidden"
                    className="w-full mb-[35px] mt-[35px] uppercase max-sm:mb-[10px] "
                />
                <div className="max-sm:hidden block">
                    <p className="pt-[20px] max-md:text-[14px] text-[18px]"><b>{isVn ? category[1].data.categoryNameVn :
                        category[1].data.categoryNameKr}</b> ({countProduct} {t("global.footer.produc_special.index")} )</p>
                    <hr className="w-full h-[2px] bg-main mb-[0px]" />
                </div>

            </div>
            <ProductSearch
                isText={false}
                handleCountProduct={handleCountProduct}
                idCategory={category[0].id}
                idSubCategory={category[1].id}
                name={isVn ? category[1].data.categoryNameVn : category[1].data.categoryNameKr} />

            <div className="product_box">
                <div className="flex items-center max-sm:block max-sm:pb-[43px]">
                    <TitleSession
                        text={firstUpper(t("product.viewed-products"))}
                        className="!w-full !h-fit !text-[18px] max-md:text-[14px] "
                    />
                    <span className="max-md:hidden max-md:text-[14px] text-[18px] ml-[10px]"> ( {productSeen?.length}  {t("global.footer.produc_special.index")} )</span>
                </div>
                <hr className="w-full h-[2px] bg-main mb-[0px] mt-[0px] max-sm:h-0" />

                {
                    productSeen.length > 0 ?
                        <div className="grid grid-cols-4 gap-4 max-sm:grid-cols-2  max-md:grid-cols-3 pt-[40px] max-md:pt-[20px]">
                            {
                                productSeen.map(item => {
                                    return <CardItem item={item} />
                                })
                            }

                        </div>
                        : <p className="pt-[20px]">{t("product.product-over")}</p>
                }
            </div>
            <div className="product_box ">
                <div className="mt-[35px] flex max-sm: items-center">
                    <TitleSession
                        text={firstUpper(t("product.product-note"))}
                        className="!w-fit !h-fit !text-[18px] max-md:text-[14px] "
                    />
                    <span className=" max-sm:hidden font-bold lowercase max-md:text-[14px] text-[18px] ml-[4px]">  {isVn ? category[1].data.categoryNameVn : category[1].data.categoryNameKr}</span>
                </div>
                <hr className="w-full h-[2px] bg-main mb-[0px] mt-[0px]" />
                <p className="pt-[20px] pb-[20px]">{isVn ? note.vn : note.kr}</p>
            </div>

        </div>
    );
}

export default CategorySubPage;