export const colors ={
    gray03: "#8E8E8E",
    gray06: "#939393",
    gray_A1A0A3: "#A1A0A3",
    black: "#000000",
    black02: "#313131",
    white: "#FFFFFF",
    main: "#FF0000",
    aqua02: "#00C3AB",
    neutral80: "#C4C4C4",
    darkOrange: "#FF7D03",
    darkGreen: "#17646E",
    cancel_color: "#EA222A",
    bg_7E8B99: "#7E8B99",
    success:"#01A63E",
    warring:"#EA222A",
    error:"#FFE600",
    blue01: "#0073E5",
    error500: "#E73F3F",
    green99b: "#99BC20"
}