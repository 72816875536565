import React from "react";
import { DistributorImage, TuVan } from "@assets/icons";
import SuccesedIcon from "@assets/iconElements/SuccesedIcon";
import useI18n from "@hooks/useI18n";

const DistributorSession = () => {
  const { t } = useI18n();
  const listSucced = [
    "global.distributor.successed.first",
    "global.distributor.successed.second",
    "global.distributor.successed.third",
    // "global.distributor.successed.forth",
    "global.distributor.successed.fiveth",
    "global.distributor.successed.sixth",
  ];
  return (
    <>
      <div
        className="lg:px-[130px] max-xl:px-[20px] 1.5xl:px-[130px] xl:pb-[120px] xl:pt-[99px] 2xl:pb-[160px] max-sm:hidden 2xl:pt-[100px] max-xl:py-[50px] bg-p  bg-no-repeat bg-cover bg-top"
        style={{
          backgroundImage: `url(${DistributorImage})`,
        }}
      >
        <div className="xl:max-w-screen-sm lg:max-w-1/2 w-full">
          <p className="text-2xl uppercase">
            {t("global.distributor.intened_for")}
          </p>
          <p className="text_linear text-40 font-bold uppercase ">
            {t("global.distributor.distributor")}
          </p>
          <p className="text-text-disable text-base mb-6 max-xl:w-[50%]">
            {t("global.distributor.policy")}
          </p>
          {listSucced.map((item, index) => {
            return (
              <div key={index} className="flex gap-1 mb-[10px]">
                <SuccesedIcon />
                <p className="text-base">{t(item)}</p>
              </div>
            );
          })}
        </div>
      </div>
      <div
        className=" hidden lg:px-[130px] max-xl:px-[20px] 1.5xl:px-[130px] xl:pb-[120px] xl:pt-[99px] 2xl:pb-[160px] max-sm:block 2xl:pt-[100px] max-xl:py-[50px] bg-p  bg-no-repeat bg-cover bg-top"

      >
        <div className="xl:max-w-screen-sm lg:max-w-1/2 w-full">
          <p className="text-[20px] uppercase text-[#313131]">

            {t("global.distributor.intened_for")}
          </p>
          <p className="text_linear text-[16px] font-bold uppercase py-[13px] ">
            {t("global.distributor.distributor")}
          </p>
          <p className="text-text-disable text-base mb-6 ">
            {t("global.distributor.policy")}
          </p>
          {listSucced.map((item, index) => {
            return (
              <div key={index} className="flex gap-1 mb-[10px]">
                <SuccesedIcon />
                <p className="text-base">{t(item)}</p>
              </div>
            );
          })}
          <div className="w-full flex items-center justify-center">
            <img src={TuVan} alt="" className="pt-[10px]" />
          </div>
          <div>
          </div>
        </div>
      </div>


    </>
  );
};

export default DistributorSession;
