import {
  ArrowDownIcon,
  CartIcon,
  CircleFilledIcon,
  GiftOutlineIcon,
  LineIcon,
  NextArrowIcon,
  PrevArrowIcon,
  Tick
} from "@assets/icons";
import { PrevIcon } from "@assets/icons/plust-mark/PrevIcon";
import { SaleIcon } from "@assets/icons/plust-mark/SaleIcon";
import BreakCrumb, { BreadcrumbType } from "@components/Breadcrumb";
import ProductCarousel from "@components/Carousel/ProductCarousel";
import ColorPicker from "@components/ColorPicker/ColorPicker";
import LoadingPage from "@components/LoadingPage";
import GalleryProductModal from "@components/Modal/GalleryProductModal";
import SizeParametersModal from "@components/Modal/SizeParametersModal";
import SizePicker from "@components/SizePicker/SizePicker";
import AmountChange from "@components/common/AmountChange";
import BreakCrum from "@components/common/BreakCrum";
import { CartItemNew, useCart } from "@contexts/CartContext";
import { ToastContex } from "@contexts/ToastContex";
import { ModalContext } from "@contexts/contextModal";
import useAutosizeTextArea from "@hooks/useAutosizeTextArea";
import useI18n from "@hooks/useI18n";
import ProductServices, {
  ProductColor,
  ProductItem,
  ProductItemV2,
  ProductSize,
} from "@services/ProductServices";
import convertToHtml from "@utility/convertoHtml";
import { firstUpper, formatMoney, some } from "@utility/helper";
import clsx from "clsx";
import React, { memo, useCallback, useContext, useEffect, useRef, useState, ReactNode } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { DiscountElement } from "./DiscountElement";
import DynamicButton from "@components/Buttons/DynamicButton";
import CricleButton from "@components/Buttons/CricleButton";
import { colors } from "@utility/colors";
import { ShoppingCart } from "@assets/icons/plust-mark/ShoppingCart";
import { Cart } from "@assets/icons/plust-mark/Cart";
import { HeadPhone } from "@assets/icons/plust-mark/HeadPhone";
import { ProductInfo } from "./ProductInfo";
import Specifications from "./Specifications";
import { useTranslation } from "react-i18next";
import ProductInTheSameCtegory from "./ProductInTheSameCtegory";
import GetFreeConsulationModal from "./modal/GetFreeConsultationModal";
import useViewport from "@hooks/useViewPort";
import PrevIconElm from "@assets/iconElements/PrevIconElm";
import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import NewAdd from "@components/AdminComponents/New/NewAdd";
import { boolean } from "yup";
import { useSelector } from "react-redux";
import { ProvinceType, initProvince, store } from "@components/LocationComponent/LocationBox";
import { lowerCase } from "lodash";
import categoryServices from "@services/categoryService";
import { ICategoryType, initValue } from "@pages/CategoryPage/CategorySub";
import SwiperNew from "./SwiperNew";

const breakcrumData: BreadcrumbType[] = [
  {
    name: "Trang chủ",
    clickable: true,
    active: false,
    link: "/",
  },
  {
    name: "Adidas",
    clickable: true,
    active: false,
    link: "/",
  },
];

export const ServiceItem = memo((props: { icon: ReactNode, content: string }) => {
  const { icon, content } = props;
  return (
    <>

    </>
  )
});

export const TextGradient = memo((props: { title: string, clssName?: string }) => {
  return (
    <>
      <div className={clsx('text-transparent font-NunitoSans bg-clip-text bg-gradient-to-tr from-red-red500 to-orange-orange400',
        props.clssName
      )}>
        {props.title}
      </div>
    </>
  )
});

export const ButtonTabGradient = memo((props: { title: string, clssName?: string, currentTab: string, keyTab: string, gradient?: boolean }) => {
  const { t } = useTranslation();
  return (
    <>
      <div className={clsx('font-NunitoSans mb-[5px]',
        {
          'text-transparent bg-clip-text bg-gradient-to-tr from-red-red500 to-orange-orange400 ': props.currentTab === props.keyTab && props.gradient,
          'text-text-disable ': props.currentTab !== props.keyTab
        },
        props.clssName
      )}>
        <p className="">{t(props.title)}</p>
      </div>
    </>
  )
});

export const Outstanding = memo((props: { content: string }) => {
  return (
    <>

    </>
  )
})

interface PropsPicker {
  selected: { key: any, name: any }[],
  data: IAttributeFes[],
  handleClick: (keySelected: string, atb: any) => void,
  keySelected: string,
  name: string,
  atb: any,
  datAatb: IAttributeFes,
  dataAtbValue: IAttributeFeValues,
  attributeFe: string,
  idxs: number,
  actionKey: { key: any, name: any }
  // value: string
}

export const SelectedPicker = (props: PropsPicker) => {
  const { data, handleClick, selected, name, atb, keySelected, actionKey = { key: null, name: null } } = props;
  const [atbs, setatbs] = useState<IAttributeFes>();
  const [atbvalue, setatbvalue] = useState<IAttributeFeValues>();
  const [select, setSelect] = useState<{ key: any, name: any }>();

  const handleClickATB = (atb: string) => {
    handleClick(keySelected, name);
  }

  return (
    <div className={clsx(
      "w-auto px-2 h-[30px] mb-[5px] text-black-bl0 2lg:text-normal1 cursor-pointer ssm:text-wap-regular2 flex border border-neutra-neutra80 items-center justify-center rounded relative",
      {
        "bg-aqua-aq02 text-white ": actionKey.name == name,
        "bg-white": actionKey.name != name,
      })
    } onClick={() => handleClickATB(atb)}>
      {firstUpper(atb)}
      {/* {
        data.total <= 0 && (
          <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2'>
            <LineIcon />
          </div>
        )
      } */}
    </div>
  )
}

interface IAttributeFeValues {
  valueVn: string,
  valueKr: string
}
interface IAttributeFes {
  attributeFeValues: IAttributeFeValues[],
  attributeFeNameKr: string,
  attributeFeNameVn: string
}
interface IAttributes {
  valueVn: string,
  valueKr: string,
  attributeNameVn: string,
  attributeNameKr: string
}
interface IProductDetails {
  productDetailId: number,
  priceDetail: number,
  promoDetail: number,
  actualPriceDetail: number,
  stockQuantity: number,
  soldQuantity: number,
  addressWarehouse: string,
  imageDetailUrl: string,
  attributes: IAttributes[]

}

export interface IProduct {
  id?: number,
  productCode: string,
  productNameVn: string,
  productNameKr: string,
  categoryId: number,
  subCategoryId: number,
  price: number,
  promo: number,
  actualPrice: number,
  stockQuantity: number,
  salientFeaturesVn: string,
  salientFeaturesKr: string,
  detailVn: string,
  detailKr: string,
  specVn: string,
  specKr: string,
  featured: number,
  createAt: string,
  videoUrl: string,
  warehouse:
  {
    address: string
  }[]
  ,
  attributeFes: IAttributeFes[],
  productDetails: IProductDetails[],
  images: {
    url: string
  }[]
}
export const SERVICES = [
  { icon: <PrevIcon />, content: "t('product.faith.text')" },
  { icon: <PrevIcon />, content: "Cam Kết Giá Tốt Nhất & CTKM Hấp Dẫn" },
  { icon: <PrevIcon />, content: "Dịch Vụ Bảo Hành Chuyên Nghiệp" },
  { icon: <PrevIcon />, content: "Giao Hàng Toàn Quốc" },
]
export type ExploreTabKey = 'product-info' | 'specifications';
export const exploreTabs: { key: ExploreTabKey; text: string }[] = [
  { key: 'product-info', text: 'product.product-info' },
  // { key: 'specifications', text: 'product.table.specifications' },
];

export type ExploreTabKeyProducts = 'products-in-the-same-category' | 'viewed-products';
export const exploreTabsProducts: { key: ExploreTabKeyProducts; text: string }[] = [
  { key: 'products-in-the-same-category', text: 'product.products-in-the-same-category' },
  { key: 'viewed-products', text: 'product.viewed-products' },
];


const ProductDetailNew = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { onChangeItem, cartItems } = useCart();
  const { onAddToast } = useContext(ToastContex);
  const [productData, setproductData] = useState<ProductItem>();
  const { setContentModal, setShowModal } = useContext(ModalContext);
  const param = useParams();
  const [lastBreakCrumb, setlastBreakCrumb] = useState<BreadcrumbType>();

  const [quantityDescActive, setQuantityDescActive] = useState<boolean>(false);
  const [quantityAscActive, setQuantityAscActive] = useState<boolean>(true);
  const [quantity, setQuantity] = useState<number>(1);

  const [imageView, setimageView] = useState<string>();
  const [videoView, setvideoView] = useState<string>();
  const [isVideo, setIsVideo] = useState<boolean>(false);

  const [productRelated, setproductRelated] = useState<ProductItemV2[]>();
  const [productSeen, setproductSeen] = useState<IProduct[]>();
  const [currentTab, setCurrentTab] = useState<ExploreTabKey>("product-info");
  const [currentTabProducts, setCurrentTabProducts] = useState<ExploreTabKeyProducts>("products-in-the-same-category");

  const [category, setCategory] = useState<ICategoryType[]>(initValue);
  const [productDetail, setProductDetail] = useState<IProduct>();
  const [attributeFes, setAttributeFes] = useState<IAttributeFes[]>([]);
  const [keySelected, setKeySelected] = useState<{ key: string, name: string }[]>([]);
  const [productDetailItem, setProductDetailItem] = useState<any>();
  const { isVn } = useI18n();
  const { width } = useViewport();
  const slideRef = useRef<any>(null);
  const nextSlide = useCallback(() => {
    console.log(slideRef)
    if (slideRef.current) {
      slideRef.current.swiper.slideNext();
    }
  }, []);
  const prevSlide = useCallback(() => {
    if (slideRef.current) {
      slideRef.current.swiper.slidePrev();
    }
  }, []);

  const showModalSize = () => {
    setContentModal(<SizeParametersModal imageUrl={productData?.imageCheck} />);
    setShowModal(true);
  };

  const showGetFreeConsulation = () => {
    setContentModal(<GetFreeConsulationModal />);
    setShowModal(true);
  }
  useEffect(() => {


    const productId = param.productId;
    if (productId) {
      getProductId(productId);
    }
  }, [param, param.productId]);

  const attribute = param.attribute;
  const paramAttributes = attribute?.split(',');



  useEffect(() => {
    if (productDetailItem) {
      setimageView(productDetailItem.imageDetailUrl)
    }

  }, [productDetailItem]);



  function findId(id?: number, idCategory?: string) {
    return id?.toString() === idCategory
  }

  const getProductId = async (id: any) => {
    try {
      const json = localStorage.getItem("province")
      let province;
      if (json == null) {
        province = initProvince
      } else {
        province = JSON.parse(json)
      }
      const res: IProduct = await ProductServices.findProductByIdAndAddress(id, province.name);
      if (res) {
        setProductDetail(res);
        console.log(res?.videoUrl !== null, "video")
        if (res?.videoUrl !== null && res.videoUrl.trim() !== "") {
          setvideoView(res?.videoUrl)
          setIsVideo(true)
          setimageView(undefined);
        } else {
          setvideoView(undefined)
          setIsVideo(false)
          setimageView(res?.images[0]?.url);
        }

        categoryServices.getCategoryByIdNew(res.categoryId).then(data => {
          if (res.subCategoryId !== null) {
            const subCategory = data.subCategoryList.filter((item) => findId(item.id, res.subCategoryId.toString()))
            const level2: ICategoryType = {
              id: subCategory[0].id,
              data: { categoryNameVn: subCategory[0].subCategoryNameVn, categoryNameKr: subCategory[0].subCategoryNameKr }
            }
            const level: ICategoryType = { id: data.id, data: { categoryNameVn: data.categoryNameVn, categoryNameKr: data.categoryNameKr } }
            setCategory([level, level2])
          } else {
            const level: ICategoryType = { id: data.id, data: { categoryNameVn: data.categoryNameVn, categoryNameKr: data.categoryNameKr } }
            setCategory([level])
          }

        })

        setAttributeFes(res?.attributeFes);
        const data = await ProductServices.getAllProducts({ page: 0, size: 10, sort: 'id,desc', categoryId: res.categoryId, subCategoryId: res.subCategoryId, address: province.name })
        setproductRelated(data.content)
        if (localStorage.getItem("ProductWatched") == undefined) {
          var item = new Array<IProduct>;
          item.push(res)
          localStorage.setItem("ProductWatched", JSON.stringify(item))
        } else {
          var item = new Array<IProduct>;
          const json = localStorage.getItem("ProductWatched");
          item = JSON.parse(json == null ? "" : json)
          var check = true;
          item.forEach((data) => {
            if (data.id === res.id) {
              check = false;

            }
          })
          check && item.push(res)
          setproductSeen(item)
          localStorage.setItem("ProductWatched", JSON.stringify(item))
        }

      }
    } catch (error) {

    }
  }

  const handleSelected = (key: string, atb: any) => {
    const keySelectedList = [...keySelected];
    //&& index[0].name != atb && atb && index[0].key == key
    const index = keySelectedList.filter((it) => it.key == key);

    if (index.length > 0 && index[0].name != atb && atb && index[0].key == key) {
      const data = keySelected.map((it) => {
        if (it.key == key) {
          return { ...it, name: atb }
        }
        return it
      });
      getProductDetailItem(data);
      setKeySelected(data);
    } else if (index.length > 0 && index[0].name == atb && index[0].key == key) {
      const data = keySelected.filter((it) => it.key !== key);
      getProductDetailItem(data);
      setKeySelected(data);
    } else {
      const newSelected = {
        key: key,
        name: atb
      }
      getProductDetailItem([...keySelected, newSelected]);
      setKeySelected([...keySelected, newSelected]);
    }


  }


  const getProductDetailItem = (selected: { key: any, name: any }[]) => {
    const key = selected.map((it) => {
      return { attributeNameVn: it.key.slice(0, -4), valueVn: it.name.slice(0, -5) }
    });

    const it_key_sort = key.sort((a, b) => {
      let fa = a.attributeNameVn.toLowerCase(),
        fb = b.attributeNameVn.toLowerCase();

      if (fa < fb) {
        return -1;
      }
      if (fa > fb) {
        return 1;
      }
      return 0;
    });

    const item = productDetail?.productDetails?.map((its, idx) => {
      return {
        ...its, attributes: its.attributes.map((it) => {
          return { attributeNameVn: it.attributeNameVn, valueVn: it.valueVn }
        }), attributesKr: its.attributes.map((it) => {
          return { attributeNameKr: it.attributeNameKr, valueKr: it.valueKr }
        })
      }
    })

    const result = item?.filter((its) => JSON.stringify(it_key_sort) == JSON.stringify(its.attributes) == true);

    if (result && result?.length > 0) {
      setProductDetailItem(result[0]);
    } else {
      setProductDetailItem(null)
    }
    // setProductDetailItem();
  }

  const handleIncrease = () => {

    const json = localStorage.getItem("province")
    let province;
    if (json == null) {
      province = initProvince
    } else {
      province = JSON.parse(json)
    }
    if (productDetail?.productDetails.length === 0) {
      onAddToast({ type: "warn", message: t("warning.product-province", { "province": lowerCase(province.name) }) })
      return;
    }


    if (productDetailItem === undefined || productDetailItem === null) {
      onAddToast({ type: "warn", message: t("warning.product") });
      return;
    }

    let total = productDetailItem.stockQuantity || 0;
    if (total == 0) {
      onAddToast({ type: "warn", message: t("warning.product-stock") });
    }
    if (total == 1) {
      onAddToast({ type: "warn", message: t("warning.product-one") });
    }
    if (total == quantity + 1) {
      setQuantity(quantity + 1);
      setQuantityAscActive(false);
    }
    if (total > quantity) {
      setQuantity(quantity + 1);
      setQuantityDescActive(true);
    }
  };

  const handleDecrease = () => {
    let total = productDetailItem.stockQuantity || 0;
    if (total > quantity - 1) {
      setQuantityAscActive(true);
    }
    setQuantity((prev) => {
      if (prev == 2) setQuantityDescActive(false);
      if (prev > 1) {
        return prev - 1;
      } else {
        return prev;
      }
    });
  };




  const breakcrumData: BreadcrumbType[] = [
    {
      name: { categoryNameVn: "Trang chủ", categoryNameKr: "홈페이지" },
      clickable: true,
      active: false,
      link: "/",
    },
    {
      name: category[0].data,
      clickable: true,
      active: false,
      link: "/category/" + category[0].id,
    },
    {
      name: category[1] && category[1].data,
      clickable: true,
      active: false,
      link: "/category/" + category[0].id + "/" + (category[1] ? category[1].id : ''),
    },

  ]

  const breakcrumData2: BreadcrumbType[] = [
    {
      name: { categoryNameVn: "Trang chủ", categoryNameKr: "홈페이지" },
      clickable: true,
      active: false,
      link: "/",
    },
    {
      name: category[0].data,
      clickable: true,
      active: false,
      link: "/category/" + category[0].id,
    }

  ]


  const handleAddToCart = () => {
    // let priceTemp = productData?.price || 0;
    // let saleTmp = sizeSelected?.sale || 0;
    // console.log("sizeSelected", sizeSelected);

    const json = localStorage.getItem("province")
    let province;
    if (json == null) {
      province = initProvince
    } else {
      province = JSON.parse(json)
    }
    if (productDetail?.productDetails.length === 0) {
      onAddToast({ type: "warn", message: t("warning.product-province", { "province": province.name }) })
      return;
    }

    if (productDetailItem === undefined || productDetailItem === null) {
      onAddToast({ type: "warn", message: t("warning.product") });
      return;
    }

    if (
      quantity > productDetailItem.stockQuantity
    ) {

      onAddToast({ type: "warn", message: t("warning.product-stock") });
      return;
    }

    const productExists = cartItems.find((it) => it.productDetailId === productDetailItem.productDetailId);
    if (productExists) {
      onAddToast({ type: "warn", message: t("warning.product-existed") });
    } else {
      const cartItem: CartItemNew = {
        ...productDetailItem,
        productDetailNameVn: productDetail?.productNameVn,
        productDetailNameKr: productDetail?.productNameKr,
        choose: true,
        quantitySelected: quantity,
        productId: productDetail?.id
      };
      onChangeItem(productData?.id || "", cartItem);
      onAddToast({ type: "success", message: t("success.cart") });
    }


  };


  const handleBuyNow = () => {
    const json = localStorage.getItem("province")
    let province;
    if (json == null) {
      province = initProvince
    } else {
      province = JSON.parse(json)
    }
    if (productDetail?.productDetails.length === 0) {
      onAddToast({ type: "warn", message: t("warning.product-province", { "province": lowerCase(province.name) }) })
      return;
    }

    if (productDetailItem === undefined || productDetailItem === null) {
      onAddToast({ type: "warn", message: t("warning.product") });
      return;
    }

    if (
      quantity > productDetailItem.stockQuantity
    ) {

      onAddToast({ type: "warn", message: t("warning.product-stock") });
      return;
    }

    const productExists = cartItems.find((it) => it.productDetailId === productDetailItem.productDetailId);
    if (productExists) {
      onAddToast({ type: "warn", message: t("warning.product-existed") });
    } else {
      const cartItem: CartItemNew = {
        ...productDetailItem,
        productDetailNameVn: productDetail?.productNameVn,
        productDetailNameKr: productDetail?.productNameVn,
        choose: true,
        quantitySelected: quantity,
        productId: productDetail?.id
      };
      onChangeItem(productData?.id || "", cartItem);
      onAddToast({ type: "success", message: t("success.cart") });
      navigate("/cart");

    }
  };

  const [thumbsSwiper, setThumbsSwiper] = useState(null);




  return (
    <div className="2lg:pt-6 2lg:pb-10 ssm:pb-6 bg-gray-100">

      {/* <div className="container px-4 lg:px-8">
        <BreakCrumb data={breakcrumData} lastData={lastBreakCrumb} normalClass="text-normal1" activeClass="font-bold"  />
      </div> */}
      <div className="3xl:px-[172px] 150px] lg:px-10 ssm:px-0">
        {
          width >= 1024 && (
            <BreakCrumb data={category.length === 1 ? breakcrumData2 : breakcrumData} lastData={lastBreakCrumb} normalClass="text-normal1" activeClass="font-bold" />
          )
        }
        <div className="layout lg:pt-5 ssm:pt-0">
          <div className="desc">
            <div className="flex-1 site flex flex-col lg:gap-x-5 ssm:gap-y-4">

              <SwiperNew video={videoView} images={productDetail && productDetail.images ? productDetail.images : []} />
              {/* <div className="lg:px-0 ssm:px-3 section1 w-full 636px] flex  ssm:flex-row items-center lg:gap-y-3 ssm:gap-x-[7px]">
                <div className="lg:w-12 lg:h-12 ssm:w-[30px] ssm:h-[30px]">
                  <button
                    className="w-full h-full flex items-center justify-center border border-neutra-neutra80 rounded-full"
                    onClick={prevSlide}
                  >
                    <div className=" ssm:-rotate-90">
                      <PrevIcon />
                    </div>
                  </button>
                </div>
                <Swiper
                  modules={[Autoplay, Navigation]}
                  direction={"horizontal"}
                  ref={slideRef}
                  // loop={true}
                  breakpoints={{
                    992: {
                      slidesPerView: 4,
                      spaceBetween: 10,
                    },
                    1280: {
                      slidesPerView: 6,
                      spaceBetween: 20,
                    },
                    1536: {
                      slidesPerView: 8,
                      spaceBetween: 20,
                    }
                  }}
                  spaceBetween={10}
                  className="ssm:w-[80%] sm:w-[90%] cursor-pointer lg:w-[80%] xl:w-[90%]   516px] ssm:h-[66px] lg:h-[100px] flex items-center justify-center -z-1"
                  autoplay={{
                    delay: 5000,
                    disableOnInteraction: false,
                  }}
                >
                  {videoView !== undefined &&

                    <SwiperSlide >

                      <video onClick={() => {
                        setIsVideo(true)
                        setimageView(undefined)
                      }} src={videoView} muted loop={true} className={clsx('object-cover rounded-md w-full h-full ',
                        { 'border-[1px] border-aqua-aq02': isVideo }

                      )}></video>
                    </SwiperSlide>
                  }

                  {productDetail && productDetail.images.map((it, idx) => (

                    <SwiperSlide key={idx}>

                      <img

                        onClick={() => {
                          setIsVideo(false)
                          setimageView(it.url)
                        }}
                        className={clsx('object-contain bg-white rounded-md w-full h-full cursor-pointer ',
                          { 'border-[1px] border-aqua-aq02': imageView === it.url }
                        )}
                        src={it.url}
                        alt="product-image"
                      />
                    </SwiperSlide>
                  ))}
                </Swiper>
                <button
                  className="section2 flex items-center justify-center lg:w-12 lg:h-12 ssm:w-[30px] ssm:h-[30px] border border-neutra-neutra80 rounded-full"
                  onClick={nextSlide}
                >
                  <div className=" ssm:rotate-90">
                    <PrevIcon />
                  </div>
                </button>
              </div>
              <div
                // onClick={handleShowGallery}
                className="w-full 640px] lg:h-[460px] xl:h-[550px] ssm:h-[370px] relative lg:rounded-lg shadow-shd020 flex justify-center items-center bg-white"
              >
                {isVideo ? <video src={videoView} className="640px] lg:h-[460px] xl:h-[550px] ssm:h-[370px] w-full" muted controls={true} loop={true}></video> :
                  <img
                    className="w-full h-full object-contain bg-white lg:rounded-lg contact"
                    src={imageView}
                    alt="product-img"
                  />
                }


              </div> */}
            </div>
          </div>
          <div className="toc ssm:px-[15px] lg:px-0 ssm:pt-6 lg:pt-0">
            <div className="w-full h-auto rounded-lg relative bg-white">

              {productDetail?.promo !== 0 ? <DiscountElement content={productDetailItem ? productDetailItem.promoDetail : productDetail?.promo} widthIcon={97} heightIcon={34}
                className="text-white text-[20px] leading-normal font-NunitoSans font-extrabold" /> : ""}
              <div className="pt-[50px] px-1 lg:px-[13px]  2lg:pb-6 ssm:pb-[18px] max-sm:px-[10px]">
                <p className="font-NunitoSans 2lg:text-normal2 ssm:text-normal1 text-text-main font-bold">{isVn ? productDetail?.productNameVn : productDetail?.productNameKr}</p>
                <div className="pt-3 flex flex-row items-center gap-x-4 border-b-[2px] border-aqua-aq02">
                  <TextGradient title={productDetailItem ? formatMoney(productDetailItem?.actualPriceDetail) : formatMoney(productDetail?.actualPrice || 0)} clssName="2lg:text-title ssm:text-[20px] ssm:leading-normal font-bold " />
                  {productDetail?.actualPrice !== productDetail?.price ? <p className="line-through font-NunitoSans 2lg:text-normal1 ssm:text-wap-regular2 font-normal text-gray-600">{productDetailItem ? formatMoney(productDetailItem?.priceDetail) : formatMoney(productDetail?.price || 0)}</p>
                    : ''}
                </div>

                <div className="lg:pt-4  ssm:pt-[34px] lg:pl-0 ssm:pl-3 flex flex-col gap-x-3 items-start gap-y-2">
                  {
                    (attributeFes ?? []).map((its, idxs) => {
                      return (
                        <div className="flex items-center" key={idxs}>
                          <p className="font-NunitoSans font-normal 2lg:text-normal ssm:text-wap-regular2 text-black-bl0">{isVn ? its.attributeFeNameVn : its.attributeFeNameKr}:</p>
                          <div className=" pl-3 flex w-fit flex-wrap gap-x-2">
                            {
                              its.attributeFeValues.map((it, idx) => {
                                const actionKey = keySelected.filter((it) => it.key == `${its.attributeFeNameVn}_key`)
                                return (
                                  <>
                                    <SelectedPicker
                                      selected={actionKey}
                                      data={attributeFes}
                                      attributeFe={isVn ? its.attributeFeNameVn : its.attributeFeNameKr}
                                      keySelected={`${its.attributeFeNameVn}_key`}
                                      name={`${it.valueVn}_name`}
                                      atb={isVn ? it.valueVn : it.valueKr}
                                      handleClick={handleSelected}
                                      key={idx}
                                      datAatb={its}
                                      dataAtbValue={it}
                                      idxs={idxs}
                                      actionKey={actionKey[0]}

                                    />
                                  </>
                                )
                              })
                            }
                          </div>
                        </div>
                      )
                    })
                  }

                  <div className="flex w-fit flex-wrap gap-x-2">
                    {/* {colorSelected && colorSelected?.sizes && colorSelected?.sizes.map((s, i) => {
                      return (
                        <>
                          {i < 3 && (
                            <SizePicker
                              selected={sizeSelected}
                              data={s}
                              handleClick={setsizeSelected}
                              key={i}
                            />
                          )}
                        </>
                      );
                    })} */}
                  </div>
                </div>
                <div className="pt-3 lg:pl-0 ssm:pl-3 flex flex-row gap-x-3 items-center">
                  <p className="font-NunitoSans font-normal 2lg:text-normal ssm:text-wap-regular2 text-black-bl0">{t('product.quantity')}:</p>
                  <AmountChange
                    quantity={productDetailItem ? (productDetailItem?.stockQuantity > 0 ? quantity : 0) : 1}
                    descActive={quantityDescActive}
                    ascActive={quantityAscActive}
                    handleIncrease={handleIncrease}
                    handleDecrease={handleDecrease}

                  />
                </div>
                <div className="pt-4">
                  <div className="px-3 pt-2  ssm:pb-[2px] bg-aqua-aq03 rounded-lg">
                    <div className="pb-3">
                      <h1 className="font-NunitoSans text-normal font-bold text-neutra-neutra20">{t('product.faith.text')}</h1>
                    </div>
                    <div className="flex flex-col gap-y-2">
                      <div className="flex flex-row gap-x-1 items-center"><Tick />
                        <p className="font-NunitoSans text-wap-regular2 font-normal text-black-bl0">{t('product.faith.item-1')}</p>
                      </div>
                      <div className="flex flex-row gap-x-1 items-center"><Tick />
                        <p className="font-NunitoSans text-wap-regular2 font-normal text-black-bl0">{t('product.faith.item-2')}</p>
                      </div>
                      <div className="flex flex-row gap-x-1 items-center"><Tick />
                        <p className="font-NunitoSans text-wap-regular2 font-normal text-black-bl0">{t('product.faith.item-3')}</p>
                      </div>
                      <div className="flex flex-row gap-x-1 items-center"><Tick />
                        <p className="font-NunitoSans text-wap-regular2 font-normal text-black-bl0">{t('product.faith.item-4')}</p>
                      </div>

                    </div>
                  </div>
                </div>
                <div className="2lg:pt-3 ssm:pt-6 flex flex-row gap-x-1">
                  <DynamicButton
                    onClick={handleAddToCart}
                    text={t('product.add-to-cart')}
                    textGradient={true}
                    iconLeft={<ShoppingCart />}
                    className="detail !rounded-[20px] !px-[6px]  tracking-tighter !py-2 !min-w-[83px] !text-[16px] !h-[40px] leading-normal font-bold font-NunitoSans"
                  />
                  <DynamicButton
                    onClick={showGetFreeConsulation}
                    text={t('product.head-phone')}
                    textGradient={true}
                    iconLeft={<HeadPhone />}
                    className="detail flex-1 !rounded-[20px]  !min-w-[83px] tracking-tighter !text-[16px] !h-[40px] leading-normal font-bold font-NunitoSans"
                  />
                </div>
                <div className="2lg:pt-3 ssm:pt-[22px]">

                  <DynamicButton
                    onClick={handleBuyNow}
                    text={t('product.buy-now')}
                    gradien={true}
                    iconLeft={<Cart />}
                    className="w-full !rounded-[20px] !px-[6px] !py-2 !min-w-[83px] !h-[40px] !text-[16px] !bottom-0 leading-normal font-bold font-NunitoSans"
                  />
                </div>
                <div className="2lg:pt-3 ssm:pt-4 2lg:px-0 ssm:px-[11px]">
                  <p className="2lg:text-normal2 ssm:text-normal ssm:font-bold font-NunitoSans text-neutra-neutra20">{t('product.outstanding-features')}</p>
                  <div className="2lg:px-1 ssm:px-2 pt-3 flex flex-col gap-y-1">
                    <div className="flex flex-row gap-x-1 items-start">
                      <span>&#x2022;</span>
                      <div className="2lg:text-normal ssm:text-wap-regular2 font-normal font-NunitoSans text-neutra-neutra20" dangerouslySetInnerHTML={{ __html: productDetail ? (isVn ? productDetail.salientFeaturesVn : productDetail.salientFeaturesKr) : "" }}></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="body">
            <div className="2lg:pt-[60px] ssm:pt-[30px] flex-1 flex flex-row lg:px-0 ssm:px-4">
              {
                exploreTabs.map((it, idx) => {
                  return (
                    <button
                      key={idx}
                      onClick={() => setCurrentTab(it.key)}
                      className={clsx('flex-1 flex items-center justify-start',
                        {
                          'border-b-[3px] border-orange-lightPink': currentTab === it.key,
                          'mb-[1px] border-b-[1px] border-aqua-aq02': currentTab !== it.key
                        }
                      )}>
                      <ButtonTabGradient
                        keyTab={it.key}
                        title={it.text}
                        currentTab={currentTab}
                        gradient={true}
                        clssName=" lg:text-normal1 ssm:text-wap-regular2 font-bold "
                      />
                    </button>
                  )
                })
              }
            </div>
            <div className="2lg:pt-[34px] ssm:pt-7">
              <>
                {currentTab === 'product-info' && (
                  <div className="lg:px-0 ssm:px-4">
                    <ProductInfo content={isVn ? productDetail?.detailVn : productDetail?.detailKr} />
                  </div>
                )}
              </>
              <>
                {currentTab === 'specifications' && (
                  <div className="lg:px-0 ssm:px-4">
                    <ProductInfo content={isVn ? productDetail?.specVn : productDetail?.specKr} />
                  </div>
                )}
              </>
            </div>
          </div>
        </div>
      </div>
      <div className="3xl:px-[155px] 140px] ssm:px-4">
        <div className="pt-[34px] flex-1 flex flex-row">
          {
            exploreTabsProducts.map((it, idx) => {
              return (
                <button
                  key={idx}
                  onClick={() => setCurrentTabProducts(it.key)}
                  className={clsx('flex-1 flex items-center justify-start ',
                    {
                      'border-b-[3px] border-orange-lightPink': currentTabProducts === it.key,
                      'mb-[1px] border-b-[1px] border-aqua-aq02': currentTabProducts !== it.key
                    }
                  )}>
                  <ButtonTabGradient
                    keyTab={it.key}
                    title={it.text}
                    currentTab={currentTabProducts}
                    gradient={true}
                    clssName=" lg:text-normal1 ssm:text-wap-regular2 font-bold"
                  />
                </button>
              )
            })
          }
        </div>
        <div className="34px] lg:pt-6 ssm:pt-3 ">
          <>
            {currentTabProducts === 'products-in-the-same-category' && (
              productRelated && productRelated.length > 0 && (
                <ProductCarousel product={productRelated} type="seen" id={productDetail?.id} />
              )
            )}
          </>
          <>
            {currentTabProducts === 'viewed-products' && (
              productSeen && productSeen.length > 0 && (
                <ProductCarousel type="aladin" productSeen={productSeen} id={productDetail?.id} />
              )
            )}
          </>
        </div>
      </div>
    </div>
  );
};


export default ProductDetailNew;
