import { AddImage } from "@assets/icons";
import { useShowMessage } from "@components/Modal/DialogMessage";
import { useShowConfirm } from "@components/Modal/DiglogComfirm";
import BtnLoading from "@components/btn-loading/BtnLoading";
import { ToastContex } from "@contexts/ToastContex";
import useI18n from "@hooks/useI18n";
import { Introduce, IntroductionImage } from "@pages/AdminPage/ManagerIntroduce";
import IntroServices from "@services/IntroServices";
import TranslateService from "@services/TranslateService";
import UploadImage from "@services/UploadImage";
import { ALLOW_IMAGE_FILE_TYPE, MAX_IMAGE_BANNER_SIZE, ROUTES } from "@utility/constants";
import { useFormik } from "formik";
import { useContext, useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import * as yup from 'yup';

export default function IntroduceForm(
  { intros }: { intros: Array<Introduce> }
) {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const { onAddToast } = useContext(ToastContex);
  const { t, isVn } = useI18n();
  const [imageUrlIntro, setImageUrlIntro] = useState<any>()
  const [imageUrlIntroSecond, setImageUrlIntroSecond] = useState<any>()
  const [displayImage, setDisplayImage] = useState<any>()
  const [displayImageSecond, setDisplayImageSecond] = useState<any>()
  const inputRef = useRef<HTMLInputElement>(null);
  const inputRefSecond = useRef<HTMLInputElement>(null);
  const [intro, setIntro] = useState<any>();
  const [introSecond, setIntroSecond] = useState<any>();
  const navigate = useNavigate();
  const { showConfirm } = useShowConfirm();
  const { showError, showSuccess, showWarning } = useShowMessage();

  const onClick = (e: any) => {
    if (inputRef.current) {
      inputRef.current.click();
    }

  };

  const onClickSecond = (e: any) => {
    if (inputRefSecond.current) {
      inputRefSecond.current.click();
    }
  }

  const handleCancel = () => {
    navigate(`/admin/${ROUTES.admin.introduce.index}`);
  };

  useEffect(() => {
    setIntro(intros[0])
    setIntroSecond(intros[1])
    try {
      setDisplayImage(intros[0].introductionImage[0].url)
      setDisplayImageSecond(intros[1].introductionImage[0].url)
      setImageUrlIntro(intros[0].introductionImage[0].url)
      setImageUrlIntroSecond(intros[1].introductionImage[0].url)
      setValues({
        intro_titleVn: intros[0].titleVn || "",
        intro_titleKr: intros[0].titleKr || "",
        intro_contentVn: intros[0].content1_Vn || "",
        intro_contentKr: intros[0].content1_Kr || "",
        intro_second_titleVn: intros[1].titleVn || "",
        intro_second_titleKr: intros[1].titleKr || "",
        intro_second_content_1Vn: intros[1].content1_Vn || "",
        intro_second_content_2Vn: intros[1].content2_Vn || "",
        intro_second_content_1Kr: intros[1].content1_Kr || "",
        intro_second_content_2Kr: intros[1].content2_Kr || "",
      })
    } catch (ex) {
      setImageUrlIntro("")
      setImageUrlIntroSecond("")
    }
  }, []);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files && event.target.files[0];
      if (inputRef.current) {
        if (!file) {
          inputRef.current.value = "";
          return;
        }

        if (ALLOW_IMAGE_FILE_TYPE.indexOf(file.type) === -1) {
          showWarning("warning.image_type");
          inputRef.current.value = "";
          return;
        }

        if (file.size > MAX_IMAGE_BANNER_SIZE) {
          showWarning("warning.image_size");
          inputRef.current.value = "";
          return;
        }

        const reader = new FileReader();
        reader.onload = () => {
          setDisplayImage(reader.result);
        };
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append("file", file);
        const image = await UploadImage.uploadImage(formData);
        const jsonImage = JSON.parse(JSON.stringify(image));
        setImageUrlIntro(jsonImage.url);
      }
    } catch (ex) {
      showError("error.post_error");
    } finally {
      if (inputRef.current) {
        inputRef.current.value = "";
      }
    }
  };

  const handleFileChangeSecond = async (event: React.ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files && event.target.files[0];
      if (inputRefSecond.current) {
        if (!file) {
          inputRefSecond.current.value = "";
          return;
        }

        if (ALLOW_IMAGE_FILE_TYPE.indexOf(file.type) === -1) {
          showWarning("warning.image_type");
          inputRefSecond.current.value = "";
          return;
        }

        if (file.size > MAX_IMAGE_BANNER_SIZE) {
          showWarning("warning.image_size");
          inputRefSecond.current.value = "";
          return;
        }
        const reader = new FileReader();
        reader.onload = () => {
          setDisplayImageSecond(reader.result);
        };
        reader.readAsDataURL(file);

        const formData = new FormData();
        formData.append("file", file);
        const image = await UploadImage.uploadImage(formData);
        const jsonImage = JSON.parse(JSON.stringify(image));
        setImageUrlIntroSecond(jsonImage.url);
      }
    } catch (ex) {
      showError("error.post_error");
    } finally {
      if (inputRefSecond.current) {
        inputRefSecond.current.value = "";
      }
    }
  };

  const onChangeInput = async (value: string, fieldVn: string, fieldKr: string) => {
    setIsLoading(true)
    var changeValue = value;
    if (value.toString().length > 2000) {
      onAddToast({ type: "error", message: t("text.form.policy.max_character", { max: 2000 }) })
      changeValue = changeValue.substring(0, 1999)
    }
    if (isVn) {
      setFieldValue(fieldVn, changeValue)
      setFieldValue(fieldKr, await TranslateService.translateToKorea({ content: changeValue }))
    } else {
      setFieldValue(fieldKr, changeValue)
      setFieldValue(fieldVn, await TranslateService.translateToVietNam({ content: changeValue }))
    }
    setIsLoading(false);
  }

  const {
    handleSubmit,
    handleChange,
    errors,
    values,
    touched,
    setValues,
    setFieldValue
  } = useFormik({
    initialValues: {
      intro_titleKr: intros[0].titleKr || "",
      intro_contentKr: intros[0].content1_Kr || "",
      intro_second_titleKr: intros[1].titleKr || "",
      intro_second_content_1Kr: intros[1].content1_Kr || "",
      intro_second_content_2Kr: intros[1].content2_Kr || "",

      intro_titleVn: intros[0].titleVn || "",
      intro_contentVn: intros[0].content1_Vn || "",
      intro_second_titleVn: intros[1].titleVn || "",
      intro_second_content_1Vn: intros[1].content1_Vn || "",
      intro_second_content_2Vn: intros[1].content2_Vn || "",
    },
    validationSchema: yup.object({
      intro_titleVn: yup.string().max(255, t("text.form.policy.max_character", { max: 255 }))
        .required(t("text.form.introduce.required_title")),
      intro_contentVn: yup.string().max(2000, t("text.form.policy.max_character", { max: 2000 }))
        .required(t("text.form.introduce.required_content")),
      intro_second_titleVn: yup.string().max(255, t("text.form.policy.max_character", { max: 255 }))
        .required(t("text.form.introduce.required_title")),
      intro_second_content_1Vn: yup.string().max(2000, t("text.form.policy.max_character", { max: 2000 }))
        .required(t("text.form.introduce.required_content")),
      intro_second_content_2Vn: yup.string().max(2000, t("text.form.policy.max_character", { max: 2000 }))
        .required(t("text.form.introduce.required_content")),
      intro_titleKr: yup.string().max(255, t("text.form.policy.max_character", { max: 255 }))
        .required(t("text.form.introduce.required_title")),
      intro_contentKr: yup.string().max(2000, t("text.form.policy.max_character", { max: 2000 }))
        .required(t("text.form.introduce.required_content")),
      intro_second_titleKr: yup.string().max(255, t("text.form.policy.max_character", { max: 255 }))
        .required(t("text.form.introduce.required_title")),
      intro_second_content_1Kr: yup.string().max(2000, t("text.form.policy.max_character", { max: 2000 }))
        .required(t("text.form.introduce.required_content")),
      intro_second_content_2Kr: yup.string().max(2000, t("text.form.policy.max_character", { max: 2000 }))
        .required(t("text.form.introduce.required_content")),
    }),
    onSubmit: async (values) => {
      try {
        setIsLoading(true)
        const dataSubmitIntro = {
          name: intros[0].name,
          titleVn: values.intro_titleVn,
          titleKr: values.intro_titleKr,
          content1Vn: values.intro_contentVn,
          content1Kr: values.intro_contentKr,
          content2Vn: "",
          content2Kr: "",
          images: [{ url: imageUrlIntro }]
        };

        const dataSubmitIntroSecond = {
          name: intros[1].name,
          titleVn: values.intro_second_titleVn,
          titleKr: values.intro_second_titleKr,
          content1Vn: values.intro_second_content_1Vn,
          content1Kr: values.intro_second_content_1Kr,
          content2Vn: values.intro_second_content_2Vn,
          content2Kr: values.intro_second_content_2Kr,
          images: [{ url: imageUrlIntroSecond }]
        };

        if (intro.id == 0) {
          const res: any = await IntroServices.post(dataSubmitIntro)
          const introInserted = {
            ...dataSubmitIntro,
            id: res.id
          }
          setIntro(introInserted)
        } else {
          await IntroServices.put(intro.id, dataSubmitIntro)
        }
        if (introSecond.id == 0) {
          const res: any = await IntroServices.post(dataSubmitIntroSecond)
          const introInserted = {
            ...dataSubmitIntroSecond,
            id: res.id
          }
          setIntroSecond(introInserted)
        } else {
          await IntroServices.put(introSecond.id, dataSubmitIntroSecond)
        }
        showSuccess("success.updated");
      } catch (ex) {
        console.log(ex)
        showError("error.update_error");
      } finally {
        setIsLoading(false)
      }
    }
  })

  return (
    <form onSubmit={handleSubmit} className="mb-20">
      <p className="text-xl font-bold">{t("text.title.about_us")} <span className="text-[#F45538]">*</span></p>
      <div className="mt-5">
        <p className="font-bold mb-2">{t("text.image.upload_image_title")} <span className="text-[#F45538]">*</span></p>
        <div className="flex justify-start">
          <div
            className={`w-fit h-[190px] border-[2px] border-dashed rounded flex flex-row justify-center items-center px-5 cursor-pointer
          ` }
            onClick={onClick}
          >
            <AddImage />
            <p className="text-normal text-gray-300 pl-3">
              {t("text.image.title")} <span className="font-bold text-[#0073E5]">{t("text.image.title_here")}</span>
            </p>
            <input
              ref={inputRef}
              type="file"
              className="hidden"
              multiple={false}
              accept={"image/jpeg, image/png"}
              onChange={handleFileChange}
            />

          </div>
          {
            displayImage &&
            <div className="w-fit h-[190px] border-[2px] border-dashed rounded flex flex-row justify-center items-center ml-10">
              <img src={displayImage} className="w-fit h-[190px] rounded p-2" />
            </div>
          }
        </div>
        <div className="flex flex-col gap-5 mt-5">
          <label className="text-lg font-bold ">
            {t("text.section.title")} <span className="text-[#F45538]">*</span>
          </label>
          <div>
            <input
              name="intro_title"
              className="w-full p-4 border-2 bg-inherit"
              onChange={(e) => onChangeInput(e.target.value, "intro_titleVn", "intro_titleKr")}
              value={isVn ? values.intro_titleVn : values.intro_titleKr}
              maxLength={40}
            />
            {isVn ? (errors.intro_titleVn && touched.intro_titleVn && (
              <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                {errors.intro_titleVn}
              </small>
            )) : (errors.intro_titleKr && touched.intro_titleKr && (
              <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                {errors.intro_titleKr}
              </small>
            ))}
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <label className="text-lg font-bold  mt-2">
            {t("text.section.content")} <span className="text-[#F45538]">*</span>
          </label>
          <div>
            <textarea
              className="w-full p-4 border-2 bg-inherit h-[130px]"
              name="intro_content"
              onChange={(e) => onChangeInput(e.target.value, "intro_contentVn", "intro_contentKr")}
              value={isVn ? values.intro_contentVn : values.intro_contentKr}
              maxLength={1000}
            />
            {isVn ? (errors.intro_contentVn && touched.intro_contentVn && (
              <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                {errors.intro_contentVn}
              </small>
            )) : (
              errors.intro_contentKr && touched.intro_contentKr && (
                <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                  {errors.intro_contentKr}
                </small>
              )
            )}
          </div>
        </div>
      </div>

      <p className="text-xl font-bold mt-10">PlusMart <span className="text-[#F45538]">*</span></p>
      <div className="mt-5">
        <p className="font-bold mb-2">{t("text.image.upload_image_title")} <span className="text-[#F45538]">*</span></p>
        <div className="flex justify-start">
          <div
            className={`w-fit h-[190px] border-[2px] border-dashed rounded flex flex-row justify-center items-center px-5 cursor-pointer
          ` }
            onClick={onClickSecond}
          >
            <AddImage />
            <p className="text-normal text-gray-300 pl-3">
              {t("text.image.title")} <span className="font-bold text-[#0073E5]">{t("text.image.title_here")}</span>
            </p>
            <input
              ref={inputRefSecond}
              type="file"
              className="hidden"
              multiple={false}
              accept={"image/jpeg, image/png"}
              onChange={handleFileChangeSecond}
            />

          </div>
          {
            displayImageSecond &&
            <div className="w-fit h-[190px] border-[2px] border-dashed rounded flex flex-row justify-center items-center ml-10">
              <img src={displayImageSecond} className="w-fit h-[190px] rounded p-2" />
            </div>
          }
        </div>
        <div className="flex flex-col gap-5 mt-5">
          <label className="text-lg font-bold ">
            {t("text.section.title")} <span className="text-[#F45538]">*</span>
          </label>
          <div>
            <input
              name="intro_second_title"
              className="w-full p-4 border-2 bg-inherit"
              onChange={(e) => onChangeInput(e.target.value, "intro_second_titleVn", "intro_second_titleKr")}
              value={isVn ? values.intro_second_titleVn : values.intro_second_titleKr}
              maxLength={40}
            />
            {isVn ? (errors.intro_second_titleVn && touched.intro_second_titleVn && (
              <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                {errors.intro_second_titleVn}
              </small>
            )) : (
              errors.intro_second_titleKr && touched.intro_second_titleKr && (
                <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                  {errors.intro_second_titleKr}
                </small>
              )
            )}
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <label className="text-lg font-bold  mt-2">
            {t("text.section.content")} 1<span className="text-[#F45538]">*</span>
          </label>
          <div>
            <textarea
              className="w-full p-4 border-2 bg-inherit h-[130px]"
              name="intro_second_content_1"
              onChange={(e) => onChangeInput(e.target.value, "intro_second_content_1Vn", "intro_second_content_1Kr")}
              value={isVn ? values.intro_second_content_1Vn : values.intro_second_content_1Kr}
              maxLength={1000}
            />
            {isVn ? (errors.intro_second_content_1Vn && touched.intro_second_content_1Vn && (
              <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                {errors.intro_second_content_1Vn}
              </small>
            )) : (
              errors.intro_second_content_1Kr && touched.intro_second_content_1Kr && (
                <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                  {errors.intro_second_content_1Kr}
                </small>
              )
            )}
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <label className="text-lg font-bold  mt-2">
            {t("text.section.content")} 2<span className="text-[#F45538]">*</span>
          </label>
          <div>
            <textarea
              className="w-full p-4 border-2 bg-inherit h-[130px]"
              name="intro_second_content_2"
              onChange={(e) => onChangeInput(e.target.value, "intro_second_content_2Vn", "intro_second_content_2Kr")}
              value={isVn ? values.intro_second_content_2Vn : values.intro_second_content_2Kr}
              maxLength={1000}
            />
            {isVn ? (
              errors.intro_second_content_2Vn && touched.intro_second_content_2Vn && (
                <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                  {errors.intro_second_content_2Vn}
                </small>
              )
            ) : (
              errors.intro_second_content_2Kr && touched.intro_second_content_2Kr && (
                <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                  {errors.intro_second_content_2Kr}
                </small>
              )
            )}
          </div>
        </div>
      </div>
      <div onClick={handleCancel} className="flex item-center justify-end mt-[20px] mb-[155px] gap-10px">
        <BtnLoading
          type="submit"
          className="w-[8%] min-w-[40px] py-4 border border-header flex justify-center items-center  text-white  font-bold bg-header"
          isLoading={isLoading}
          onClick={handleSubmit}
        >
          {t("text.button.save")}
        </BtnLoading>
      </div>

    </form>
  )
}