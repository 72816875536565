import React, { useContext, useEffect, useState } from 'react'
import Navigator from './Navigator'
import { GroupInput } from '@components/input/GroupInput'
import { ToastContex } from '@contexts/ToastContex';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useI18n from '@hooks/useI18n';
import DynamicButton from '@components/Buttons/DynamicButton';
import AccountServices, { AccountTypeNew } from '@services/AccountServices';
import AuthService from '@services/AuthServices';

const intUser: AccountTypeNew = {
    id: "",
    fullName: "",
    phoneNumber: "",
    email: "",
    address: "",
    login: ""
}

const Account = () => {
    const { onAddToast } =
        useContext(ToastContex);
    const [user, setUser] = useState<AccountTypeNew>(intUser);

    const { t } = useI18n();

    useEffect(() => {
        AccountServices.getAccountInfo().then(data => {
            setUser(data)
        })
    }, [])

    const { handleChange, handleSubmit, setFieldValue, handleBlur, values, errors, touched } = useFormik({
        enableReinitialize: true,
        initialValues: {
            fullName: user.fullName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            address: user.address,
            login: user.login,
            userId: ""
        },
        validationSchema: Yup.object({

            fullName: Yup.string()
                .required(t("error.form.required"))
                .max(40, "error.form.max"),
            address: Yup.string()
                .required("error.form.required"),
            phoneNumber: Yup.string()
                .required("error.form.required"),
            email: Yup.string()
                .required("error.form.required")
                .max(256, "error.form.max"),
        }),
        onSubmit: async (value, { resetForm }) => {
            value.userId = user.id
            try {
                const response = await AccountServices.put(user.id, value);
                setUser(response)
                onAddToast({ type: "success", message: t("success.account") });
                setTimeout(() => {
                    AuthService.doLogin();
                }, 3000)

            } catch (ex) {
                onAddToast({ type: "error", message: t("error.post_error") });
            }


        }
    });


    return (
        <div className='sticky flex items-center justify-start h-[100vh]'>
            <Navigator />
            <div className='bg-[#FAFAFA] h-[100vh] w-full flex justify-center items-start '>
                <div className='h-[70%] pt-[30px] w-[80%] max-sm:w-[90%]' >
                    <form className='grid grid-cols-2 max-md:grid-cols-1' onSubmit={handleSubmit}>
                        <div className='pt-4 flex flex-col gap-y-5 mr-[20px]'>
                            <GroupInput
                                title="product.get-free-consulation.form.full-name"
                                valueInput={values.fullName}
                                nameInput="fullName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="product.get-free-consulation.form.placeholder-full-name"
                                titleError={touched.fullName && errors.fullName ? errors.fullName : ""}
                            />
                            <GroupInput
                                title="product.get-free-consulation.form.address"
                                valueInput={values.address}
                                nameInput="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="product.get-free-consulation.form.placeholder-address"
                                titleError={touched.address && errors.address ? errors.address : ""}
                            />
                            <GroupInput
                                title="product.get-free-consulation.form.phone-number"
                                valueInput={values.phoneNumber}
                                nameInput="phoneNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="product.get-free-consulation.form.placeholder-phone-number"
                                titleError={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ""}
                            />
                        </div>
                        <div className='pt-4 flex flex-col gap-y-5'>
                            <GroupInput
                                title="product.get-free-consulation.form.email"
                                valueInput={values.email}
                                nameInput="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="product.get-free-consulation.form.placeholder-email"
                                titleError={touched.email && errors.email ? errors.email : ""}
                            />
                            <GroupInput
                                title="product.get-free-consulation.form.login"
                                valueInput={user.login}
                                nameInput="login"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                isWrite={false}
                                placeholder="product.get-free-consulation.form.placeholder-login"
                            />
                        </div>

                        <div className="pt-[30px] flex justify-end col-span-2 max-md:col-span-1">
                            <div>
                                <DynamicButton
                                    type="submit"
                                    text={t('global.update')}
                                    gradien={true}
                                    className="w-full !rounded-[20px] !px-[20px] !py-2 !min-w-[83px] text-[16px] leading-normal font-bold font-NunitoSans"
                                />
                            </div>
                        </div>
                    </form>
                </div>

            </div>
        </div>
    )
}

export default Account