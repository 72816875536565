import PrevIconElm from "@assets/iconElements/PrevIconElm";
import RightArrow from "@assets/iconElements/RightArrow";
import CricleButton from "@components/Buttons/CricleButton";
import CartISlideCategory from "@components/Card/CartISlideCategory";
import CartISlideImage from "@components/Card/CartISlideImage";
import TitleSession from "@components/common/TitleSession";
import useI18n from "@hooks/useI18n";
import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import ProductServices, { ProductItem } from "@services/ProductServices";
import { SubCategory } from "@services/Types/category";
import categoryServices from "@services/categoryService";
import clsx from "clsx";
import { PaginationCompt } from "commons/Paginnation";
import SwiperComponent from "commons/SwiperComponent";
import { CategoryType } from "commons/contannt";
import { memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Swiper, { Autoplay, Grid, Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";

interface Props {
    breackPoin?: any;
}


const breack = {
    390: {
        slidesPerView: 2.5,
        spaceBetween: 8,
    },
    768: {
        slidesPerView: 3.5,
        spaceBetween: 26,
    },
    1280: {
        slidesPerView: 3.5,
        spaceBetween: 1,
    },
};

export interface ICategorySlide {
    id: number,
    name: string,
    image: string
}

const SubCateogry = memo(
    ({ breackPoin }: Props) => {
        const [listproducts, setListProducts] = useState<ProductItem[]>([]);
        const [totalElements, setTotalElements] = useState<number>(0);
        const [totalPage, setTotalPage] = useState<number>(0);
        const [currentPage, setCurrentPage] = useState<number>(0);
        const [loading, setLoading] = useState<boolean>(false);

        const [listSubCategory, setListSubCategory] = useState<any>();

        const {
            navigationPrevRef,
            navigationNextRef,
            handleNext,
            handlePre,
            NavigationElement,
            currentIndex,
            onActiveIndexChange,
            activeThumb,
        } = useSwiperNavigationRef();


        useEffect(() => {
            categoryServices.getAllCategorySub().then((data) => {
                setListSubCategory(data);

            })
        }, [])

        return (
            <div className="product_left bg-gray-100">
                <TitleSession
                    isBox={true}
                    text="homepage.category.text"
                    className={`uppercase !text-[32px] max-sm:!py-[23px] max-sm:!text-[18px] max-sm:text-[#00C3AB] w-full font-bold pt-[68px] pb-[35px] max-md:pt-[20px] max-md:pb-[20px] `}
                />
                <div className={clsx("relative ")}>

                    <SwiperComponent
                        onActiveIndexChange={onActiveIndexChange}
                        navigationNextRef={navigationNextRef}
                        navigationPrevRef={navigationPrevRef}
                        breakpoints={breackPoin ? breackPoin : breack}
                        slidesPerView={1}
                        modules={[Pagination, Navigation]}
                        className={clsx("w-full h-full  max-md:h-[150px]", {})}
                        spaceBetween={1}
                    >
                        {listSubCategory && listSubCategory.map((item: SubCategory, index: number) => {
                            return (
                                <SwiperSlide key={index} className="!w-[379px] !mr-[22px] max-sm:!mr-[6px] max-sm:!w-[107px]">
                                    <CartISlideCategory key={index} item={item} stt={index} />
                                </SwiperSlide>
                            );
                        })}
                    </SwiperComponent>
                </div>
            </div>

        );
    })

export default SubCateogry;
