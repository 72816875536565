import React, { useContext, useEffect, useState } from 'react'
import Navigator from './Navigator'
import { GroupInput } from '@components/input/GroupInput'
import { ToastContex } from '@contexts/ToastContex';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import useI18n from '@hooks/useI18n';
import DynamicButton from '@components/Buttons/DynamicButton';
import AccountServices from '@services/AccountServices';
import AuthService from '@services/AuthServices';


const Password = () => {
    const { onAddToast } =
        useContext(ToastContex);

    const { t } = useI18n();


    const { handleChange, handleSubmit, handleBlur, values, errors, touched } = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            newPasswordRepeat: "",
        },
        validationSchema: Yup.object({
            oldPassword: Yup.string()
                .required("error.form.required"),
            newPassword: Yup.string()
                .required("error.form.required"),
            newPasswordRepeat: Yup.string()
                .required("error.form.required")
                .oneOf([Yup.ref('newPassword')], 'error.form.password-fail')
                // .matches(phoneRegExp, "error.form.password")
                .max(256, "error.form.max")
        }),
        onSubmit: async (value, { resetForm }) => {
            AccountServices.postPass(value).then(data => {
                onAddToast({ type: "success", message: t("success.account") });
                setTimeout(() => {
                    AuthService.doLogin();
                }, 3000)
            }).catch(e => {
                let number = e.response.data.charAt(0)
                switch (number) {
                    case "1":
                        onAddToast({ type: "error", message: t("error.password-1") });
                        break;
                    case "2":
                        onAddToast({ type: "error", message: t("error.password-2") });
                        break;
                    case "3":
                        onAddToast({ type: "error", message: t("error.password-3") });
                        break;
                    case "4":
                        onAddToast({ type: "error", message: t("error.password-4") });
                        break;
                    default:
                        onAddToast({ type: "error", message: t("error.post_error") });
                        break;

                }
            });

            // } catch (ex) {
            //     onAddToast({ type: "error", message: t("error.post_error") });
            // }
        }
    });

    return (
        <div className='flex items-center justify-start h-[100vh]'>
            <Navigator />
            <div className='bg-[#FAFAFA] h-[100vh] w-full flex justify-center items-start '>
                <div className=' pt-[10%] w-[48%] max-sm:w-[90%]' >
                    <form className='form-pass grid grid-cols-1 !bg-[#FFF] px-[40px] pt-[20px] h-[450px] ' onSubmit={handleSubmit}>
                        <p className='text-center text-[24px] font-semibold pt-[20px]'>{t('global.change-password')}</p>
                        <GroupInput
                            title={t('global.change-password-present')}
                            valueInput={values.oldPassword}
                            nameInput="oldPassword"
                            onChange={handleChange}
                            type='password'
                            onBlur={handleBlur}
                            placeholder="product.get-free-consulation.form.placeholder-password"
                        />
                        <GroupInput
                            title={t('global.change-password-new')}
                            valueInput={values.newPassword}
                            nameInput="newPassword"
                            onChange={handleChange}
                            type='password'
                            onBlur={handleBlur}
                            placeholder="product.get-free-consulation.form.placeholder-password"
                        />
                        <div>
                            <GroupInput
                                title={t('global.change-password-old')}
                                valueInput={values.newPasswordRepeat}
                                nameInput="newPasswordRepeat"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                type='password'
                                placeholder="product.get-free-consulation.form.placeholder-password-confirm"
                                titleError={touched.newPasswordRepeat && errors.newPasswordRepeat ? errors.newPasswordRepeat : ""}
                            />
                        </div>


                        <div>
                            <DynamicButton
                                type="submit"
                                text={t('global.update')}
                                gradien={true}
                                className="w-full !rounded-[20px] !px-[20px] !py-2 !min-w-[83px] text-[16px] leading-normal font-bold font-NunitoSans"
                            />
                        </div>



                    </form>
                </div>

            </div>
        </div>
    )
}

export default Password