import RightArrow from "@assets/iconElements/RightArrow";
import CricleButton from "@components/Buttons/CricleButton";
import useI18n from "@hooks/useI18n";
import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import { memo } from "react";
import { Link } from "react-router-dom";

interface Props {
    item?: any,
    stt: number
}

const CardItemSlideCategory = memo(({ item, stt }: Props) => {
    // const {
    //     handleNext
    // } = useSwiperNavigationRef();

    const { isVn } = useI18n();

    return (
        <div className="p-3 lg:pt-6 xl:mt-1 mt-10 bg-white flex flex-col sm:gap-4 gap-2 lg:rounded-lg rounded-sm shadow  xl:h-[460px] max-md:h-[250px]">
            <div className="h-[50%]">
                <div className="flex items-center justify-between xl:my-1 ">
                    <span className="font-bold xl:text-header2 text-xl text-gray-400">
                        {stt + 1 < 10 && "0"}
                        {stt + 1}
                    </span>
                    <Link to={`/category/${item?.id}`}>
                        <CricleButton
                            className="xl:w-12 xl:h-12 w-spc30 h-spc30 z-max"
                            // onClick={() => handleNext()}
                            icon={<RightArrow />}
                        /></Link>
                </div>
                <Link to={`/category/${item?.id}`}>
                    <p className="xl:text-lg text-lg font-bold line-clamp-2 hover:text-main max-sm:text-[16px] ">
                        <p className="max-sm:leading-[20px]"> {isVn ? item.categoryNameVn : item.categoryNameKr}</p>
                    </p>
                </Link>
            </div>
            <div className="h-[50%]">
                <div className="flex justify-center items-center max-h-[50%]">
                    <img
                        src={item.imagesCategory[0].url}
                        className="rounded-[12px] w-fit h-full object-contain shadow-shd020"
                    />
                </div>
            </div>
        </div>
    );
});

export default CardItemSlideCategory;