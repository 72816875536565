import CartIcon from "@assets/iconElements/CartIcon";
import { TooltipSale } from "@assets/icons";
import CricleButton from "@components/Buttons/CricleButton";
import DynamicButton from "@components/Buttons/DynamicButton";
import { ToastContex } from "@contexts/ToastContex";
import useI18n from "@hooks/useI18n";
import { colors } from "@utility/colors";
import { formatMoney, nl2br } from "@utility/helper";
import clsx from "clsx";
import React, { memo, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { number } from "yup";

interface Props {
  item?: any;
  description?: string;
  showBtn?: boolean;
}

const Discount = memo(({ percent }: { percent: number }) => {
  return (
    <div className={clsx(`absolute 2xl:top-0 -top-1 left-0 text-white font-bold max-sm:top-[-7px]`,
      {
        "hidden": percent === 0,
      }

    )}>
      <div className="relative flex max-sm:w-[60%] ">
        <TooltipSale className="" />
        <span className="lg:text-[24px] pl-[7px] pt-[1px] text-white font-bold absolute top-1/2 left-[16%] -translate-x-[16%] -translate-y-1/2">
          -{percent}%
        </span>
      </div>
    </div>
  );
});


const caculatorSold = (item: any[]) => {
  let result = 0;
  item.forEach((value) => {
    result += value.soldQuantity
  })
  return result;
}

const caculatorStock = (item: any[]) => {
  let result = 0;
  if (item.length > 0) {
    item.forEach(element => {
      result += element.stockQuantity
    })
  }
  return result;
}

function CardItem({ description, showBtn = true, item }: Props) {
  const { isVn, t } = useI18n();
  const [show, setShow] = useState<Boolean>();
  const { listToast, onAddToast } = useContext(ToastContex);
  const navigate = useNavigate();

  const addCard = () => {
    console.log(caculatorStock(item.productDetails))
    if (caculatorStock(item.productDetails) <= 0) {
      onAddToast({ type: "warn", message: t("warning.product-stock") });
    } else {
      navigate("/product/" + item.id)
    }
  }

  return (

    <div className="relative  max-sm:pb-4 pt-0  sc1510:rounded-lg rounded-[20px] max-sm:rounded-[8px]  overflow-hidden bg-[#FFF] max-sm:h-[325px] h-[425px]  "

    >
      <div className="2xl:px-[25px] px-[14px] xl:pt-spc50 sm:pt-6 max-sm:h-[200px] h-[285px]"
      // onMouseEnter={() => setShow(true)}
      // onMouseLeave={() => setShow(false)}
      >
        <Discount percent={item.promo} />
        <div className="h-[285px] max-sm:h-[200px] sc480:h-[200px] 2xl:px-3"

        >

          <Link to={`/product/${item?.id}`}>
            <img
              src={item.images[0] && item.images[0].url}
              alt={isVn ? item.productNameVn : item.productNameKr}
              className="w-full h-full object-contain"
            />
          </Link>
        </div>
        <div className={clsx(` bg-[#2E4D44] absolute top-0 z-5 left-0 h-[285px] w-[100%] max-sm:h-[200px] 
         opacity-[0.9] overflow-y-auto flex justify-between flex-col items-center  pb-[20px]`,

          {
            "hidden": !show,
            "!inline": caculatorStock(item.productDetails) === 0
          })}
        >
          {caculatorStock(item.productDetails) > 0 ?
            <>
              <div className="w-full card-bg">
                <p className="px-[10%] max-sm:!text-[12px] py-[10%] !text-white !bg-transparent "
                  dangerouslySetInnerHTML={{ __html: item ? (isVn ? item.salientFeaturesVn : item.salientFeaturesKr) : "" }}></p>
              </div>
              <Link to={`/product/${item?.id}`} className="!w-[90%]">
                <DynamicButton
                  text="button.see_more"
                  normal={false}
                  className="!rounded-[30px] bg-white !w-full  font-bold !min-h-[41px]
                
                max-md:text-[14px] !text-[18px] text-sm !text-main max-sm:!text-[12px]  max-sm:!min-h-[25px]"
                /></Link>
            </>
            :
            <p className="text-white text-center pt-[50%]  max-md:text-[14px] text-[24px] font-bold tracking-wide">{t("global.product_stock")}</p>
          }




        </div>
      </div>

      <hr className="w-full h-[1px] bg-gray-100 mb-[12px] mt-0" />
      {showBtn ? (
        <div className="2xl:px-[25px] px-[14px] max-sm:px-[10px]">
          <Link to={`/product/${item?.id}`} className="">
            <p className="sc480:text-[16px] text-black-bl01 font-normal line-clamp-2 mb-2 hover:text-main h-[40px] max-sm:h-[37px] max-sm:text-[12px]">
              {isVn ? item.productNameVn : item.productNameKr}
            </p></Link>
          <div className="flex items-center justify-between sm:mb-2 max-sm:items-start">
            <span className="sm:text-[18px] text-sm font-bold text-main  max-sm:text-[14px]">
              {

                item.promo && item.promo > 0 ? formatMoney(item.price - (item.price * item.promo) / 100) : formatMoney(item.price)
              }
            </span>
            <span className=" text-[14px]  text-text-disable max-sm:text-[10px]">
              {t("global.sell")} {
                item.productDetails.length > 0 ? caculatorSold(item.productDetails) : 0
              }
            </span>
          </div>
          <div className="flex items-center justify-between max-sm:mt-[5px]">
            <DynamicButton
              text="button.byNow"
              normal={false}
              className="!rounded bg-aqua-aq02 !min-w-[83px] sc480:text-[14px] text-sm !text-white"
              onClick={() => addCard()}
            />
            {/* <CricleButton
              className="w-[36px] h-[36px] max-sm:w-[30px] max-sm:h-[30px] max-sm:pr-[2px] max-sm:pb-[1px]"
              onClick={() => addCard()}
              icon={<CartIcon color={colors.aqua02} />}
            /> */}
          </div>
        </div>
      ) : (
        <div className="relative">
          <p className="sc480:text-base text-xs text-text-main font-normal line-clamp-2 mb-1">
            {t("global.currency")}
          </p>
          <div className="flex items-end gap-6">
            <span className="sm:text-lg text-sm font-bold text-main">
              {isVn ? formatMoney(30000) :
                formatMoney(30000)
              } {t("global.currency")}
            </span>
          </div>
          <CricleButton
            className="w-9 h-9 absolute bottom-1 right-0" onClick={() => addCard()}
            icon={<CartIcon color={colors.aqua02} />}
          />
        </div>
      )}
    </div>
  );
}

export default CardItem;
