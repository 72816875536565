import { colors } from "@utility/colors";
import { IconProps } from "@utility/types";
import React from "react";

const CloseIconElm = ({
  width = 24,
  height = 24,
  color = colors.white,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        d="M18.2983 5.70998C18.2058 5.61728 18.0959 5.54373 17.9749 5.49355C17.8539 5.44337 17.7242 5.41754 17.5933 5.41754C17.4623 5.41754 17.3326 5.44337 17.2116 5.49355C17.0907 5.54373 16.9808 5.61728 16.8883 5.70998L11.9983 10.59L7.10827 5.69998C7.01569 5.6074 6.90578 5.53396 6.78481 5.48385C6.66385 5.43375 6.5342 5.40796 6.40327 5.40796C6.27234 5.40796 6.14269 5.43375 6.02173 5.48385C5.90076 5.53396 5.79085 5.6074 5.69827 5.69998C5.60569 5.79256 5.53225 5.90247 5.48214 6.02344C5.43204 6.1444 5.40625 6.27405 5.40625 6.40498C5.40625 6.53591 5.43204 6.66556 5.48214 6.78652C5.53225 6.90749 5.60569 7.0174 5.69827 7.10998L10.5883 12L5.69827 16.89C5.60569 16.9826 5.53225 17.0925 5.48214 17.2134C5.43204 17.3344 5.40625 17.464 5.40625 17.595C5.40625 17.7259 5.43204 17.8556 5.48214 17.9765C5.53225 18.0975 5.60569 18.2074 5.69827 18.3C5.79085 18.3926 5.90076 18.466 6.02173 18.5161C6.14269 18.5662 6.27234 18.592 6.40327 18.592C6.5342 18.592 6.66385 18.5662 6.78481 18.5161C6.90578 18.466 7.01569 18.3926 7.10827 18.3L11.9983 13.41L16.8883 18.3C16.9809 18.3926 17.0908 18.466 17.2117 18.5161C17.3327 18.5662 17.4623 18.592 17.5933 18.592C17.7242 18.592 17.8539 18.5662 17.9748 18.5161C18.0958 18.466 18.2057 18.3926 18.2983 18.3C18.3909 18.2074 18.4643 18.0975 18.5144 17.9765C18.5645 17.8556 18.5903 17.7259 18.5903 17.595C18.5903 17.464 18.5645 17.3344 18.5144 17.2134C18.4643 17.0925 18.3909 16.9826 18.2983 16.89L13.4083 12L18.2983 7.10998C18.6783 6.72998 18.6783 6.08998 18.2983 5.70998Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIconElm;
