


import useI18n from "@hooks/useI18n";
import React, { memo, useCallback, useContext, useEffect, useRef, useState, ReactNode } from "react";


type Props = {
    handleChange: (e: any) => void,
    choose: string
}

const SortProduct = ({ handleChange, choose }: Props) => {
    const [selectedOption, setSelectedOption] = useState('');
    const { t } = useI18n();
    useEffect(() => {
        console.log("choose", choose)
    })
    return (
        <div className="flex flex-wrap ">
            <p className="max-md:text-[14px] text-[16px] pr-[16px] max-sm:font-bold"> {t("global.sort.index")}:</p>
            <div className="flex max-md:text-[14px] text-[16px] flex-wrap">
                <div className="flex justify-center items-center">
                    <input type="radio" value="id,desc" checked={choose === "id,desc"} onChange={handleChange} />
                    <p className="pr-[16px] pt-[2px] pl-[4px]">{t("global.sort.id")}</p>
                </div>
                <div className="flex justify-center items-center">
                    <input type="radio" value="totalSoldQuantity,desc" checked={choose === "totalSoldQuantity,desc"} onChange={handleChange} />
                    <p className="pr-[16px]  pt-[2px]  pl-[4px]">{t("global.sort.stock")}</p>
                </div >
                <div className="flex justify-center items-center">
                    <input type="radio" value="price,asc" checked={choose === "price,asc"} onChange={handleChange} />
                    <p className="pr-[16px]  pl-[4px] pt-[2px] "> {t("global.sort.price_asc")}</p>
                </div>
                <div className="flex justify-center items-center">
                    <input type="radio" value="price,desc" checked={choose === "price,desc"} onChange={handleChange} /><p className="pr-[16px] pt-[2px] pl-[4px]">{t("global.sort.price_desc")}</p>

                </div>
                <div className="flex justify-center items-center">
                    <input type="radio" value="promo,desc" checked={choose === "promo,desc"} onChange={handleChange} /><p className="pr-[16px]  pt-[2px] pl-[4px]"> {t("global.sort.promo")}</p>

                </div>
            </div >
        </div>
    );
}
export default SortProduct;