import Pagination from "@components/Pagination";
import { ToastContex } from "@contexts/ToastContex";
import { useAppDispatch, useAppSelector } from "@hooks/reduxHook";
import ProductServices from "@services/ProductServices";
import InputTextElement from "commons/components/InputComponent/InputTextElement";
import debounce from "lodash/debounce";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  setCurrenPage,
  setFilter,
  setLisProduct,
} from "redux/reducer/productSlice";
import {
  ThunkProduclist,
} from "redux/thunk/productAction";
import ProducSizeItem from "../ProducSizeItem";
import LinearButton from "@components/Buttons/LinearButton";
import PlusLinerIcon from "@assets/iconElements/PlusLinerIcon";
import ManyfingIcon from "@assets/iconElements/ManyfingIcon";
import { useShowConfirm } from "@components/Modal/DiglogComfirm";
import { useShowMessage } from "@components/Modal/DialogMessage";
import useI18n from "@hooks/useI18n";
import { setCurrentPage } from "redux/reducer/orderSlice";
import { result } from "lodash";

interface ButtonFilterProps {
  name: string;
  isActive: boolean;
  className?: string;
  handleSubmit: () => void;
}



export const ButtonFilter = (props: ButtonFilterProps) => {
  const { name, isActive, className = "", handleSubmit } = props;
  return (
    <button
      className={
        "text-small font-semibold " +
        (isActive ? "text-main border-b-[3px] border-main mt-[1px]" : "") +
        " " +
        className
      }
      onClick={handleSubmit}
    >
      {name}
    </button>
  );
};

function ManageProduct() {
  const navigator = useNavigate();
  const [searchParams] = useSearchParams();
  const dispatch = useAppDispatch();
  const { currentPage, totalElement, listProducts, filter } =
    useAppSelector((state) => state.products);
  const [itemDeletes, setItemDeletes] = useState<string[]>([]);
  const [keySearch, setKeySearch] = useState<string>("");
  const [filterSelected, setFilterSelected] = useState<string>("ALL")
  const { showConfirm } = useShowConfirm();
  const { showError, showSuccess, showWarning } = useShowMessage();
  const { t } = useI18n();
  const [pagnation, setPagnation] = useState<boolean>(true);


  const nameList = [
    t("text.manage_product.title_attributes"),
    t("text.manage_product.title_price"),
    t("text.manage_product.title_address_warehouse"),
    t("text.manage_product.title_number_stock"),
    t("text.manage_product.title_sold_quantity"),
  ];

  const sizing = 5;

  const handleFilter = async (filters?: boolean) => {
    navigator("");
    setKeySearch("");
    if (filters) {
      setFilterSelected("IN_STOCK")
    } else {
      setFilterSelected("OUT_STOCK")
    }
    if (filters != null && filters != undefined) {
      try {
        const result = await ProductServices.getListProductFilter({
          page: currentPage - 1,
          sort: ["createAt", "desc"],
          remaining: filters,
          size: sizing,
        });
        if (result) {
          if (result.totalElements >= sizing) {
            dispatch(setLisProduct(result));
            setPagnation(true)
          } else {
            dispatch(ThunkProduclist({
              page: 0,
              sort: ["createAt", "desc"],
              remaining: filters,
              size: sizing,
            }))
            setPagnation(false)
          }
        }
      } catch (error) {
        showError("text.manage_product.error_load_products")
      }
    } else {
      try {
        const result = await ProductServices.getListProductFilter({
          page: currentPage - 1,
          sort: ["createAt", "desc"],
          size: sizing,
        });
        if (result) {
          dispatch(setLisProduct(result));
        }
      } catch (error) {
        showError("text.manage_product.error_load_products")
      }
      setFilterSelected("ALL")
      setPagnation(true)
    }
  };

  const handleShowConfirmDelete = (id: string) => {
    showConfirm("confirm.delete_products", () => handleDeleteOneProduct(id));
  };

  const getProductByKeySearch = async (params: { [key: string]: any }) => {
    try {
      const result = await ProductServices.searchProducts({
        page: params.page,
        size: sizing,
        keyword: params.keySearch,
      });
      if (result) {
        dispatch(setLisProduct(result));
        return;
      }
    } catch (error) {
      showError("text.manage_product.error_load_products")
    }
  };
  const debounceSearch = useCallback(
    debounce((value: any) => getProductByKeySearch(value), 1500),
    []
  );

  const handleSearch = (inputSearch: { name: string; value: string }) => {
    setKeySearch(inputSearch.value);
    navigator("");
    dispatch(setFilter(true));
    dispatch(setCurrenPage(1));
  };

  const handleChoseIdDelete = (idProduct: string) => {
    const checkId = itemDeletes.includes(idProduct);
    let newItemDelete = [...itemDeletes];
    if (checkId) {
      newItemDelete = newItemDelete.filter((item) => {
        return item != idProduct;
      });
    } else {
      newItemDelete.push(idProduct);
    }
    setItemDeletes(newItemDelete);
  };

  const handleDeleteOneProduct = async (id: string) => {
    try {
      const ids: string[] = [];
      ids.push(id)
      if (ids.length > 0) {
        await ProductServices.deleteProducts(ids).then((res) => {
          if (res) {
            dispatch(
              ThunkProduclist({
                page: currentPage - 1,
                size: sizing,
                remaining: filter,
                sort: ["createAt", "desc"],
              })
            );
            showSuccess("success.deleted")
            navigator("");
            setItemDeletes([]);
          }
        });
      }
    } catch (error) {
      showError("error.deleted_error")
    }
  };

  const handleDeleteProducts = async () => {
    try {
      if (itemDeletes.length > 0) {
        await ProductServices.deleteProducts(itemDeletes).then((res) => {
          if (res) {
            dispatch(
              ThunkProduclist({
                page: currentPage - 1,
                size: sizing,
                remaining: filter,
                sort: ["createAt", "desc"],
              })
            );
            showSuccess("success.deleted")
            navigator("");
            setItemDeletes([]);
          }
        });
      }
    } catch (error) {
      showError("error.deleted_error")
    }
  };

  useEffect(() => {
    if (searchParams.get("page")) {
      const numberPage = Number(searchParams.get("page"));
      setCurrenPage(numberPage);
    }
    setItemDeletes([]);
  }, [searchParams]);

  useEffect(() => {
    if (keySearch != "") {
      if (currentPage == 1) {
        debounceSearch({
          page: currentPage - 1,
          size: sizing,
          keySearch: keySearch,
        });
      } else {
        getProductByKeySearch({
          page: currentPage - 1,
          size: sizing,
          keySearch: keySearch,
        });
      }
      return;
    } else {
      debounceSearch.cancel();
      if (filterSelected != 'ALL') {
        dispatch(
          ThunkProduclist({
            page: currentPage - 1,
            size: sizing,
            remaining: filter,
            sort: ["createAt", "desc"],
          })
        );
      } else {
        if (searchParams.get("page")) {
          const numberPage = Number(searchParams.get("page"));
          dispatch(
            ThunkProduclist({
              page: numberPage - 1,
              size: sizing,
              sort: ["createAt", "desc"],
            })
          );
          setCurrenPage(numberPage);
        } else {
          dispatch(
            ThunkProduclist({
              page: currentPage - 1,
              size: sizing,
              sort: ["createAt", "desc"],
            })
          );
        }
        
      }
    }

    return () => { };
  }, [currentPage, filter, keySearch]);

  return (
    <div className=" pt-9 pb-10px">
      <h2 className="titlePage mb-5">{t("text.manage_product.title")}</h2>
      <div className="mb-5 flex items-start flex-col gap-5">
        <div className="flex items-center gap-4">
          <ButtonFilter
            name={t("text.manage_product.all_product")}
            isActive={filterSelected === "ALL"}
            handleSubmit={() => handleFilter()}
          />
          <ButtonFilter
            name={t("text.manage_product.in_stock")}
            isActive={filterSelected === "IN_STOCK"}
            handleSubmit={() => handleFilter(true)}
          />
          <ButtonFilter
            name={t("text.manage_product.out_stock")}
            isActive={filterSelected === "OUT_STOCK"}
            handleSubmit={() => handleFilter(false)}
          />
        </div>
      </div>
      <div className="flex items-center justify-between mb-5">
        <p className="text-normal font-medium">{t("text.manage_product.total_product", { total: totalElement })}</p>
        <div className="flex items-center justify-end gap-6">
          <div className="bg-btn p-[1px] h-10 rounded w-[300px]">
            <InputTextElement
              icon={<ManyfingIcon />}
              name="keySearch"
              value={keySearch}
              placehoderText={t("text.manage_product.search_placeholder")}
              classWidth="w-full w-[300px] "
              onChangeInput={handleSearch}
              className="py-[9px] px-5 rounded"
            />
          </div>
          <LinearButton
            text="button.add_product"
            iconLeft={<PlusLinerIcon />}
            className="w-[170px] h-10 !rounded !text-sm overflow-hidden"
            className_child="rounded !text-sm "
            onClick={() => navigator("add")}
          />
        </div>
      </div>
      <div>
        <div className="flex items-center border-2 rounded-t-md mb-4 bg-gradient-to-b from-orange-500  to-red-500 text-white font-bold py-3">
          <div className="flex items-center pl-4 pr-7 h-full justify-center w-2/5 text-sm">
            {t("text.manage_product.title_product")}
          </div>
          <div className="w-3/5">
            <div className="flex ">
              {nameList.map((item, index) => {
                return (
                  <div key={index} className="flex border-l-2 w-1/5 justify-center text-sm">
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {listProducts && listProducts.length > 0 &&
          listProducts.map((product: any, indexP: number) => {
            return (
              <ProducSizeItem
                key={product.id}
                item={product}
                isCheck={itemDeletes.includes(product.id!)}
                handleDelete={handleChoseIdDelete}
                handleDeleteOneProduct={handleShowConfirmDelete}
              />
            );
          })}
      </div>
      {itemDeletes.length > 0 && (
        <button
          className="rounded-md py-2 px-3 mt-4 border border-main flex items-center text-main text-small font-normal bg-icon"
          onClick={handleDeleteProducts}
        >
          {t("text.manage_product.delete")}
        </button>
      )}


      {
        pagnation && <div className="p-8 flex justify-end">
          <Pagination
            currenPage={currentPage}
            setCurrentPage={(page: number) => dispatch(setCurrenPage(page))}
            total={Math.ceil(totalElement / 5)}
          />
        </div>
      }

    </div>
  );
}

export default ManageProduct;
