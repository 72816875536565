import useI18n from "@hooks/useI18n";
import { formatMoney } from "@utility/helper";
import InputTextElement from "commons/components/InputComponent/InputTextElement";
import { OrderType } from "commons/contannt";
import { dayFormat } from "commons/dayfomat";

interface Props {
  itemOrder: OrderType;
}
function OrderModal(props: Props) {
  const { itemOrder } = props;
  const { isVn, t } = useI18n();
  return (
    <div className="w-[60%] max-h-[80%] overflow-hidden mt-5">
      <div className="w-full h-full overflow-y-scroll hiddenScroll bg-white pt-9 pl-16 pr-100 pb-12">
        <h3 className="flex text-2xl tracking-[.03] mb-12 text-center items-center justify-center gap-2">
          <span className="font-bold text-main">{t("text.manage_order_product.title_detail_order")}:</span>
          <span className="font-normal text-black">{itemOrder.orderCode}</span>
          <span className="text-[#A1A0A3] text-xs">{dayFormat(itemOrder.orderTime)}</span>
        </h3>
        <div>
          <div className="flex flex-col gap-3 mb-4">
            <h1 className="font-bold text-lg">{t("text.manage_order_product.label_information_customer")}</h1>
            <div className="flex">
              <h3 className="font-bold">{t("text.manage_order_product.title_order_name")}: </h3>
              <span className="ml-2">{itemOrder.fullName}</span>
            </div>
            <div className="flex">
              <h3 className="font-bold">{t("text.manage_order_product.label_email")}: </h3>
              <span className="ml-2">{itemOrder.email}</span>
            </div>
            <div className="flex">
              <h3 className="font-bold">{t("text.manage_order_product.title_phone")}: </h3>
              <span className="ml-2">{itemOrder.phoneNumber}</span>
            </div>
            <div className="flex">
              <h3 className="font-bold">{t("text.manage_order_product.label_address")}: </h3>
              <span className="ml-2">{itemOrder.address}</span>
            </div>
            <div className="flex">
              <h3 className="font-bold">{t("text.manage_order_product.label_note")}: </h3>
              <span className="ml-2">{itemOrder.note}</span>
            </div>
          </div>
          <div>
            <h1 className="font-bold text-lg mb-4">{t("text.manage_order_product.label_order_product")}</h1>
            <div className="flex w-full font-bold">
              <div className="w-[50px]">{t("text.manage_order_product.title_stt")}</div>
              <div className="w-2/6">{t("text.manage_order_product.title_product")}</div>
              <div className="w-[150px] pl-3">{t("text.manage_order_product.title_quantity")}</div>
              <div className="w-2/6">{t("text.manage_order_product.title_unit_price")} (VNĐ)</div>
              <div className="w-1/6">{t("text.manage_order_product.title_total_price")}</div>
              <div className="w-1/6">{t("text.manage_order_product.title_warehouse")}</div>
            </div>
            <div className="border-b mt-6"></div>
            <div>
              {itemOrder && itemOrder.productOrders?.length > 0 &&
                itemOrder.productOrders.map((item: any, index: number) => {
                  return (
                    <div className="flex w-full mt-4 font-bold  text-[13px]">
                      <div className="w-[50px]">{index + 1}</div>
                      <div className="flex w-2/6 gap-2">
                        <div>
                          <img className="min-w-[50px] min-h-[50px] w-[50px] h-[50px]" src={item.image} alt="" />
                        </div>
                        <div>
                          {isVn ? item.productNameVn : item.productNameKr}
                        </div>
                      </div>
                      <div className="w-[150px] pl-3">x {item.quantityOder}</div>
                      <div className="w-2/6 text-[#00C3AB]"><span>{formatMoney(item.actualPrice)}</span> / <span className="text-[#A1A0A3] line-through font-normal ">{formatMoney(item.price)}</span></div>
                      <div className="w-1/6 text-[#00C3AB]">{formatMoney(item.actualPrice * item.quantityOder)}</div>
                      <div className="w-1/6">{item.addressWarehouse}</div>
                    </div>
                  )
                })
              }
            </div>
          </div>
          <div className="w-[50%] flex flex-col gap-4 mt-6">
            <h1 className="font-bold text-lg">Thanh toán</h1>
            <div className="flex justify-between font-bold">
              <h3 >{t("text.manage_order_product.label_total_price_order")} </h3>
              <span className="text-main">{formatMoney(itemOrder.totalPrice)}</span>
            </div>
            <div className="flex justify-between font-bold">
              <h3>{t("text.manage_order_product.label_total_price_promo")}</h3>
              <span className="text-main">{formatMoney(itemOrder.voucherPrice)} </span>
            </div>
            <div className="flex justify-between font-bold">
              <h3>{t("text.manage_order_product.label_promo_code")}: <span className="text-main">{itemOrder.voucherCode}</span></h3>
              <span className="text-main">{formatMoney(itemOrder.voucherPrice)}</span>
            </div>
            <div className="flex justify-between font-bold">
              <h3>{t("text.manage_order_product.label_total_price_payment")} </h3>
              <span className="text-[#00C3AB]">{formatMoney(itemOrder.totalPriceActual)}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OrderModal;
