import CloseIcon from "@assets/iconElements/CloseIcon";
import PrevIconElm from "@assets/iconElements/PrevIconElm";
import { KoreanFlagImage, VietnamsFlagImage } from "@assets/images";
import CricleButton from "@components/Buttons/CricleButton";
import DynamicButton from "@components/Buttons/DynamicButton";
import BtnLoading from "@components/btn-loading/BtnLoading";
import { GroupInput } from "@components/input/GroupInput";
import { InputComponent } from "@components/input/InputComponent";
import { TextError } from "@components/input/TextError";
import { TextareaComponent } from "@components/input/TextareaComponent";
import TitleInput from "@components/input/TitleInput";
import { ToastContex } from "@contexts/ToastContex";
import { ModalContext } from "@contexts/contextModal";
import useFocusOut from "@hooks/useFocusOut";
import useI18n from "@hooks/useI18n";
import ContactServices from "@services/ContactServices";
import { colors } from "@utility/colors";
import { useFormik } from "formik";
import { useContext, ReactNode, useState, useEffect } from 'react';
import { useTranslation } from "react-i18next";
import { Navigate, useNavigate } from "react-router-dom";
import * as Yup from 'yup';

export type COUNTRY = 'Vietnamese' | 'Korea'
const MOBILE_TELEPHONE_PREFIXES: { country: COUNTRY; telephone_prefixes: string, image: string }[] = [
    { country: "Vietnamese", telephone_prefixes: "+84", image: VietnamsFlagImage },
    { country: "Korea", telephone_prefixes: "+82", image: KoreanFlagImage },
]

const GetFreeConsulationModal = () => {
    const { setShowModal } = useContext(ModalContext);
    const [preview, setPreview] = useState(MOBILE_TELEPHONE_PREFIXES[0]);
    const naviagte = useNavigate();
    const { t } = useI18n();
    const { onAddToast } = useContext(ToastContex);

    const seeProduct = () => {
        setShowModal(false)
        naviagte("/")
    }
    const submitContact = () => {
        console.log(values.fullName)
    }
    const phoneRegExp = /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/

    const { handleChange, handleSubmit, setFieldValue, handleBlur, values, errors, touched } = useFormik({
        initialValues: {
            fullName: "",
            phoneNumber: "",
            email: "",
            address: "",
            content: ""

        },

        validationSchema: Yup.object({

            fullName: Yup.string()
                .required(t("error.form.required"))
                .max(40, "error.form.max"),

            content: Yup.string()
                .required("error.form.required")
                .max(1000, "error.form.max"),
            address: Yup.string()
                .max(100, "error.form.max")
                .required("error.form.required"),

            email: Yup.string()
                .required("error.form.required")
                .max(256, "error.form.max"),
            phoneNumber: Yup.string()
                .required("error.form.required")
                .matches(phoneRegExp, "error.form.phone")
            ,

        }),
        onSubmit: async (value, { resetForm }) => {
            try {
                const response = await ContactServices.post(value);
                console.log(response.status)
                if (response) {
                    onAddToast({ type: "success", message: t("success.contact") });
                    setShowModal(false)
                    resetForm()
                } else {
                    onAddToast({ type: "error", message: t("error.post_error") });
                    setShowModal(false)
                }

            } catch (ex) {
                console.log(ex)
            }


        }
    });

    const {
        clickShow,
        setClickShow,
        ref
    } = useFocusOut();

    const SelectPhoneNumber = () => {
        return (
            <>
                <div ref={ref}>
                    <div className="flex flex-row gap-x-1 justify-center items-center">
                        <img src={preview.image} alt={preview.country} />
                        <p>{preview.telephone_prefixes}</p>
                        <div className="relative">
                            <button
                                onClick={() => setClickShow((prev) => !prev)}
                                className="-rotate-90 flex justify-center items-center">
                                <div className="">
                                    <PrevIconElm width={15} height={12} color={colors.darkOrange} />
                                </div>
                            </button>
                            {
                                clickShow && (
                                    <div className="absolute z-10 w-[104px] top-4 left-0 ">
                                        <div className="bg-neutra-neutral98 rounded">
                                            {
                                                MOBILE_TELEPHONE_PREFIXES.map((it, idx) => {
                                                    return (
                                                        it.country !== preview.country && (
                                                            <>
                                                                <button
                                                                    key={idx}
                                                                    onClick={() => {
                                                                        setPreview(it)
                                                                        setClickShow((prev => !prev))
                                                                    }}
                                                                    className="px-[9px] py-[6px] flex flex-row gap-x-2 justify-center items-center" >
                                                                    <img src={it.image} alt={it.country} className="" />
                                                                    <p className="w-11 flex items-start text-normal text-neutra-neutral2 font-normal font-NunitoSans">{it.telephone_prefixes}</p>
                                                                </button >
                                                            </>
                                                        )
                                                    )
                                                })
                                            }
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </div>

                </div >
            </>
        )
    }
    return (
        <>
            <div className="w-[680px] pt-[22px] pb-10 bg-white rounded-[20px] max-sm:w-[90%] overflow-y-auto h-[90vh] ">
                <div className="px-11 max-sm:px-[15px]">
                    <div className="flex flex-row justify-between">
                        <p className="text-main font-bold text-title font-NunitoSans max-sm:text-[24px] xl:uppercase ">
                            {t('product.get-free-consulation.title')}
                        </p>
                        <CricleButton
                            onClick={() => setShowModal((prev: boolean) => !prev)}
                            className="max-sm:w-[25px] max-sm:h-[25px] mt-[7px] "
                            icon={<CloseIcon width={24} height={24} color={colors.aqua02} />}
                        />
                    </div>
                    <form className="pt-[14px]" onSubmit={handleSubmit}>
                        <p className="text-normal font-NunitoSans font-normal text-neutra-neutra20 max-sm:text-[14px] ">{t('product.get-free-consulation.content')}</p>
                        <div className="pt-4 flex flex-col gap-y-5 max-sm:pt-2 max-sm:gap-y-3">
                            <div >
                                <GroupInput
                                    title="product.get-free-consulation.form.full-name"
                                    valueInput={values.fullName}
                                    nameInput="fullName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="product.get-free-consulation.form.placeholder-full-name"
                                    titleError={touched.fullName && errors.fullName ? errors.fullName : ""}
                                />
                            </div>
                            <div>
                                <TitleInput isRequired={true} name={"product.get-free-consulation.form.phone-number"} />
                                <InputComponent
                                    value={values.phoneNumber}
                                    name="phoneNumber"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="product.get-free-consulation.form.placeholder-phone-number"
                                    renderLeft={() => {
                                        return (
                                            <span className="mr-[16px] ml-[6px]">
                                                <SelectPhoneNumber />
                                            </span>
                                        );
                                    }}
                                />
                                <TextError message={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ""} option={{ max: 40 }} />
                            </div>
                            <div>
                                <GroupInput
                                    title="product.get-free-consulation.form.email"
                                    valueInput={values.email}
                                    nameInput="email"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="product.get-free-consulation.form.placeholder-email"
                                    titleError={touched.email && errors.email ? errors.email : ""}
                                />
                            </div>
                            <div>
                                <GroupInput
                                    title="product.get-free-consulation.form.address"
                                    valueInput={values.address}
                                    nameInput="address"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="product.get-free-consulation.form.placeholder-address"
                                    titleError={touched.address && errors.address ? errors.address : ""}
                                />
                            </div>
                            <div>
                                <TitleInput isRequired={true} name={"product.get-free-consulation.form.content"} />
                                <TextareaComponent
                                    name="content"
                                    value={values.content}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="product.get-free-consulation.form.placeholder-content"
                                />
                                <TextError message={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ""} option={{ max: 40 }} />
                            </div>
                        </div>
                        <div className="pt-9 flex flex-col gap-y-3 max-sm:pt-4">
                            <DynamicButton
                                type="submit"
                                text={t('product.button.ask-for-advice')}
                                gradien={true}

                                className="w-full !rounded-[20px] !px-[6px] !py-2 !min-w-[83px] text-[16px] leading-normal font-bold font-NunitoSans"
                            />
                            <DynamicButton
                                onClick={seeProduct}
                                text={t('product.button.view-more-products')}
                                textGradient={true}
                                className="flex-1 !rounded-[20px] !px-[6px] !py-2 !min-w-[83px] text-[16px] leading-normal font-bold font-NunitoSans"
                            />
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
};

export default GetFreeConsulationModal;