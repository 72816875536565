import EditIcon from "@assets/iconElements/EditIcon";
import { ICDeleteTrashLight } from "@assets/iconElements/ICDeleteTrashLight";
import useI18n from "@hooks/useI18n";
import { CategoryType } from "@services/Types/category";
import InputChecboxElement from "commons/components/InputComponent/InputChecboxElement";
import React, { memo } from "react";

interface Props {
  category: CategoryType[];
  onDelete: (id: number) => void;
  handleEdit: (item: CategoryType) => void;
  handlSelectAll: () => void;
  handleSelect: (id: number) => void;
  listIdDelete: number[];
}
const CategoryList = memo(({ category, onDelete, handleEdit, handlSelectAll, handleSelect, listIdDelete }: Props) => {
  const { t, isVn } = useI18n();
  return (
    <div>
      <table className="w-full text-black-bl01">
        <thead>
          <tr>
            <th className="text-left flex items-center pb-[34px]">
              {/* <input
                type="checkbox"
                onClick={handlSelectAll}
              // onChange={choseAllList}
              // checked={listIdDelete.length == listItem.length}
              /> */}
              <InputChecboxElement
                isCheck={
                  category.length > 0 &&
                  listIdDelete.length === category.length
                }
                name={"checkAll"}
                onHandleChange={handlSelectAll}
                sizeBox="w-4 h-4 rounded-[4px]"
              />
            </th>
            <th className="text-left pb-[34px]">
              {t("text.table.category_name")}
            </th>
            <th className="text-left pb-[34px]">
              {t("text.table.detail_category")}
            </th>
            <th className="text-right pb-[34px]">{t("text.table.func")}</th>
          </tr>
        </thead>
        <tbody>
          {category.map((item, index) => {
            return (
              <tr key={item.id} className="py-4 border-t border-t-borderGray">
                <td className="py-4">
                  {/* <input
                    // checked={listIdDelete.includes(item.id)}
                    // onChange={() => handleInputDelete(item.id)}
                    // style={{ border: "1px solid red" }}
                    onClick={() => handleSelect(item && item.id ? item.id : -1)}
                    type="checkbox"
                  /> */}
                  <InputChecboxElement
                        isCheck={listIdDelete.includes(item && item.id ? item.id : -1)}
                        name={item.id + "Input"}
                        onHandleChange={() => handleSelect(item && item.id ? item.id : -1)}
                        sizeBox="w-4 h-4 rounded-[4px]"
                      />
                </td>
                <td className="py-4">
                  {isVn ? item.categoryNameVn : item.categoryNameKr}
                </td>
                <td className="py-4">
                  {item.subCategoryList.map((sub, index) => {
                    return `${isVn ? sub.subCategoryNameVn : sub.subCategoryNameKr
                      } ${index + 1 != item.subCategoryList.length ? " - " : ""}`;
                  })}
                </td>
                <td className="text-right py-4">
                  <div className="flex items-center justify-end gap-2 cursor-pointer">
                    <div onClick={() => handleEdit(item)}>
                      <EditIcon />
                    </div>
                    <div onClick={() => onDelete(item.id!)}>
                      <ICDeleteTrashLight />
                    </div>
                  </div>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
});

export default CategoryList;
