import { Input } from "@components/common/Input";
import InputPayment from "@components/common/InputPayment";
import SelectInputPayment from "@components/common/SelectInputPayment";
import useI18n from "@hooks/useI18n";
import { some } from "@utility/helper";
import React, { useState, useRef, useEffect } from "react";

// import province_data from "./province_date.json";
import province_data from "../../utility/province_date.json";
import { VietNamFlag } from '@assets/icons'
import PrevIconElm from "@assets/iconElements/PrevIconElm";
import { KoreanFlagImage, VietnamsFlagImage } from "@assets/images";
import useFocusOut from "@hooks/useFocusOut";
import { colors } from "@utility/colors";
import { InputComponent } from "@components/input/InputComponent";

export type WardType = {
  Level: string;
  Id?: string;
  Name?: string;
};

export type DistrictType = {
  Wards: WardType[];
  Id: string;
  Name: string;
};

export type ProvinceType = {
  Districts: DistrictType[];
  Id: string;
  Name: string;
};

type Props = {
  formik: any;
};

export type COUNTRY = 'Vietnamese' | 'Korea'
const MOBILE_TELEPHONE_PREFIXES: { country: COUNTRY; telephone_prefixes: string, image: string }[] = [
  { country: "Vietnamese", telephone_prefixes: "+84", image: VietnamsFlagImage },
]

function PaymentForm({ formik }: Props) {
  const { t } = useI18n();
  const [preview, setPreview] = useState(MOBILE_TELEPHONE_PREFIXES[0]);


  const {
    ref
  } = useFocusOut();

  const SelectPhoneNumber = () => {
    return (
      <>
        <div ref={ref}>
          <div className="flex flex-row gap-x-1 justify-center items-center">
            <img src={preview.image} alt={preview.country} />
            <p>{preview.telephone_prefixes}</p>
          </div>
        </div >
      </>
    )
  }

  return (
    <div>
      <div className="mb-2">
        <label className="text-text font-bold">
          {t("payment.info_delivery.form.name")}{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="h-10 mb-4">
        <InputPayment
          className="border-[1px] border-solid border-neutra-neutra80"
          name="fullName"
          value={formik.values.fullName}
          setValue={formik.handleChange}
          label={t("payment.info_delivery.form.name_place_holder")}
          required
          onBlur={formik.handleBlur}
          autoFocus={true}
        />
      </div>
      <div className="mb-2">
        <label className="text-text font-bold">
          {t("payment.info_delivery.form.phone")}{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="h-10 mb-4">
        <InputComponent
          value={formik.values.phoneNumber}
          name="phoneNumber"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="payment.info_delivery.form.phone"
          className="text-normal1 pl-3"
          renderLeft={() => {
            return (
              <span className="mr-[16px]">
                <SelectPhoneNumber />
              </span>
            );
          }}
        />
      </div>
      <div className="mb-2">
        <label className="text-text font-bold">
          {t("payment.info_delivery.form.email")}{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="h-10 mb-4">
        <InputPayment
          className="border-[1px] border-solid border-neutra-neutra80 "
          name="email"
          value={formik.values.email}
          setValue={formik.handleChange}
          label={t("payment.info_delivery.form.email")}
          required
          onBlur={formik.handleBlur}
        />
      </div>
      <div className="mb-2">
        <label className="text-text font-bold">
          {t("payment.info_delivery.form.address")}{" "}
          <span className="text-red-500">*</span>
        </label>
      </div>
      <div className="h-10 mb-4">
        <InputPayment
          className="border-[1px] border-solid border-neutra-neutra80 "
          name="address"
          value={formik.values.address}
          setValue={formik.handleChange}
          label={t("payment.info_delivery.form.address_place_holder")}
          required
          onBlur={formik.handleBlur}
        />
      </div>
      <div className="mb-2">
        <label className="text-text font-bold">
          {t("payment.info_delivery.form.note")}
        </label>
      </div>
      <div className="h-10 mb-4">
        <InputPayment
          className="border-[1px] border-solid border-neutra-neutra80 "
          name="note"
          value={formik.values.note}
          setValue={formik.handleChange}
          label={t("payment.info_delivery.form.note_placeholder")}
          onBlur={formik.handleBlur}
        />
      </div>
    </div>
  );
}

export default PaymentForm;
