import useI18n from "@hooks/useI18n";
import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import { memo } from "react";

interface Props {
    item?: any,
    stt: number
}

const ProductFeaturedSlide = memo(({ item, stt }: Props) => {
    const {
    } = useSwiperNavigationRef();
    const { isVn } = useI18n();
    return (
        <div className="w-full rounded-lg pt-3 pl-1">
            <div className="text-main xl:text-2xl text-[18px] font-bold ">{isVn ? item.productNameVn : item.productNameKr}</div>
            <div className="pt-4 pl-4" dangerouslySetInnerHTML={{ __html: isVn ? item.salientFeaturedVn : item.salientFeaturedKr }} />
        </div>
    );
});

export default ProductFeaturedSlide;