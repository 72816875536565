import { ArrowDownIcon, ClearIcon, PasswordIcon, UserBlackIcon, UserIcon } from "@assets/icons";
import { ModalContext } from "@contexts/contextModal";
import useI18n from "@hooks/useI18n";
import { some } from "@utility/helper";
import React, { useContext } from "react";
import CategoryMobileItem from "./CategoryMobileItem";
import { Link, useNavigate } from "react-router-dom";
import AuthService from "@services/AuthServices";
import { CategoryHeader, LanguageBox, NavHeader } from "./Header";
import { ROLES, ROUTES } from "@utility/constants";
import { useCart } from "@contexts/CartContext";
import CartProductHover from "@components/Cart/CartProductHover";
import ShopIcon from "@assets/iconElements/ShopIcon";
import PhoneIcon from "@assets/iconElements/PhoneIcon";
import { phoneNumber } from "@utility/types";
import { colors } from "@utility/colors";
import CartIcon from "@assets/iconElements/CartIcon";
import UserIconElm from "@assets/iconElements/UserIconElm";
import useFocusOut from "@hooks/useFocusOut";
import { CategoryType } from "@services/Types/category";
import Collapse from "./Collapse";

interface Props {
  categoryData: CategoryType[];
  handleShowFormLocation?: () => void;
}

function HeaderMenuMobile({ categoryData, handleShowFormLocation }: Props) {
  const { t } = useI18n();
  const cartContext = useCart();
  const navigate = useNavigate();
  const { closeModal } = useContext(ModalContext);

  const userLogin = () => {
    AuthService.doLogin();
    closeModal()
  };
  const userLogout = () => {
    AuthService.doLogout();
    closeModal()
  };

  return (
    <div className="h-screen w-screen z-max">
      <div className="w-full h-full bg-white ml-auto  flex flex-col items-start  ">
        <div
          className="px-5 hover:cursor-pointer py-6 flex justify-end"
          onClick={closeModal}
        >
          <ClearIcon />
        </div>
        <div className="w-full px-5  overflow-x-hidden pb-8">
          {categoryData.map((item: CategoryType, index) => {
            return <><Collapse
              data={item.subCategoryList}
              nameKr={item.categoryNameKr}
              nameVn={item.categoryNameVn}
              id={item.id}
              close={closeModal}
            /></>
          })}
        </div>

        <div className=" w-full px-5 pb-10 flex flex-col gap-5 py-6 border-t border-t-main ">
          <Link
            className="font-semibold text-[18px] mb-[10px]"
            to={"/about-us"}
            onClick={closeModal}
          >
            {t("global.link.introduce")}
          </Link>
          {AuthService.isLoggedIn() && (
            <>
              {AuthService.hasRole([ROLES.admin, ROLES.system]) ?
                <>
                  <Link to="/admin" className="mb-[10px] flex items-center" onClick={closeModal}>
                    <UserBlackIcon />
                    <span className=" text-normal1 font-semibold px-[7px]" >
                      {t("header.admin")}
                    </span>
                  </Link>
                </>

                :
                <>
                  <Link to="/account" className="mb-[10px] flex items-center" onClick={closeModal}>
                    <UserBlackIcon />
                    <span className=" text-normal1 font-semibold px-[7px]" >
                      {t("global.account")}
                    </span>
                  </Link>
                  <Link to="/change-password" className="mb-[10px] flex items-center " onClick={closeModal}>
                    <PasswordIcon className="ml-[7px]" />
                    <span className=" text-normal1 font-semibold px-[12px]" >
                      {t("global.change-password")}
                    </span>
                  </Link>
                </>


              }



            </>

          )}
          <div className="">
            <div className="flex justify-start items-center font-semibold">
              <LanguageBox />
              <p className=" pl-[5px]">  {t('global.language')}</p>

            </div>
          </div>

          {/* <a
            href={`tel:${phoneNumber.replace(/\s/g, "")}`}
            className="hover:cursor-pointer flex sm:gap-1 gap-4 items-center "
          >
            <PhoneIcon color={colors.gray03} width={30} height={26} />
            <p className="sm:px-2 sm:text-wap-regular1 ">{phoneNumber}</p>
          </a> */}
          {/* {AuthService.hasRole([ROLES.admin, ROLES.system]) ? (
            <Link
              className="flex pl-11 mt-4 items-center text-normal1"
              to={"/admin"}
              onClick={closeModal}
            >
              {t("link.manage")}
            </Link>
          ) : null} */}

          <Link
            to={"/about-us"}
            className="flex gap-4 items-center"
            onClick={closeModal}
          >
            {/* <div className="w-7 ">
              <ShopIcon />
            </div> */}
            {/* <span className=" text-normal1">{t("about-our")}</span> */}
          </Link>
          <div className="flex  items-center ">


            {!AuthService.isLoggedIn() ? (
              <span className=" text-normal1 font-semibold" onClick={userLogin}>
                {t("global.login")}
              </span>
            ) : (
              <span className=" text-normal1 font-semibold" onClick={userLogout}>
                {t("global.logout")}
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderMenuMobile;
