

import {
    Register,
    LogoOrange
} from "@assets/icons"
import DynamicButton from "@components/Buttons/DynamicButton";
import { GroupInput } from "@components/input/GroupInput";
import { InputComponent } from "@components/input/InputComponent";
import TitleInput from "@components/input/TitleInput";
import { ToastContex } from "@contexts/ToastContex";
import useI18n from "@hooks/useI18n"
import AccountServices from "@services/AccountServices";
import { TextError } from "commons/TextError";
import { useFormik } from "formik";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import * as Yup from 'yup';
const RegisterPage = () => {
    const { t } = useI18n();
    const { onAddToast } = useContext(ToastContex);
    const naviagte = useNavigate();
    // const phoneRegExp = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[0-9a-zA-Z]{8,}$/
    const { handleChange, handleSubmit, setFieldValue, handleBlur, values, errors, touched } = useFormik({
        initialValues: {
            fullName: "",
            email: "",
            address: "",
            phoneNumber: "",
            login: "",
            password: "",
            role: "users",
            confirmPassword: ""

        },

        validationSchema: Yup.object({
            role: Yup.string(),
            fullName: Yup.string()
                .required(t("error.form.required"))
                .max(40, "error.form.max"),
            phoneNumber: Yup.string()
                .required(t("payment.info_delivery.form.required_phone"))
                .matches(/^[0-9]{10}$/, t("payment.info_delivery.form.required_format_phone")),
            login: Yup.string()
                .required("error.form.required")
                .max(180, "error.form.max")
                .test('checkDuplUsername', 'error.form.account-fail', function (value) {
                    return new Promise((resolve, reject) => {
                        AccountServices.checklogin(value).then((data) => {
                            // exists
                            resolve(true)
                        }).catch(() => {
                            // note exists
                            resolve(false)
                        })
                    })
                })
            ,
            address: Yup.string()
                .required("error.form.required"),
            email: Yup.string()
                .email("error.form.email")
                .required("error.form.required")
                .max(256, "error.form.max"),
            password: Yup.string()
                .required("error.form.required")
                // .matches(phoneRegExp, "error.form.password")
                .max(256, "error.form.max"),
            confirmPassword: Yup.string()
                .required("error.form.required")
                .oneOf([Yup.ref('password')], 'error.form.password-fail')
                // .matches(phoneRegExp, "error.form.password")
                .max(256, "error.form.max")
        }),

        onSubmit: async (value, { resetForm }) => {
            value.role = "users"
            try {
                const response = await AccountServices.post(value).then(data => {
                    onAddToast({ type: "success", message: t("error.register-success") });
                    resetForm()

                    setTimeout(() => {
                        window.location.replace("/")
                    }, 1000)
                })
                    .catch(e => {
                        onAddToast({ type: "warn", message: t("error.register-cancel") });

                    })

            } catch (ex) {
                console.log(ex)
            }

        }
    });
    return (
        <div className="flex justify-evenly items-center pt-[45px]  max-xl:flex-col h-full ">
            <Register className="mt-[50px] max-xl:hidden" />
            <div className=" w-[40%] flex justify-center items-center flex-col max-xl:pr-[0px] pr-[69px] max-xl:w-[100%]">
                <Link to="/">      <LogoOrange /></Link>

                <form className="pt-[14px]    w-[90%]  max-xl:w-[70%]  max-sm:w-[90%]" onSubmit={handleSubmit}>
                    <p className="text-black text-[32px] max-md:text-[24px] font-bold text-center pt-[40px] pb-[20px] max-xl:pt-[10px]"><p>{t("text.register")}</p></p>
                    <div className="pt-4 flex flex-col justify-center gap-y-5">
                        <div>
                            <GroupInput
                                title="product.get-free-consulation.form.full-name"
                                valueInput={values.fullName}
                                nameInput="fullName"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="product.get-free-consulation.form.placeholder-full-name"
                                titleError={touched.fullName && errors.fullName ? errors.fullName : ""}
                            />
                        </div>

                        <div>
                            <GroupInput
                                title="product.get-free-consulation.form.email"
                                valueInput={values.email}
                                nameInput="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="product.get-free-consulation.form.placeholder-email"
                                titleError={touched.email && errors.email ? errors.email : ""}
                            />
                        </div>
                        <div>
                            <GroupInput
                                title="product.get-free-consulation.form.phone-number"
                                valueInput={values.phoneNumber}
                                nameInput="phoneNumber"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="product.get-free-consulation.form.placeholder-phone-number"
                                titleError={touched.phoneNumber && errors.phoneNumber ? errors.phoneNumber : ""}
                            />
                        </div>
                        <div>
                            <GroupInput
                                title="product.get-free-consulation.form.address"
                                valueInput={values.address}
                                nameInput="address"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="product.get-free-consulation.form.placeholder-address-ship"
                                titleError={touched.address && errors.address ? errors.address : ""}
                            />
                        </div>
                        <div>
                            <GroupInput
                                title="product.get-free-consulation.form.login"
                                valueInput={values.login}
                                nameInput="login"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                placeholder="product.get-free-consulation.form.placeholder-login"

                                titleError={touched.login && errors.login ? errors.login : ""}
                            />
                        </div>

                        <div className="flex justify-between items-center max-xl:flex-col">
                            <div className="w-[50%] max-xl:w-full">
                                <GroupInput
                                    title="product.get-free-consulation.form.password"
                                    valueInput={values.password}
                                    nameInput="password"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="password"
                                    placeholder="product.get-free-consulation.form.placeholder-password"
                                    titleError={touched.password && errors.password ? errors.password : ""}
                                />
                            </div>
                            <div className="w-[50%] pl-[10px] max-xl:w-full max-xl:pl-[0px] max-xl:pt-6">
                                <GroupInput
                                    title="product.get-free-consulation.form.password-confirm"
                                    valueInput={values.confirmPassword}
                                    nameInput="confirmPassword"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    type="password"
                                    placeholder="product.get-free-consulation.form.placeholder-password-confirm"
                                    titleError={touched.confirmPassword && errors.confirmPassword ? errors.confirmPassword : ""}
                                />
                            </div>

                        </div>


                    </div>
                    <div className="pt-9 flex flex-col gap-y-3">
                        <DynamicButton
                            type="submit"
                            text={t('product.button.register')}
                            gradien={true}

                            className="w-full !rounded-[20px] !px-[6px] !py-2 !min-w-[83px] text-[16px] leading-normal font-bold font-NunitoSans"
                        />

                    </div>
                </form>
                <Register className="mt-[50px] max-xl:block hidden w-[90%] h-fit pl-[30px]" />
            </div>
        </div>
    )
}

export default RegisterPage