import { ListVoucherType, VoucherType, paramVoucher } from "commons/contannt";
import queryString from "query-string";
import api from "./api";
import { IVoucher } from "./Types/voucher";
import { IRespone } from "./Types/respone";

const path = '/voucher'
const VoucherServices = {
    getVoucher: async (param: paramVoucher): Promise<ListVoucherType> => {
        const result = await api.get(`${path}?${queryString.stringify(param, { skipNull: true })}`)
        return result.data
    },
    getVoucherById: async (id: string): Promise<any> => {
        // const result = await api.get(`${path}/${id}`)
        // return result.data
        return await api.get(`${path}/${id}`);
    },
    deleteVoucher: async (id: string): Promise<any> => {
        return api.delete(`${path}/${id}`)
    },
    addOrUpdateVoucher: async (data: any, id?: string): Promise<any> => {
        if (id) {
            const updatedItem = await api.put(`${path}/${id}`, data)
            return updatedItem.data
        }
        const itemCreated = await api.post(path, data)
        return itemCreated.data
    },
    getAllVoucher: async (params: paramVoucher): Promise<IRespone> => {
        return await api.get(path, { params });
    },
    getProductNotBeenAddVoucherAddkeyWork: async (keyWord?: any, code?: any): Promise<any> => {
        return api.get(`${path}/search/products?keyWord=${keyWord}&voucherCode=${code}`);
    },
    getProductNotBeenAddVoucher: async (code?: any): Promise<any> => {
        return api.get(`${path}/products?voucherCode=${code}`);
    },
    postVoucher: async (data: any) => {
        return api.post(path, data);
    },
    putVoucher: async (id: any, data: any) => {
        return api.put(`${path}/${id}`, data);
    },
    removeListVoucher: async (data: any) => {
        return api.delete(`${path}?${queryString.stringify({ ids: data }, { skipNull: true })}`)
    },
    getVoucherOrder: async (ids: string[], priceBill: number): Promise<IRespone> => {
        return api.get(`${path}/order?${queryString.stringify({productDetailIds: ids},{ arrayFormat: "comma", skipNull: true })}&priceBill=${priceBill}`)
    }
}

export default VoucherServices