import SessionIcon from "@assets/iconElements/SessionIcon";
import useI18n from "@hooks/useI18n";
import clsx from "clsx";
import React, { memo } from "react";
interface Props {
  isBox?: boolean;
  text: string;
  className?: string;
  check?: string
}
const TitleSession = memo(({ isBox = false, text, className = "", check }: Props) => {
  const { t } = useI18n();
  return isBox ? (
    <h3
      className={clsx(
        " font-bold md:text-title text-lg leading-normal text-text-main text-left ",
        className
      )}
    >
      {t(text || "")}
    </h3>
  ) : (
    <div className="bg-gray-100 mr-4">
      <div
        className={clsx("relative sc480:hidden block pb-[10px] w-full", className)}
      >
        <div className="rounded-full h-spc50 flex items-center border pl-6 border-white border-t-transparent justify-center text-lg font-bold text-white z-2 relative bg-title">
          <p className="pl-3">{t(text || "")}</p>
        </div>
        <div className="h-spc50 w-full rounded-full bg-header absolute top-[5px] left-[2px] z-1"></div>
        <div className="w-[68px] h-[68px] absolute left-spc30 bg-btn flex items-center justify-center z-10 -top-[6px] rounded-md">
          <SessionIcon />
        </div>
      </div>
      <h3
        className={clsx(
          "sc480:block hidden font-bold md:text-title text-lg leading-normal text-text-main text-left ",
          check,
          className
        )}
      >
        {t(text || "")}
      </h3>
    </div>
  );
});

export default TitleSession;
