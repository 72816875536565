import React from 'react'

const UserBlackIcon = ({ hover }: { hover: boolean }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 25 25" fill={hover ? '#FF7D03' : 'black'}>
            <path d="M12.8169 6.23685C13.0927 6.23685 13.3657 6.29117 13.6205 6.39671C13.8753 6.50224 14.1068 6.65693 14.3018 6.85193C14.4968 7.04693 14.6515 7.27843 14.757 7.53322C14.8626 7.788 14.9169 8.06108 14.9169 8.33685C14.9169 8.61263 14.8626 8.8857 14.757 9.14049C14.6515 9.39527 14.4968 9.62677 14.3018 9.82178C14.1068 10.0168 13.8753 10.1715 13.6205 10.277C13.3657 10.3825 13.0927 10.4369 12.8169 10.4369C12.2599 10.4369 11.7258 10.2156 11.332 9.82178C10.9381 9.42795 10.7169 8.89381 10.7169 8.33685C10.7169 7.7799 10.9381 7.24576 11.332 6.85193C11.7258 6.4581 12.2599 6.23685 12.8169 6.23685ZM12.8169 15.2369C15.7869 15.2369 18.9169 16.6969 18.9169 17.3369V18.4369H6.71689V17.3369C6.71689 16.6969 9.84689 15.2369 12.8169 15.2369ZM12.8169 4.33685C10.6069 4.33685 8.81689 6.12685 8.81689 8.33685C8.81689 10.5469 10.6069 12.3369 12.8169 12.3369C15.0269 12.3369 16.8169 10.5469 16.8169 8.33685C16.8169 6.12685 15.0269 4.33685 12.8169 4.33685ZM12.8169 13.3369C10.1469 13.3369 4.81689 14.6769 4.81689 17.3369V20.3369H20.8169V17.3369C20.8169 14.6769 15.4869 13.3369 12.8169 13.3369Z" fill={hover ? '#FF7D03' : 'black'} />
        </svg>

    )
}

export default UserBlackIcon