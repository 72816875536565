import BreakCrumb, { BreadcrumbType } from "@components/Breadcrumb";
import CartProductNew from "@components/Cart/CartProductNew";
import PaymentForm from "@components/Form/PaymentForm";
import PaymentModal from "@components/Modal/PaymentModal";
import ShipmentMethod from "@components/shipment/ShipmentMethod";
import { useCart } from "@contexts/CartContext";
import { ModalContext } from "@contexts/contextModal";
import useI18n from "@hooks/useI18n";
import CartServices, { CartItemRequest, CartItemRequestNew, PaymentOrderRequest, PaymentOrderRequestNew } from "@services/CartServices";
import { PAYMENT_METHOD, ROUTES } from "@utility/constants";
import { formatMoney } from "@utility/helper";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useContext, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContex } from "@contexts/ToastContex";
import BtnLoading from "@components/btn-loading/BtnLoading";
import clsx from "clsx";
import AccountServices, { AccountTypeNew } from "@services/AccountServices";
import AuthService from "@services/AuthServices";

const PaymentNew = () => {

  const { t } = useI18n()
  const location = useLocation()
  const { onAddToast } = useContext(ToastContex);
  const { cartQuantity, totalPriceChoose, cartItems, onChangeItem, setCartItems } = useCart()
  const { setContentModal, setShowModal } = useContext(ModalContext);
  const [checked, setChecked] = useState<boolean>(true);
  const [voucher, setVoucher] = useState<string>("")
  const [moneyVoucher, setmoneyVoucher] = useState<number>(0)
  const [isLoading, setIsLoading] = useState<boolean>(false)


  useEffect(() => {
    if (!AuthService.isLoggedIn()) {
      AuthService.doLogin();
    }
  }, [])

  useEffect(() => {
    let v = window.sessionStorage.getItem("voucher") || "";
    let mv = Number(window.sessionStorage.getItem("money_voucher") || 0);
    setVoucher(location.state?.voucher || v || "")
    setmoneyVoucher(location.state?.moneyVoucher || mv || 0)
  }, [])

  useEffect(() => {
    if (voucher) {
      window.sessionStorage.setItem("voucher", voucher);
    }
  }, [voucher]);

  useEffect(() => {
    const getUserInformation = async () => {
      try {
        const userInfo = await AccountServices.getAccountInfo();
        formik.setFieldValue("fullName", userInfo.fullName)
        formik.setFieldValue("email", userInfo.email)
        formik.setFieldValue("phoneNumber", userInfo.phoneNumber)
        formik.setFieldValue("address", userInfo.address)
      } catch (error) {
        AuthService.doLogin();
      }
    }
    getUserInformation();
  }, [])

  useEffect(() => {
    if (moneyVoucher != 0) {
      window.sessionStorage.setItem("money_voucher", moneyVoucher + "");
    }
  }, [moneyVoucher]);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      phoneNumber: "",
      email: "",
      address: "",
      province: "",
      district: "",
      commune: "",
      month: "",
      year: "",
      card_number: "",
      zip_code: "",
      country_code: "",
      note: ""
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required(t("payment.info_delivery.form.required_username")),
      phoneNumber: Yup.string()
        .required(t("payment.info_delivery.form.required_phone"))
        .matches(/^[0-9]{10}$/, t("payment.info_delivery.form.required_format_phone")),
      email: Yup.string()
        .required(t("payment.info_delivery.form.required_format_email"))
        .matches(
          /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
          t("payment.info_delivery.form.required_format_email")
        ),
      address: Yup.string().required(t("payment.info_delivery.form.required_address")),
    }),
    onSubmit: (values) => {
    },
  });

  const handleRemoveItems = () => {
    cartItems.filter(c => c.choose).forEach(c => {
      c.quantitySelected = 0
      onChangeItem(c.productDetailId, c)
    })
  }

  const handleOrder = async () => {
    let cartItemRequest = cartItems.filter(c => c.choose);

    if (!cartItemRequest || cartItemRequest.length == 0) {
      onAddToast({ type: "warn", message: t("payment.info_delivery.form.required_select_product") });
      return
    }

    let error = formik.errors.fullName || formik.errors.phoneNumber ||
      formik.errors.email || formik.errors.address ||
      formik.errors.province || formik.errors.district || formik.errors.commune
    if (error) {
      onAddToast({ type: "warn", message: error });
      return
    }

    let request: PaymentOrderRequestNew = {
      fullName: formik.values.fullName,
      email: formik.values.email,
      address: formik.values.address,
      note: formik.values.note,
      phoneNumber: formik.values.phoneNumber,
      paymentType: PAYMENT_METHOD.PERSON,
      totalPrice: totalPriceChoose,
      voucherPrice: moneyVoucher,
      voucherCode: voucher,
      productOrders: cartItemRequest.map((it) => {
        const cartItem: CartItemRequestNew = {
          productNameVn: it.productDetailNameVn,
          productNameKr: it.productDetailNameKr,
          productDetailId: it.productDetailId,
          quantityOder: it.quantitySelected,
          price: it.priceDetail,
          actualPrice: it.actualPriceDetail,
          addressWarehouse: it.addressWarehouse,
          image: it.imageDetailUrl
        }
        return cartItem
      })
    }
    setIsLoading(true)
    try {
      const res = await CartServices.paymentOrderApi(request);
      if (res) {
        formik.resetForm();
        handleRemoveItems();
        setCartItems([]);
        setContentModal(<PaymentModal />);
        setShowModal(true);
        setIsLoading(false)
      }
    } catch (error) {
      setIsLoading(false)
    }
  }

  return (
    <div className="container px-4 lg:px-8" >
      <div className="mt-6">
        {/* <BreakCrumb data={breakcrumData} normalClass="" activeClass=" text-main" /> */}
        <div className="mt-6 font-['Nunito_Sans']">
          {t("cart.cart_title")}
        </div>
      </div>
      <div className="rounded-lg flex-1 pb-12 pt-6 flex flex-col lg:flex-row lg:gap-4 xl:gap-10">
        <div className="flex-1 mb-2">
          <div className="bg-white border rounded-lg p-5">
            <div className="overflow-y-auto max-h-[350px] cart-scrollbar">
              {cartItems.filter(cartItem => cartItem.choose).map((it, idx) => (
                <CartProductNew itemCart={it} key={idx} change={false} border={idx != 1} payment={true} size="sm" />
              ))}
            </div>
            <div className="flex justify-between pb-2 mt-4 ">
              <h1 className="text-normal text-text">
                {t("payment.total_price_title")}
              </h1>
              <div className="text-normal1 font-bold text-text">
                {formatMoney(totalPriceChoose)}
              </div>
            </div>
            <div className="flex justify-between pb-2 ">
              <h1 className="text-normal text-text">
                {t("payment.total_discount")}
              </h1>
              <div className="text-normal1 font-bold text-main">
                {formatMoney(moneyVoucher)}
              </div>
            </div>
            <div className="flex justify-between pt-2 border-t-2 ">
              <h1 className="text-normal  text-text font-bold">
                {t("payment.total_price")}
              </h1>
              <div className="text-normal1 text-text font-bold">
                {formatMoney(totalPriceChoose - moneyVoucher > 0 ? totalPriceChoose - moneyVoucher : 0)}
              </div>
            </div>
          </div>
        </div>
        <div className="p-8 w-full lg:w-[70%] rounded-lg h-fit bg-white">
          <div className="">
            <h3 className="text-normal2 font-bold">{t("payment.info_delivery.index")}</h3>
          </div>
          <div className="py-4 xl:py-8">
            <PaymentForm formik={formik} />
          </div>
          <div className="">
            <h3 className="text-normal2 font-bold">{t("payment.info_delivery_method.title")}</h3>
            <div className="my-2 xl:mt-5 flex flex-col gap-3 w-full">
              <ShipmentMethod method="person" checked={checked} setChecked={setChecked} />
            </div>
          </div>
          <div className="mt-4 lg:mt-8">
            <BtnLoading className={clsx("btn text-normal font-medium rounded-lg bg-header w-full h-9 text-center text-white my-3", {
              "opacity-60 cursor-default": cartQuantity <= 0
            })}
              onClick={handleOrder}
              isLoading={isLoading}
            >
              {t("payment.payment")}
            </BtnLoading>
            <Link to={"/"} className="btn text-main font-semibold rounded-lg border-[#00c3ab] border-2 w-full h-9 text-center text-black ">
              {t("cart.payment_info.btn_buy_more")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentNew;
