import SlideProductPaginate from "@components/product/SlideProductPaginate";
import { CategoryType } from "@services/Types/category";
import categoryServices from "@services/categoryService";
import { memo, useEffect, useState } from "react";
import { setLoading } from "redux/reducer/orderSlice";
import SlideCategory, { SwiperCategory } from "./SlideCategory";
import clsx from "clsx";
import TitleSession from "@components/common/TitleSession";
import { PaginationCompt } from "commons/Paginnation";
import ProductServices from "@services/ProductServices";
import { IRespone } from "@services/Types/respone";
import SwiperComponent from "commons/SwiperComponent";
import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import { Autoplay, Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
import CardItemSlideCategory from "./CardItemSlideCategory";


interface Props {
    className?: string;
}

const CategorySpecial = memo(() => {
    const [listCategory, setListCategory] = useState<CategoryType[]>([]);
    const [currentPageProduct, setCurrentPageProduct] = useState<number>(0);
    const [totalPageCategory, setTotalPageCategory] = useState<number>(0);
    const [currentPageCategory, setCurrentPageCategory] = useState<number>(0);
    const [listProduct, setListProduct] = useState<IRespone[]>([]);

    const callApi = async () => {
        try {
            setLoading(true);
            const result = await categoryServices.getAllCategoryPage({
                page: currentPageCategory - 1,
                size: 12,
            });
            setTotalPageCategory(result.totalPages);
            setListCategory(result.content)
            setLoading(false);
        } catch (error) { }
    };

    const callApiFeatured = async () => {
        try {
            const json = localStorage.getItem("province");
            setLoading(true);
            const result = await ProductServices.getProductFeatured({
                page: currentPageProduct - 1,
                categoryId: "",
                address: json === null ? "Thành phố Hà Nội" : JSON.parse(json).name
            });
            setListProduct(result.content);
            setLoading(false);
        } catch (error) { }
    };

    useEffect(() => {
        callApi();
        callApiFeatured();
    }, [currentPageCategory]);

    const breackPoinIntro = {
        350: {
            slidesPerView: 2.4,
            spaceBetween: 8,
        },
        480: {
            slidesPerView: 3.4,
            spaceBetween: 8,
        },
        640: {
            slidesPerView: 3.4,
            spaceBetween: 12,
        },
        992: {
            slidesPerView: 4.4,
            spaceBetween: 8,
        },
        1024: {
            slidesPerView: 4.4,
            spaceBetween: 12,
        },
        1280: {
            slidesPerView: 3.4,
            spaceBetween: 20,
        },
        1536: {
            slidesPerView: 3.4,
            spaceBetween: 20,
        },
    };

    const {
        navigationPrevRef,
        navigationNextRef,
        handleNext,
        handlePre,
        NavigationElement,
        currentIndex,
        onActiveIndexChange,
        activeThumb,
    } = useSwiperNavigationRef();

    return (
        <>
            <div
                className={"relative product_left pt-[22px] xl:block hidden"}>
                <div className="xl:flex xl:justify-between mb-10 pr-4">
                    <TitleSession
                        isBox={false}
                        text="text.section.special_product"
                        className={`uppercase w-full font-bold bg-white text-[#000000]`}
                    />
                    <div className="shadow rounded-lg mr-20 w-fit hidden xl:block">
                        <PaginationCompt
                            currentPage={currentPageCategory == 0 ? currentPageCategory + 1 : currentPageCategory}
                            totalPages={totalPageCategory}
                            setCurrentPage={setCurrentPageCategory}
                        />
                    </div>
                </div>
                <SlideCategory
                    slideItems={listCategory}
                    slideFeatured={listProduct}
                    breackPoin={breackPoinIntro}
                />
            </div>
            <div
                className={clsx("xl:hidden block ml-4")}
            >
                <TitleSession
                    isBox={false}
                    text="text.section.special_product"
                    className={`uppercase w-full  font-bold bg-white text-[#000000] mb-3 `}
                />
                <div className="relative xl:w-2/6 xl:h-[450px] h-[300px] cursor-pointer bg-[#DAF1E7] rounded-tl-lg">
                    <SwiperCategory slideFeatured={listProduct} breackPoin={breackPoinIntro} slideItems={listCategory} />
                </div>
                <div className="relative">
                    <div className="absolute h-2/5 w-full" style={{ background: "linear-gradient(180deg, #FE7D29 0%, #F9849E 100%)", backgroundSize: "50%" }}>
                    </div>
                    <span className="absolute top-[5%] left-[4%] text-[18px] font-bold uppercase text-white">Danh mục sản phẩm</span>
                    <div className="absolute shadow mt-2 rounded-lg right-2 w-fit">
                        <PaginationCompt
                            currentPage={currentPageCategory == 0 ? currentPageCategory + 1 : currentPageCategory}
                            totalPages={totalPageCategory}
                            setCurrentPage={setCurrentPageCategory}
                        />
                    </div>
                    <div className="ml-[4%] pt-5">
                        <SwiperComponent
                            onActiveIndexChange={onActiveIndexChange}
                            navigationNextRef={navigationNextRef}
                            navigationPrevRef={navigationPrevRef}
                            breakpoints={breackPoinIntro}
                            slidesPerView={1}
                            className={clsx("w-full h-full ml-10", {})}
                            spaceBetween={12}
                            autoplay={{
                                delay: 3000,
                                disableOnInteraction: false,
                            }}
                            modules={[Autoplay, Pagination, Navigation]}
                        >
                            {listCategory.map((item: any, index: number) => {
                                return (
                                    <SwiperSlide key={index} className="max-sm:h-[202px] mb-2">
                                        <CardItemSlideCategory key={index} item={item} stt={index} />
                                    </SwiperSlide>
                                );
                            })}
                        </SwiperComponent>
                    </div>
                </div>
                {listCategory.length > 0 && <>{NavigationElement}</>}
            </div>
        </>
    )
})

export default CategorySpecial;