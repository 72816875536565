import { some } from "@utility/helper";
import api from "./api";

const apiAccount = "/keycloak"
const apiUser = "/user"
type AccountType = {
    id: string,
    login: string,
    firstName: string,
    lastName: string,
    email: string,
    imageUrl: string,
    role: string
}

export type AccountTypeNew = {
    id: string,
    fullName: string,
    phoneNumber: string,
    email: string,
    address: string,
    login: string
}

export type AccountResponse = {
    data: AccountType[],
    total: number
}

const AccountServices = {
    getAllAccounts: async (username: string, page: number, size: number): Promise<AccountResponse> => {
        return api.get(apiAccount + `/searchuser?username=${username}&page=${page}&size=${size}`).then(data => data.data)
    },
    addAccountToAdmin: async (id: string): Promise<any> => {
        return api.put(apiAccount + `/addAdmin/${id}`).then(data => data.data)
    },
    removeAccountFromAdmin: async (id: string): Promise<any> => {
        return api.put(apiAccount + `/removeAdmin/${id}`).then(data => data.data)
    },
    getAccountInfo: async (): Promise<AccountTypeNew> => {
        return await api.get(`${apiUser}/account-info`);
    },
    removeAccount: async (ids: string[]): Promise<any> => {
        return api.delete(apiAccount + `/deletelist`, { data: ids }).then(data => data.data)
    },

    checklogin: async (login: string): Promise<any> => {
        return api.get("/user/checklogin?login=" + login).then(data => data.data)
    },
    post: async (data: some) => {
        return api.post("/user/adduser", data)
    },
    put: async (id: string, data: some): Promise<AccountTypeNew> => {
        return api.put(`/user/update/${id}`, data)
    },
    postPass: async (data: some): Promise<AccountTypeNew> => {
        return api.post(`/user/password/change`, data)
    },

    getResetPass: async (email: string): Promise<any> => {
        return api.get(`/user/password/reset?email=` + email)
    },
    // post: async (data: Array<HomeContent>) => {
    //     return api.post('/homepagelist', data);
    // }
}


export default AccountServices;