import { IntroduceImageMoa1, IntroduceImageMoa1Mobile, IntroduceImageMoa2, Logo } from "@assets/icons";
import Banner from "@components/Banner/Banner";
import BreakCrumb, { BreadcrumbType } from "@components/Breadcrumb";
import CategorySpecial from "@components/Category/CategorySpecial";
import LoadingPage from "@components/LoadingPage";
import ProductSpecial from "@components/product/ProductSpecial";
import useI18n from "@hooks/useI18n";
import { Introduce } from "@pages/AdminPage/ManagerIntroduce";
import BannerServices, { BannerImage } from "@services/BannerServices";
import IntroServices from "@services/IntroServices";
import convertToHtml from "@utility/convertoHtml";
import { useEffect, useState } from "react";

export const Intropage = () => {
  const { t, isVn } = useI18n();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [listIntroduces, setListIntroduces] = useState<any[]>([]);
  const [bannerIntroduce, setBannerIntroduce] = useState<BannerImage[]>();

  useEffect(() => {
    try {
      setIsLoading(true);
      fetchData()
        .then((data) => {
          setListIntroduces(data)
          setIsLoading(false);
        })
        .catch((e) => {
          setIsLoading(false);
        });
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    try {
      const result = BannerServices.getBannerByName("ABOUTUS").then((data) => {
        setBannerIntroduce(data.images)
      });
    } catch (error) {
      setIsLoading(false);
    }
  }, []);

  const fetchData = async () => {
    return await IntroServices.get();
  };

  return (
    <div>
      <Banner
        className="h-spc230 lg:min-h-[490px] sm:h-[50vh]"
        images={bannerIntroduce}
      />
      {
        listIntroduces[0] &&
        <>
          <div className=" xl:block hidden xl:py-spc80 py-8" style={{
            backgroundImage: `url(${IntroduceImageMoa1})`,
            backgroundRepeat: "no-repeat",
            backgroundPositionX: "120px",
            backgroundPositionY: "-40px",
            backgroundSize: "80%"
          }}>
            <div className="product_box grid xl:grid-cols-2 grid-cols-1 xl:gap-[87px] gap-9 " >
              <div className="relative flex justify-center xl:w-[513px] xl:h-[530px] h-[277px]">
                <img className="absolute xl:hidden block object-cover" src={IntroduceImageMoa1Mobile} />
                <img
                  src={listIntroduces[0]!?.images?.[0]?.url}
                  className="sticky rounded-md text-base xl:w-[513px] xl:h-[530px] w-[280px] w-[277px] object-cover xl:order-1 order-2"
                />
              </div>
              <div className="xl:order-2 order-1">
                <p className="xl:text-2xl text-[18px] font-semibold text-main mb-2 uppercase text-[#000000]">
                  {t("text.title.about_us")}
                </p>
                <p className="xl:text-40 text-title font-bold mb-5 uppercase text-[#000000]">
                  {isVn ? listIntroduces[0]!?.titleVn : listIntroduces[0]!?.titleKr}
                </p>
                <p className="xl:text-lg text-sm font-normal mb-5 text-left break-words text-[#000000] ">
                  {isVn ? listIntroduces[0]!?.content1Vn : listIntroduces[0]!?.content1Kr}
                </p>
              </div>
            </div>
          </div>
          <div className="xl:hidden block xl:py-spc80 py-8">
            <div className="product_box grid xl:grid-cols-2 grid-cols-1 xl:gap-[87px] gap-9 " >
              <div className="relative flex justify-center xl:w-[513px] xl:h-[530px] h-[277px]">
                <img className="absolute xl:hidden block object-cover h-[100%] w-[120%]" src={IntroduceImageMoa1Mobile} />
                <img
                  src={listIntroduces[0]!?.images?.[0]?.url}
                  className="sticky rounded-md text-base xl:w-[513px] xl:h-[530px] w-[280px] w-[277px] object-cover xl:order-1 order-2"
                />
              </div>
              <div className="xl:order-2 order-1">
                <p className="xl:text-2xl text-[18px] font-semibold text-main mb-2 uppercase text-[#000000]">
                  {t("text.title.about_us")}
                </p>
                <p className="xl:text-40 text-title font-bold mb-5 uppercase text-[#000000]">
                  {isVn ? listIntroduces[0]!?.titleVn : listIntroduces[0]!?.titleKr}
                </p>
                <p className="xl:text-lg text-sm font-normal mb-5 text-left break-words text-[#000000] ">
                  {isVn ? listIntroduces[0]!?.content1Vn : listIntroduces[0]!?.content1Kr}
                </p>
              </div>
            </div>
          </div>
        </>
      }
      <CategorySpecial/>
      <div className="xl:block hidden product_box xl:pb-28 pb-6 " style={{
        backgroundImage: `url(${IntroduceImageMoa2})`,
        backgroundPosition: "53% 42%",
      }}>
        <p className="xl:text-40 text-title font-bold mb-5 uppercase text-[#000000]">
          {isVn ? listIntroduces[1]?.titleVn : listIntroduces[1]?.titleKr}
        </p>
        <div className="grid xl:grid-cols-4 grid-cols-1 gap-5">
          <div className="col-span-1flex items-end">
            <p className="xl:text-base text-sm font-normal break-words max-w-[300px] text-[#000000]">
              {isVn ? listIntroduces[1]?.content1Vn : listIntroduces[1]?.content1Kr}
            </p>
          </div>
          <div className="xl:col-span-2 col-span-1 flex justify-center overflow-hidden sc480:mb-0 mb-10 xl:w-[593px] xl:h-[593px] h-[296px]">
            <img
              src={listIntroduces[1]?.images?.[0]?.url}
              className="xl:w-[593px] xl:h-[593px] rounded-full h-[296px] w-[304px]"
              alt=""
            />
          </div>
          <div className="col-span-1 flex items-end justify-end">
            <p className="xl:text-base text-sm font-normal break-words max-w-[300px] text-[#000000]">
              {isVn ? listIntroduces[1]?.content1Vn : listIntroduces[1]?.content1Kr}
            </p>
          </div>
        </div>
      </div>

      <div className="xl:hidden block 2xl:px-[15%] xl:px-[135px] lg:px-[82px] xl:pb-28 pb-6">
        <p className="xl:text-40 text-title px-4 font-bold mb-5 uppercase text-[#000000] mt-4">
          {isVn ? listIntroduces[1]?.titleVn : listIntroduces[1]?.titleKr}
        </p>
        <div className="grid xl:grid-cols-4 grid-cols-1">
          <div className="col-span-1 flex items-end px-4">
            <p className="xl:text-base text-sm font-normal break-words max-w-[300px] text-[#000000] mb-8">
              {isVn ? listIntroduces[1]?.content1Vn : listIntroduces[1]?.content1Kr}
            </p>
          </div>
          <div className="relative xl:col-span-2 w-full col-span-1 flex justify-center items-center overflow-hidden sc480:mb-0 mb-10 xl:w-[593px] xl:h-[593px] h-[296px]">
            <img className="absolute xl:hidden block object-cover h-[100%] w-[120%]" src={IntroduceImageMoa2} />
            <img
              src={listIntroduces[1]?.images?.[0]?.url}
              className="sticky xl:w-[593px] xl:h-[593px] rounded-full h-[296px] w-[304px]"
              alt=""
            />
          </div>
          <div className="col-span-1 flex items-end justify-end px-4">
            <p className="xl:text-base text-sm font-normal break-words max-w-[300px] text-[#000000]">
              {isVn ? listIntroduces[1]?.content1Vn : listIntroduces[1]?.content1Kr}
            </p>
          </div>
        </div>
      </div>
    </div >
  );
};
