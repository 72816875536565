import SearchIcon from '@assets/iconElements/SearchIcon';
import useI18n from '@hooks/useI18n'
import ProductServices from '@services/ProductServices';
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';

const SearchPageMobile = () => {

    const { t, isVn } = useI18n();
    const [value, setValue] = useState<string>("")
    const [show, setShow] = useState<Boolean>(true)
    const [history, setHistory] = useState<any>([]);
    const [listProduct, setListProduct] =
        useState<any[]>([]);
    const navigate = useNavigate();
    const onChange = (e: any) => {
        if (e.target.value.trim() !== "") {
            setShow(false)
        } else
            setShow(true)
        setValue(e.target.value)
    }

    const removeValue = (e: any) => {
        setListProduct([])
        setValue("")
    }
    const json = localStorage.getItem("search");
    useEffect(() => {
        setHistory(json ? JSON.parse(json) : [])
    }, [])
    const removeHistory = (to: any) => {
        if (history.length > 0) {
            const arr = history.filter((item: any) => item !== to)
            setHistory(arr)
            localStorage.setItem("search", JSON.stringify(arr))
        }
    }
    const handleHistory = (item: any) => {
        console.log("item", item)
        setValue(item)
        navigate("/search/" + item)

    }
    const getApi = () => {

        ProductServices.searchPoduct({
            "page": 0,
            "size": 10,
            "sort": "id,desc",
            "keyword": value,
            "address": json === null ? "Thành phố Hà Nội" : JSON.parse(json).name
        }).then(data => {
            // handleCountProduct(data.totalElements)
            setListProduct(data.content)
            console.log(data)
        })

    }

    const handleClick = () => {
        if (value !== "") {
            navigate("/search/" + value)
        }

    }

    useEffect(() => {
        setHistory(json ? JSON.parse(json) : [])
        let timer = setTimeout(() => {
            if (value)
                getApi()
        }, 500)
        return () => clearTimeout(timer)
    }, [value]);
    return (
        <>
            <div className=' px-[10px] flex justify-center items-center mt-[10px]'>
                <div className='mr-[5px]' onClick={() => navigate(-1)}> <SearchIcon width={18} height={18} color="black" check={4} /></div>
                <div className=' w-full relative'>
                    <input type="text" className='px-[30px] w-full h-[45px] border-[1px] border-main rounded-[10px] text-[14px]' value={value} onChange={onChange} />
                    <div className='absolute top-[14px] left-[10px] '>
                        <SearchIcon width={18} height={18} color="#C4C4C4" check={1} />
                    </div>
                    <div className='absolute top-[18px] right-[10px] cursor-pointer' onClick={removeValue}>
                        <SearchIcon width={18} height={12} color="#C4C4C4" check={2} />
                    </div>
                </div>
                <button className='text-[10px] h-[45px] w-[75px] flex justify-center items-center bg-main text-white rounded-[4px] ml-[10px]' onClick={handleClick}>{t('button.search')}</button>
            </div>
            {
                show && <div className='pt-[8px]'>
                    {history.map((item: any) => <p className="w-full flex items-center justify-between mt-[10px] px-[12px]">
                        <SearchIcon width={12} height={12} color="#878787" check={3} />
                        <div className='w-full pl-[15px]'> <p className="hover:text-main cursor-pointer" onClick={() => handleHistory(item)}>{item}</p></div>
                        <span className="cursor-pointer hover:text-main pl-[10px]" onClick={() => removeHistory(item)} >
                            <SearchIcon width={12} height={12} color="#C4C4C4" check={2} />
                        </span>
                    </p>)}
                </div>
            }
            {
                !show && <div className='pt-[8px]'>
                    {listProduct.map((item: any) => <p className="w-full flex items-center justify-between mt-[10px] px-[12px]">
                        <div className='w-full pl-[25px]' onClick={() => navigate("/product/" + item.id)}>      <p className="cursor-pointer text-[14px]"  >{isVn ? item.productNameVn : item.productNameKr}</p></div>
                    </p>)}
                </div>
            }


        </>
    )
}

export default SearchPageMobile