import { some } from "@utility/helper";
import api from "./api";
import { IRespone } from "./Types/respone";
import { CartItemNew } from "@contexts/CartContext";

const apiCart = "/checkpayment"
const apiVoucher = "/voucher"
const apiOrder = "/order"

export type CartItemRequest = {
    itemId: string,
    itemName: string,
    image: string,
    size: string,
    color: string,
    price: number,
    total: number,
    sku: string
}

export type CheckVoucherRequest = {
    itemsCartList: CartItemRequest[],
    voucher: string
}

export type PaymentOrderRequest = {
    itemsCartList: CartItemRequest[],
    paymentMethod: string,
    customer: {
        fullname: string,
        phoneNumber: string,
        email: string,
        address: string,
        province: string,
        district: string,
        commune: string
    },
    voucher: string
}

export type PaymentOrderRequestNew = {
    fullName: string,
    email: string,
    address: string,
    note: string,
    phoneNumber: string,
    paymentType: string,
    totalPrice: number,
    voucherPrice: number,
    voucherCode: string,
    productOrders: CartItemRequestNew[]
}

export type CartItemRequestNew = {
    productNameVn: string,
    productNameKr: string,
    productDetailId: string,
    quantityOder: number,
    price: number,
    addressWarehouse: string,
    image: string,
    actualPrice?: number,
}

const CartServices = {

    checkVoucherApi: async (data: CheckVoucherRequest): Promise<some> => {

        return api.post("/checkpayment", data).then(data => data.data)
    },

    paymentOrderApi: async (data: PaymentOrderRequestNew): Promise<IRespone> => {
        return api.post(apiOrder, data)
    },

    getAllCartItems: async (): Promise<any> => {
        return api.get("/cart-items")
    },

    changeCartItems: async (request: CartItemNew[]) : Promise<any> => {
        return api.put("/cart-items/update", request)
    },
}


export default CartServices;