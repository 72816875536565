import KakaoTalkIcon from "@assets/iconElements/KakaoTalkIcon";
import ZaloIcon from "@assets/iconElements/ZaloIcon";
import {
  CricleContactSvg,
  ImgHomeContact,
  MessageContact,
} from "@assets/icons";
import useI18n from "@hooks/useI18n";
import React from "react";

const ContactSession = () => {
  const { t } = useI18n();
  return (
    <div className="grid md:grid-cols-8 h-auto">
      <div className=" col-span-4 h-full">
        <img
          src={ImgHomeContact}
          alt=""
          className=" w-full  contact !h-[183px] object-top max-sm:object-cover"
        />
      </div>
      <div className="h-[183px] xl:block flex justify-center items-end col-span-4 bg-aqua-aq02 relative  pb-5">
        {/* <CricleContactSvg className="absolute xl:right-[12%] right-2 xl:top-4 bottom-0 xl:w-auto xl:h-auto w-[170px] h-[170px]" /> */}
        <div className="  h-[183px] relative p-3 pb-14">
          <div className="relative w-full h-[183px]  rounded-lg border-white z-1 p-[14px]">
            <p className="max-sm:text-[24px] max-sm:w-fit text-[24px] text-center text-white font-bold uppercase mb-2 w-3/4 mx-auto leading-[30px] max-sm:pt-[30px]">
              {t("text.constact.help")}
            </p>
            {/* <p className="md:text-[12px] text-[12px] text-center font-normal mb-[22px] max-sm:mb-[12px]">
              {t("text.constact.already")}
            </p> */}
            <div className="flex items-center justify-center gap-spc45 max-sm:gap-[32px]">
              <div>
                <a href="https://open.kakao.com/o/sKf07UIc" target="_blank" className="rounded-md bg-btn md:w-spc77 w-spc50 md:h-spc77 h-spc50 flex items-center justify-center mb-2 max-sm:ml-[5px]
                 max-sm:w-[55px] max-sm:h-[55px]
                ">
                  <KakaoTalkIcon width={38} height={38} classname="" />
                </a>
                <p className="text-sm font-bold text-center">
                  {t("global.kakaotalk")}
                </p>
              </div>
              <div>
                <a href="https://zalo.me/0337101004" target="_blank" className="rounded-md bg-btn md:w-spc77 w-spc50 md:h-spc77 h-spc50 flex items-center justify-center mb-2
                  max-sm:w-[55px] max-sm:h-[55px]
                ">
                  <ZaloIcon
                    //  width={56} height={25}
                    width={40}
                    height={18}
                  />
                </a>
                <p className="text-sm font-bold text-center">
                  {t("global.zalo")}
                </p>
              </div>
            </div>
          </div>
          {/* <img
            src={MessageContact}
            className="absolute bottom-0 w-full h-full left-0 top-0"
            alt=""
          /> */}
        </div>
      </div>
    </div>
  );
};

export default ContactSession;
