import TitleSession from "@components/common/TitleSession";
import SocialHelpBox from "commons/components/SocialHelpBox";
import { memo } from "react";
import SlideProducts from "./SlideProducts";

interface Props {
  isBtn?: boolean
  categoryId?: string
}
const ProductSale = memo(({ isBtn = true, categoryId }: Props) => {
  return (
    <div className="product_box pb-[58px] max-md:pt-[0px] bg-gray-100 max-sm:!px-[13px] max-sm:pb-[26px]">
      <TitleSession
        text="homepage.product.text"
        className="w-full mb-[35px] pt-[35px] max-sm:mb-[28px] max-md:pt-[0px]  uppercase"
      />
      <SlideProducts typeSlide="sale" row={2} size={4} categoryId={categoryId} />
      {/* {isBtn && <SocialHelpBox />} */}
    </div>
  );
});

export default ProductSale;
