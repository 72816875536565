import global from './global.json'
import vi from './vi.json'
import messages from './messages.json'
import text from './text.json'
import input from './input.json'
import button from './button.json'
import product from './product.json'
import router from './router.json'
import form from './form.json'
import voucher from './voucher.json'
export default {
    ...vi,
    ...global,
    ...text,
    ...messages,
    ...input,
    ...button,
    ...product,
    ...router,
    ...form,
    ...voucher
}