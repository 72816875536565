import BreakCrumb, { BreadcrumbType } from "@components/Breadcrumb";
import ContactSession from "@components/Home/ContactSession";
import DistributorSession from "@components/Home/DistributorSession";
import ProductNew from "@components/product/ProductNew";
import ProductSale from "@components/product/ProductSale";
import ProductSpecial from "@components/product/ProductSpecial";
import { useParams } from "react-router-dom";
import React, { memo, useCallback, useContext, useEffect, useRef, useState, ReactNode } from "react";
import useViewport from "@hooks/useViewPort";
import categoryServices from "@services/categoryService";
import { CategoryType } from "commons/contannt";
import { data } from "autoprefixer";
import useI18n from "@hooks/useI18n";
import ProductSearch from "@components/product/ProductSearch";

const CategoryPage = () => {
    const [category, setCategory] = useState<{ id?: number, data: { categoryNameVn: string, categoryNameKr: string } }>({ id: 0, data: { categoryNameVn: "...", categoryNameKr: "홈페이지" } });
    const param = useParams();
    const [lastBreakCrumb, setlastBreakCrumb] = useState<BreadcrumbType>();
    const { width } = useViewport();
    const [countProduct, setCountProduct] = useState<number>(0)
    const { t, isVn } = useI18n();
    const breakcrumData: BreadcrumbType[] = [
        {
            name: { categoryNameVn: "Trang chủ", categoryNameKr: "홈페이지" },
            clickable: true,
            active: false,
            link: "/",
        },
        {
            name: category.data,
            clickable: true,
            active: false,
            link: "/category/" + category.id,
        },

    ];
    const categoryId = param.id;

    useEffect(() => {
        console.log(categoryId)

        if (
            categoryId
        ) {
            categoryServices.getCategoryByIdNew(categoryId).then(data => {
                setCategory({ id: data.id, data: { categoryNameVn: data.categoryNameVn, categoryNameKr: data.categoryNameKr } })
            })
        }

    }, [param])

    const handleCountProduct = (count: number) => {
        setCountProduct(count)
    }

    return (
        <div className="pt-[50px] bg-gray-100 max-sm:pt-[10px]">


            <div className="product_box">
                {
                    width >= 1024 && (
                        <BreakCrumb data={breakcrumData} lastData={lastBreakCrumb} normalClass="text-normal1" activeClass="font-bold" />
                    )
                }
                <p className="pt-[20px] max-md:text-[14px] text-[18px]"><b>{isVn ? category.data.categoryNameVn : category.data.categoryNameKr}</b> ({countProduct} {t("global.footer.produc_special.index")} )</p>
                <hr className="w-full h-[2px] bg-main mb-[23px]" />
            </div>
            <ProductSale isBtn={false} categoryId={categoryId} />
            <ProductSpecial isbg={true} className="bg-gray-100" categoryId={categoryId} />
            <ProductSearch handleCountProduct={handleCountProduct} idCategory={categoryId} name={isVn ? category.data.categoryNameVn : category.data.categoryNameKr} />
            <ContactSession />
        </div>
    );
}

export default CategoryPage;