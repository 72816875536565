import CartIcon from "@assets/iconElements/CartIcon";
import {
  ArrowDownIcon,
  KoreaFlag,
  Logo,
  LogoPlusMark,
  MenuMobileIconIcon,
  PhoneIcon,
  SwiperSlideButton,
  UserIcon,
  VNFlag,
} from "@assets/icons";
import CartProductHover from "@components/Cart/CartProductHover";
import LocationBox, { ProvinceType, initProvince } from "@components/LocationComponent/LocationBox";
import HeaderSearch from "@components/common/HeaderSearch";
import { useCart } from "@contexts/CartContext";
import { ModalContext } from "@contexts/contextModal";
import useFocusOut from "@hooks/useFocusOut";
import useI18n from "@hooks/useI18n";
import AuthService from "@services/AuthServices";
import { ContentFooter } from "@services/FooterService";
import { ROLES, ROUTES } from "@utility/constants";
import clsx from "clsx";
import { memo, useContext, useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import CategoryItem from "./CategoryItem";
import HeaderMenuMobile from "./HeaderMenuMobile";
import categoryServices from "@services/categoryService";
import { CategoryType } from "@services/Types/category";
import AccountServices from "@services/AccountServices";

export type CategoryHeaderItem = {
  id?: number | null;
  title: string;
  items?: CategoryHeaderItem[];
};

export type CategoryHeader = {
  text: string;
  items: CategoryHeaderItem[];
};
// "i18next": "^22.4.14",
export const LanguageBox = memo(() => {
  const { isVn, changeLanguage } = useI18n();
  return (
    <div className="cursor-pointer group relative">
      <div className="flex  h-[27px] mt-[2px] ">
        {isVn ? (
          <>
            <VNFlag className="rounded-[7px]" />

          </>
          // <img src={VNFlag} alt="" className="w-full h-full w-[34px] h-[25px] rounded-[7px]" />
        ) : (
          <KoreaFlag className="mt-[2px]" />
        )}
        <ArrowDownIcon stroke="#FFF" className="pl-[5px] !w-[16px]" />

      </div>

      {isVn ? (
        <>
          <KoreaFlag
            onClick={() => changeLanguage("ksl")}
            className="w-full absolute top-[26px] left-[-8px]  group-hover:block hidden  "
          />
        </>
      ) : (
        <VNFlag
          onClick={() => changeLanguage("vi")}
          className="w-full absolute top-[25px] left-[-8px] group-hover:block hidden rounded-[7px]"
        />
        // <div
        //   className="w-full h-full absolute top-full left-0 group-hover:block hidden"
        //   }
        // >
        //   <img src={VNFlag} alt="" className="w-full h-full  w-[34px] h-[25px]" />
        // </div>
      )}
    </div>
  );
});

export const NavHeader = memo(
  ({ phoneNumber, fill }: { phoneNumber?: string; fill?: string }) => {
    return (
      <div className="flex sm:flex-row flex-col sm:items-center gap-4 sm:text-sm text-lg font-normal  sm:text-white text-black">
        <a
          href={`tel:${phoneNumber && phoneNumber.replace(/\s/g, "")}`}
          className="hover:cursor-pointer flex sm:gap-1 gap-4 items-center "
        >
          <PhoneIcon />
          <p className="sm:px-2 sm:text-wap-regular1 xl:text-[14px]">
            {phoneNumber! ? fill : "033 710 1004"}
          </p>
        </a>
      </div>
    );
  }
);
const Header = () => {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { setContentModal, setShowModal } = useContext(ModalContext);
  const { clickShow, setClickShow, ref: locationRef, refClose } = useFocusOut();
  const padingRef = useRef(null);
  const cartContext = useCart();
  const [footerInfo, setFooterInfo] = useState<ContentFooter>();
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [choose, setChoose] = useState<ProvinceType>(initProvince);
  const [user, setUser] = useState<any>(null);

  useEffect(() => {
    const json = localStorage.getItem("province")

    if (json !== null) {
      setChoose(JSON.parse(json))
    } else {
      localStorage.setItem("province", JSON.stringify(initProvince))
    }
    try {
      getAllCategory();
    } catch (error) { }
    AccountServices.getAccountInfo().then(data => {
      setUser(data)
    })
  }, []);

  const openMenuMobile = () => {
    setContentModal(
      <HeaderMenuMobile
        categoryData={categories}
        handleShowFormLocation={() => setClickShow(true)}
      />
    );
    setShowModal(true);
  };

  const handleChoose = () => {
    const json = localStorage.getItem("province")
    console.log(json)
    if (json !== null) {
      setChoose(JSON.parse(json))
    } else {
      localStorage.setItem("province", JSON.stringify(initProvince))
    }

  }

  const userLogin = () => {
    if (!AuthService.isLoggedIn()) {
      AuthService.doLogin();
    }
  };
  const userLogout = () => {
    navigate("/")
    AuthService.doLogout();
  };

  const getAllCategory = () => {
    categoryServices.getAllCategory().then((data) => {
      setCategories(data)
    })
  }

  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollCategory = (position: string) => {
    if (scrollRef.current) {
      const container = scrollRef.current;
      if (position === "LEFT") {
        container.scrollTo({
          left: container.scrollLeft - 500,
          behavior: 'smooth',
        });
      } else {
        container.scrollTo({
          left: container.scrollLeft + 500,
          behavior: 'smooth',
        });
      }
    }
  };

  const [showButtonLeft, setShowButtonLeft] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollLeft } = scrollRef.current;
        setShowButtonLeft(scrollLeft !== 0);
      }
    };

    if (scrollRef.current) {
      scrollRef.current.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <div className="sticky top-0 z-50 bg-gray-100 xl:h-header xl:shadow  ">
      <div className="bg-header flex items-center xl:px-[100px] h-spc80  max-sm:h-[66px] 3xl:px-[250px] max-3xl:justify-between max-sm:justify-evenly max-lg:px-[30px] max-sm:px-[10px]">
        <Link to={"/"} className="mr-[25px] max-sm:mr-[10px]">
          <img src={LogoPlusMark} alt="" width={48} />
          {/* <Logo className="xl:w-[74px] w-[70px] max-sm:w-[57px]" fill="white" /> */}
        </Link>
        <div
          ref={padingRef}
          onClick={() => setClickShow(true)}
          className="rounded-[6px]  relative h-[46px] max-sm:h-[40px] cursor-pointer pt-[7px] px-2  max-sm:px-[8px]  max-ssm:h-[35px]  max-ssm:pt-[5px]
            max-sm:w-fit break-words sc480:text-sm xl:text-[14px]  text-white bg-aqua-aq02  mr-[25px]  max-sm:mr-[20px] max-sm:pt-[7px] max-ssm:mr-[5px] "
        >
          <p className="leading-[15px] max-sm:text-[8px] max-sm:h-[8px] ">
            {t("global.see_Price")}
          </p>
          <p className="font-bold xl:text-[14px] max-sm:!text-[10px] max-sm:h-[8px]">
            {choose.name}
          </p>
        </div>
        <div className={`items-center    max-sm:mr-[7px] max-sm:mt-[1px] ${AuthService.isLoggedIn() ? '3xl:mr-[100px]' : '3xl:mr-[200px]'}`}>
          <HeaderSearch />
        </div>
        <div className="xl:block hidden mr-[25px] " >
          <NavHeader phoneNumber={footerInfo?.phoneNumber[0]} />
        </div>
        <div className="flex items-center">
          <div
            className="relative group hover:cursor-pointer  mr-[30px] max-sm:mr-[15px]"
            onClick={() => navigate(ROUTES["cart"]["index"])}
          >
            <CartIcon />
            <>
              <div className="absolute -top-1 -right-2 rounded-full bg-cancel aspect-square border w-5 h-5 text-xs text-white border-white flex justify-center items-center ">
                {cartContext.cartQuantity}
              </div>
              <div className="lg:group-hover:block hidden absolute top-[calc(100%_+_32px)]  -right-9 z-10">
                <CartProductHover />
              </div>
            </>
          </div>
          <div className="xl:block hidden mr-[25px]">
            <LanguageBox />
          </div>
          <div className="group xl:block hidden relative">

            {AuthService.isLoggedIn() ? (
              <>
                <div className="flex items-center justify-center cursor-pointer relative">
                  <img src="https://img.freepik.com/free-vector/illustration-businessman_53876-5856.jpg?w=826&t=st=1697420575~exp=1697421175~hmac=20b2d47a8ee37fd7444f1b18573a3f0c59c5c7a419b58ef5b589c59bf8643f5a" className="rounded-[50%] w-[30px] h-[30px]" alt="alo" />
                  <p className="text-white px-[5px] pt-[2px]">{user?.fullName}</p>
                  <ArrowDownIcon stroke="#FFF" className="!w-[12px] mt-[3px]" />
                </div>
                <div className="group-hover:block hidden absolute top-[31px] left-0 z-10 w-[20vh]">
                  <p
                    className="rounded-md px-4 py-2 whitespace-nowrap cursor-pointer bg-icon hover:text-main transition-all"
                    onClick={userLogout}
                  >
                    {t('global.logout')}
                  </p>

                </div>
              </>

            ) : <UserIcon
              className={clsx(" fill-gray-300 ", {
                "cursor-pointer": !AuthService.isLoggedIn(),
              })}
              onClick={userLogin}
              stroke="white"
            />}
          </div>
        </div>

        <MenuMobileIconIcon
          onClick={openMenuMobile}
          className="block xl:hidden w-10 hover:cursor-pointer mr-[-20px]  max-sm:mr-[0px]"
          fill="white"
          stroke="white"

        />

        {clickShow && (
          <div
            ref={locationRef}
            className={clsx(
              "absolute 2.5xl:left-[15%] 2xl:left-[254px] xl:left-[250px] lg:left-[240px] sm:left-[13%] left-0 top-full sm:w-[510px]  w-full"
            )}
          >
            <LocationBox
              handleChoose={() => handleChoose()}
              onClose={() => setClickShow(false)}
              closeRef={refClose}
            />
          </div>
        )}
      </div>

      <div className="hidden h-full xl:flex xl:px-[100px]  3xl:px-[250px] px-4 justify-between gap-5 text-wap-regular1 bg-white">
        <div className="flex items-center gap-4 w-3/4 ">
          {
            showButtonLeft &&
            <div
              className={
                "cursor-pointer prevcategory"
              }
            >
              <SwiperSlideButton width={10} className="rotate-180" onClick={() => scrollCategory("LEFT")} />
            </div>
          }
          <div className="flex items-center gap-4 overflow-x-auto hiddenScroll " ref={scrollRef}>

            {
              categories.map((it, idx) => {
                return (
                  <div key={idx} className="w-[250px]">
                    <CategoryItem data={it} />
                  </div>
                );
              })}
          </div>
          <div
            className={
              "cursor-pointer nextcategory"
            }
          >
            <SwiperSlideButton width={10} stroke="white" onClick={() => scrollCategory("RIGHT")} />
          </div>
        </div>

        {/* <div className="w-3/4 flex items-center gap-4">
          <div
            className={
              "cursor-pointer prevcategory"
            }
          >
            <SwiperSlideButton width={10} className="rotate-180" />
          </div>
          <Swiper
            slidesPerView={6}
            autoplay={false}
            spaceBetween={30}
            className="mySwiper"
            allowTouchMove={false}
            navigation={{
              nextEl: `.nextcategory`,
              prevEl: `.prevcategory`,
            }}
            modules={[Navigation]}
          >
            {
              categories.map((it, idx) => {
                return (
                  <SwiperSlide key={idx}>
                    <CategoryItem data={it} />
                  </SwiperSlide>
                );
              })}
          </Swiper>
          <div
            className={
              "cursor-pointer nextcategory"
            }
          >
            <SwiperSlideButton width={10} stroke="white" />
          </div>

        </div> */}
        <div className="flex gap-3 w-1/4">
          {AuthService.hasRole([ROLES.admin, ROLES.system]) ? (
            <Link
              className="flex items-center text-normal1 hover:text-main  font-bold"
              to={"/admin"}
            >
              {t("header.admin")}
            </Link>
          ) : (
            <></>
          )}
          {AuthService.hasRole([ROLES.user]) ? (
            <Link
              className="flex items-center text-normal1 hover:text-main  font-bold"
              to={"/account"}
            >
              {t("header.user")}
            </Link>
          ) : (
            <></>
          )}

          <Link
            className="flex items-center px-4 text-normal1  hover:text-main font-bold"
            to={"/about-us"}
          >
            {t("global.link.introduce")}
          </Link>

        </div>
      </div>
    </div>
  );
};

export default Header;
