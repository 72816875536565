import BtnLoading from "@components/btn-loading/BtnLoading";
import { ToastContex } from "@contexts/ToastContex";
import useI18n from "@hooks/useI18n";
import { PolicyWithLang } from "@pages/AdminPage/ManagePolicy";
import PolicyServices from "@services/PolicyServices";
import TranslateService from "@services/TranslateService";
import { ROUTES } from "@utility/constants";
import Editor from "commons/Editor";
import yup from "custom/yup/yupInstance";
import { useFormik } from "formik";
import { t } from "i18next";
import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function PolicyForm({ policy }: { policy?: PolicyWithLang }) {
  const { lang, isVn } = useI18n();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { onAddToast } = useContext(ToastContex);

  const navigate = useNavigate();

  // Use the useEffect hook to update initial values when the language changes
  useEffect(() => {
    const initialValues = {
      titleVn: "",
      describeVn: "",
      contentVn: "",
      titleKr: "",
      describeKr: "",
      contentKr: "",
    };

    initialValues.titleVn = policy?.titleVn || "";
    initialValues.describeVn = policy?.describeVn || "";
    initialValues.contentVn = policy?.contentVn || "";
    initialValues.titleKr = policy?.titleKr || "";
    initialValues.describeKr = policy?.describeKr || "";
    initialValues.contentKr = policy?.contentKr || "";

    setFieldValue("titleVn", initialValues.titleVn);
    setFieldValue("describeVn", initialValues.describeVn);
    setFieldValue("contentVn", initialValues.contentVn);
    setFieldValue("titleKr", initialValues.titleKr);
    setFieldValue("describeKr", initialValues.describeKr);
    setFieldValue("contentKr", initialValues.contentKr);

  }, [lang, policy]);


  const { handleSubmit, setFieldValue, values, errors, touched } =
    useFormik({
      initialValues: {
        titleVn: policy?.titleVn || "",
        describeVn: policy?.describeVn || "",
        contentVn: policy?.contentVn,
        titleKr: policy?.titleKr || "",
        describeKr: policy?.describeKr || "",
        contentKr: policy?.contentKr || "",
      },
      validationSchema: yup.object({
        titleVn: yup.string().max(255, t("text.form.policy.max_character", { max: 255 })).required(t("text.form.policy.required_title")),
        describeVn: yup.string().max(255, t("text.form.policy.max_character", { max: 255 })).required(t("text.form.policy.required_description")),
        contentVn: yup.string().max(50000, t("text.form.policy.max_character", { max: 255 })).required(t("text.form.policy.required_content")),
        titleKr: yup.string().max(255, t("text.form.policy.max_character", { max: 255 })).required(t("text.form.policy.required_title")),
        describeKr: yup.string().max(255, t("text.form.policy.max_character", { max: 5000 })).required(t("text.form.policy.required_description")),
        contentKr: yup.string().max(5000, t("text.form.policy.max_character", { max: 5000 })).required(t("text.form.policy.required_content")),
      }),
      onSubmit: async (values) => {
        try {
          setIsLoading(true);
          const dataSubmit = {
            titleVn: values.titleVn,
            titleKr: values.titleKr,
            describeVn: values.describeVn,
            describeKr: values.describeKr,
            contentKr: values.contentKr,
            contentVn: values.contentVn,
          };

          if (policy) {
            await PolicyServices.put(policy.id, dataSubmit);
            onAddToast({ type: "success", message: t("success.updated") });
          } else {
            await PolicyServices.post(dataSubmit);
            onAddToast({ type: "success", message: t("success.posted") });
          }
          return navigate(`/admin/${ROUTES.admin.policy.index}`);
        } catch (ex) {
          console.log(ex);
          return onAddToast({ type: "error", message: t("error.post_error") });
        } finally {
          setIsLoading(false);
        }
      },
    });


  const onChangeInput = async (value: string, fieldVn: string, fieldKr: string) => {
    setIsLoading(true)
    var changeValue = value;
    if (value.toString().length > 5000) {
      onAddToast({type: "error", message: t("text.form.policy.max_character", { max: 5000 })})
      changeValue = changeValue.substring(0, 4999)
    }
    if (isVn) {
      setFieldValue(fieldVn, changeValue)
      setFieldValue(fieldKr, await TranslateService.translateToKorea({ content: changeValue }))
    } else {
      setFieldValue(fieldKr, changeValue)
      setFieldValue(fieldVn, await TranslateService.translateToVietNam({ content: changeValue }))
    }
    setIsLoading(false);
  }

  const handleCancel = () => {
    navigate(`/admin/${ROUTES.admin.policy.index}`);
  };

  return (
    <form onSubmit={handleSubmit} className="px-10">
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-5">
          <label className="text-lg font-bold ">
            {t("text.section.title")} <span className="text-[#F45538]">*</span>
          </label>
          <div>
            <input
              className="w-full p-4 border-2 bg-inherit"
              onChange={(e) => onChangeInput(e.target.value, "titleVn", "titleKr")}
              value={isVn ? values.titleVn : values.titleKr}
            />
            {isVn ? (errors.titleVn && touched.titleVn && (
              <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                {errors.titleVn}
              </small>
            )) : (
              errors.titleKr && touched.titleKr && (
                <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                  {errors.titleKr}
                </small>
              )
            )}
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <label className="text-lg font-bold">
            {t("text.section.description")} <span className="text-[#F45538]">*</span>
          </label>
          <div>
            <input
              className="w-full p-4 border-2 bg-inherit"
              onChange={(e) => onChangeInput(e.target.value, "describeVn", "describeKr")}
              value={isVn ? values.describeVn : values.describeKr}
            />
            {isVn ? (errors.describeVn && touched.describeVn && (
              <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                {errors.describeVn}
              </small>
            )) : (
              errors.describeKr && touched.describeKr && (
                <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                  {errors.describeKr}
                </small>
              )
            )}
          </div>
        </div>
        <div className="flex flex-col gap-5">
          <label className="text-lg font-bold">
            {t("text.section.content")} <span className="text-[#F45538]">*</span>
          </label>
          <div>
            <textarea value={isVn ? values.contentVn : values.contentKr} onChange={(e) => onChangeInput(e.target.value, "contentVn", "contentKr")} className="w-full p-4 h-full border-2 bg-inherit min-h-[200px]" />
            {isVn ? (errors.contentVn && touched.contentVn && (
              <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                {errors.contentVn}
              </small>
            )) : (
              errors.contentKr && touched.contentKr && (
                <small className="text-[14px] leading-3 mt-1 text-[#F31A1A]">
                  {errors.contentKr}
                </small>
              )
            )}
          </div>
        </div>
      </div>

      <div className="flex item-center justify-end mt-[70px] mb-[155px] gap-10px">
        <button
          type="button"
          className="w-[8%] py-4 border border-[#0073E5] flex justify-center items-center  text-[#0073E5]  font-bold bg-white"
          onClick={() => handleCancel()}
        >
          {t("text.button.cancel")}
        </button>
        <BtnLoading
          type="submit"
          className="w-[8%] py-4 border border-[#0073E5] flex justify-center items-center  text-white  font-bold bg-[#0073E5]"
          isLoading={isLoading}
          disabled={isLoading}
        >
          {t("text.button.save")}
        </BtnLoading>
      </div>
    </form>
  );
}
