import { ICRequest } from "@assets/iconElements/ICRequest";
import { ArrowDownManageIcon, IconArrowDown, IconArrowUp, TrashCanIcon, TrashIconAdvice } from "@assets/icons";
import FormFeedBackContact from "@components/AdminComponents/FormFeedBackContact";
import { useShowMessage } from "@components/Modal/DialogMessage";
import { useShowConfirm } from "@components/Modal/DiglogComfirm";
import Pagination from "@components/Pagination";
import { ToastContex } from "@contexts/ToastContex";
import { ModalContext } from "@contexts/contextModal";
import useI18n from "@hooks/useI18n";
import AuthService from "@services/AuthServices";
import ContactServices from "@services/ContactServices";
import { some } from "@utility/helper";
import clsx from "clsx";
import ConfirmBox from "commons/ConfirmBox";
import InputChecboxElement from "commons/components/InputComponent/InputChecboxElement";
import { ContactType } from "commons/contannt";
import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function ManageContact() {
  const navigator = useNavigate();
  const { showConfirm } = useShowConfirm();
  const { showError, showSuccess, showWarning } = useShowMessage();
  const { onAddToast } = useContext(ToastContex);
  const { setShowModal, setContentModal } = useContext(ModalContext);
  const [listContact, setListContact] = useState<ContactType[]>([]);
  const [listIdDelete, setListIdDelete] = useState<string[]>([]);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currenPage, setCurrenPage] = useState<number>(1);
  const { t } = useI18n();
  const [statusFilter, setStatusFilter] = useState<"WAITING" | "REPLIED" | null>(
    null
  );
  const [searchKey, setsearchKey] = useState("");
  const [sortFilter, setSortFilter] = useState<"asc" | "desc">("desc");
  const handleSearch = (e: any) => {
    setsearchKey(e.target.value);
  };
  const handleFilter = (value: "asc" | "desc") => {
    setCurrenPage(1);
    navigator("");
    setSortFilter(value);
  };
  const handleAddManyId = () => {
    if (listContact.length > 0 && listIdDelete.length !== listContact.length) {
      const newListId = listContact.map((item) => {
        return item.id;
      });
      setListIdDelete(newListId);
      return;
    }
    setListIdDelete([]);
    return;
  };

  const handleAdIdDelete = (id: string) => {
    const checkIdAlready = listIdDelete.includes(id);
    if (checkIdAlready) {
      const newListId = listIdDelete.filter((item) => {
        return item != id;
      });
      setListIdDelete(newListId);
    } else {
      setListIdDelete([...listIdDelete, id]);
    }
  };

  const onDeletConcats = async () => {
    try {
      if (listIdDelete.length > 0) {
        await ContactServices.deleteManyContact(listIdDelete);
        showSuccess("success.deleted")
        navigator("");
        setListIdDelete([]);
        currenPage != 1 && setCurrenPage(1);
        setSortFilter("asc");
        getListContact({
          page: 0,
          size: 10,
          status: statusFilter,
          sort: ["createAt", sortFilter],
        });
      }
      return;
    } catch (error) {
      showError("error.deleted_error")
    }
  };

  const handleSetData = (value: ContactType) => {
    const newState = listContact.map((item) => {
      if (item.id == value.id) {
        item = value;
      }
      return item;
    });
    setListContact(newState);
  };

  const handleShowFormFeedBack = (items: ContactType) => {
    setShowModal(true);
    setContentModal(
      <FormFeedBackContact item={items} handleForm={handleSetData} />
    );
  };
  const handleShowConfirmDelete = () => {
    showConfirm("confirm.delete_contacts", () => onDeletConcats());
  };

  const showModalConfirm = async (data: ContactType) => {
    showConfirm("confirm.contact_change_status", () => {
      changeStatusContact(data);
    }, <ICRequest width={106} height={106} />, "warning.note_change_status");
  };
  const getListContact = async (param: some) => {
    const result = await ContactServices.getContactFilter(param);
    const { totalElements, content, status }: any = result;
    setListContact(content);
    setTotalPage(Math.ceil(totalElements / 10));
    return result;
  };
  const changeStatusContact = async (data: ContactType) => {
    try {
      const newData = { ...data, status: "REPLIED" };
      const putted: any = await ContactServices.put(data.id, newData);
      if (putted) {
        const newContact = listContact.map((item) => {
          if (item.id == putted.id) {
            item = putted;
          }
          return item;
        });
        setListContact(newContact);
        showSuccess("success.updated")
      }
    } catch (error) {
      showError("error.update_error")
    }
  };

  const handleFilterByStatus = () => {
    setCurrenPage(1);
    navigator("");
  };
  useEffect(() => {
    const param = {
      page: currenPage - 1,
      size: 10,
      status: statusFilter,
      sort: ["createAt", sortFilter],
    };
    getListContact(param);
    return () => { };
  }, [currenPage, sortFilter, statusFilter]);

  return (
    <div className="px-10">

      <div className="flex justify-between items-center h-[40px] gap-4 my-2 mt-20">
        <h3 className="text-title font-semibold text-main">
          {t("text.title.title_consultant")}
        </h3>
        <div className="flex items-center gap-2">
          <div>
            <button
              onClick={handleShowConfirmDelete}
              disabled={listIdDelete.length === 0}
              className={
                "flex justify-center items-center gap-x-[10px] px-4 py-3 border-[1px] border-[#E00] text-[#E00] font-bold text-wap-regular2" +
                (listIdDelete.length === 0
                  ? "cursor-not-allowed bg-inherit"
                  : "cursor-pointer bg-white")
              }
            >
              <TrashIconAdvice /> {t("text.button.delete")}
            </button>
          </div>
          <div
            onClick={() => handleFilter("desc")}
            className="flex justify-center items-center gap-x-[10px] px-4 py-3 border-[1px] border-main text-main font-bold text-wap-regular2 cursor-pointer"
          >
            <IconArrowUp /> {t("text.button.newest")}
          </div>
          <div
            onClick={() => handleFilter("asc")}
            className="flex justify-center items-center gap-x-[10px] px-4 py-3 border-[1px] border-main text-main font-bold text-wap-regular2 cursor-pointer"
          >
            <IconArrowDown /> {t("text.button.latest")}
          </div>
        </div>
      </div>

      <div className=" ">
        <table className="w-full">
          <thead>
            <tr className="border-b">
              <th className="px-4 py-4 w-7 relative">
                <div className="flex items-center justify-start absolute left-0 bottom-5">
                  <InputChecboxElement
                    isCheck={
                      listContact.length > 0 &&
                      listIdDelete.length === listContact.length
                    }
                    name={"checkAll"}
                    onHandleChange={handleAddManyId}
                    sizeBox="w-4 h-4 rounded-[4px]"
                  />
                </div>
              </th>
              <th className="text-start text-black text-wap-regular2 px-4 py-4 whitespace-nowrap">
                {t("text.table.full_name")}
              </th>
              <th className="text-start text-black text-wap-regular2 px-4 py-4 whitespace-nowrap">
                {t("text.table.phone")}
              </th>
              <th className="text-start text-black text-wap-regular2 px-4 py-4 whitespace-nowrap">
                Email
              </th>
              <th className="text-start text-black text-wap-regular2 px-4 py-4 whitespace-nowrap">
                {t("text.table.address")}
              </th>
              <th className="text-start text-black text-wap-regular2 px-4 py-4 whitespace-nowrap max-w-[300px]">
                {t("text.table.content")}
              </th>
              <th className="flex text-center text-black text-wap-regular2 px-4 py-4 whitespace-nowrap status-icon cursor-pointer">
                <div className="flex items-center gap-2 justify-center relative justify-start">
                  <span>{t("text.table.status")}</span>

                  {/*Icon filter*/}
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill={statusFilter ? "black" : "none"}>
                    <path d="M22 3H2L10 12.46V19L14 21V12.46L22 3Z" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                  </svg>

                  <div className="w-[100%] border-main absolute top-[150%] z-40 bg-white statusBox right-2/4 translate-x-[50%]">
                    <div
                      onClick={() => {
                        setStatusFilter("REPLIED");
                        handleFilterByStatus();
                      }}
                      className={clsx(
                        "w-full h-auto py-3 px-3 text-small cursor-pointer  text-black",
                        { "bg-main text-white": statusFilter == "REPLIED" }
                      )}
                    >
                      {t("text.table.status_data_replied")}
                    </div>
                    <div
                      onClick={() => {
                        setStatusFilter("WAITING");
                        handleFilterByStatus();
                      }}
                      className={clsx(
                        "w-full h-auto py-3 px-3 text-small cursor-pointer  text-black",
                        { "bg-main text-white": statusFilter == "WAITING" }
                      )}
                    >
                      {t("text.table.status_data_waiting")}
                    </div>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            {listContact.map((itemContact, indexContact) => {
              return (
                <tr
                  key={indexContact}
                  className=" h-[50px]"
                >
                  <td className="border-b text-center px-4 py-6  text-wap-regular2 relative">
                    <div className="flex items-center justify-start absolute left-0 bottom-5">
                      <InputChecboxElement
                        isCheck={listIdDelete.includes(itemContact.id)}
                        name={itemContact.id + "Input"}
                        onHandleChange={() => handleAdIdDelete(itemContact.id)}
                        sizeBox="w-4 h-4 rounded-[4px]"
                      />
                    </div>
                  </td>
                  <td
                    className="border-b px-4 py-6 text-wap-regular2 "
                    onClick={() => handleShowFormFeedBack(itemContact)}
                  >
                    <p className="text-start"> {itemContact.fullName}</p>
                  </td>
                  <td
                    onClick={() => handleShowFormFeedBack(itemContact)}
                    className="border-b px-4 py-6 text-wap-regular2"
                  >
                    {itemContact.phoneNumber}
                  </td>
                  <td
                    onClick={() => handleShowFormFeedBack(itemContact)}
                    className="border-b px-4 py-6 text-wap-regular2"
                  >
                    {itemContact.email}
                  </td>
                  <td
                    onClick={() => handleShowFormFeedBack(itemContact)}
                    className="border-b px-4 py-6 text-wap-regular2"
                  >
                    {itemContact.address}
                  </td>
                  <td
                    onClick={() => handleShowFormFeedBack(itemContact)}
                    className="border-b px-4 py-6 text-wap-regular2 max-w-[300px]"
                  >
                    {itemContact.content}
                  </td>
                  <td
                    className={clsx(
                      "border-b px-4  text-wap-regular2  whitespace-nowrap font-semibold",
                      {
                        "text-[#5CD931] no-underline": (itemContact.status === "REPLIED"),
                        "text-[#E73F3F]": !(itemContact.status === "REPLIED"),
                      }
                    )}
                  >
                    <div className="flex items-sr">
                      <svg className="mr-1" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                        <circle cx="6" cy="6" r="6" fill={(itemContact.status === "REPLIED") ? "#5CD931" : "#E73F3F"} />
                      </svg>
                      <p
                        onClick={() =>
                          !(itemContact.status === "REPLIED") && showModalConfirm(itemContact)
                        }
                        className={clsx({
                          "cursor-pointer underline underline-offset-[1px]": !(itemContact.status === "REPLIED"),
                        })}
                      >
                        {itemContact.status === "REPLIED" ? t("text.table.status_data_replied") : t("text.table.status_data_waiting")}
                      </p>
                    </div>
                    {(itemContact.status === "REPLIED") &&
                      <span className="text-[#A1A0A3] text-[13px] font-normal ml-4 no-underline">Admin</span>
                    }
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      {totalPage > 1 && (
        <div className="flex justify-end pb-[50px] pt-[50px] text-background-100">
          <Pagination
            currenPage={currenPage}
            setCurrentPage={setCurrenPage}
            total={totalPage}
          />
        </div>
      )}
    </div>
  );
}

export default ManageContact;
