import { ArrowToDownIcon, ArrowToRightIcon } from '@assets/icons'
import useI18n from '@hooks/useI18n';
import { SubCategory } from '@services/Types/category';
import { CategoryType } from 'commons/contannt';
import React from 'react'
import { useCollapse } from 'react-collapsed'
import { Link } from 'react-router-dom'
interface Props {
    data: SubCategory[];
    nameVn: string;
    nameKr: string;
    id?: number;
    close: () => void
}
const Collapse = ({ data, nameVn, nameKr, close, id }: Props) => {
    const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()
    const { isVn } = useI18n();
    return (
        <div>
            <button {...getToggleProps()} className=' py-[10px]  w-[100%] flex justify-between items-center'>
                <Link to={`/category/${id}`} className={`text-[18px] font-bold ${isExpanded ? 'text-main' : ''}`} onClick={close} >  {isVn ? nameVn : nameKr}</Link>
                {data.length > 0 && (isExpanded ? <ArrowToDownIcon /> : <ArrowToRightIcon />)}
            </button>
            <section {...getCollapseProps()} >
                {data.map((item, index) => {
                    return <Link key={index} to={`/category/${id}/${item.id}`} onClick={close} ><p className='text-[14px] pb-[10px]'>{isVn ? item.subCategoryNameVn : item.subCategoryNameKr}</p></Link>
                })}


            </section>
        </div >
    )
}

export default Collapse