import { LogoOrange } from '@assets/icons'
import { ToastContex } from '@contexts/ToastContex';
import useI18n from '@hooks/useI18n'
import AccountServices from '@services/AccountServices';
import React, { useContext, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const { t } = useI18n();

    const [value, setValue] = useState("");
    const { listToast, onAddToast } = useContext(ToastContex);
    const navigate = useNavigate();


    const handleClick = () => {
        if (value.trim() !== null && value.trim() !== "") {
            AccountServices.getResetPass(value).then((data) => {
                onAddToast({ type: "success", message: t("success.reset") })
                setTimeout(() => {
                    navigate("/")
                }, 3000)
            }).catch((e) => {
                console.log(e)
                onAddToast({ type: "warn", message: t("warning.reset") })
            })
        } else {
            onAddToast({ type: "warn", message: t("warning.reset-email") })
        }
    }

    return (
        <div className='flex h-[100vh] w-[100vw] justify-center flex-col items-center'>
            <Link to="/"><LogoOrange /></Link>
            <p className='text-[18px] text-[#264659] pt-[11px]'>{t('global.reset')}</p>
            <div className='flex flex-col max-sm:w-full max-sm:justify-center max-sm:items-center' >
                <p className='font-bold text-[18px] text-[#264659] pt-[22px] pb-[10px]'> {t('global.reset-email')}</p>
                <input type="email" value={value} onChange={(e: any) => setValue(e.target.value)} className='h-[40px] max-sm:w-[95%] w-[419px] rounded-[10px] border-[1px] border-[#3483DC] px-[20px]' />
                <button className='h-[40px] w-[419px] max-sm:w-[95%] rounded-[10px] bg-btn font-bold text-white text-[18px] mt-[23px]' onClick={handleClick}> {t('global.reset')}</button>
            </div>
        </div>
    )
}

export default ResetPassword