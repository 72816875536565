import PasswordIcon from '@assets/iconElements/PasswordIcon';
import UserBlackIcon from '@assets/iconElements/UserBlackIcon';
import useI18n from '@hooks/useI18n';
import React from 'react'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const Navigator = () => {
    const { t } = useI18n();
    const navi = useLocation();
    return (
        <div className='!bg-[#fff] w-[20%] max-1.5xl:w-[40%] h-[100vh] pt-[47px] max-sm:hidden' >
            <>
                <Link to="/account" className={`pl-[34px] mb-[15px] border-l-[4px]  flex items-center hover:text-main ${navi.pathname == "/account" ? 'text-main border-l-main ' : ''} `}  >
                    <UserBlackIcon hover={navi.pathname == "/account" ? true : false} />
                    <span className=" text-normal1 font-semibold pr-[7px] pl-[9px] pt-[2px]" >
                        {t("global.account")}
                    </span>
                </Link>
                <Link to="/change-password" className={`pl-[34px] border-l-[4px] mb-[10px] flex items-center hover:text-main ${navi.pathname == "/change-password" ? 'text-main border-l-main ' : ''} `} >
                    <PasswordIcon hover={navi.pathname == "/change-password" ? true : false} />
                    <span className=" text-normal1 font-semibold px-[12px] pt-[2px]">
                        {t("global.change-password")}
                    </span>
                </Link>

            </>
        </div>
    )
}

export default Navigator