import { colors } from "@utility/colors";
import { IconProps } from "@utility/types";
import React from "react";

const CloseIcon = ({
  width = 18,
  height = 19,
  color = colors.gray06,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill="none"
    >
      <path
        d="M13.7252 4.78248C13.6558 4.71296 13.5734 4.6578 13.4826 4.62016C13.3919 4.58252 13.2946 4.56315 13.1964 4.56315C13.0982 4.56315 13.0009 4.58252 12.9102 4.62016C12.8195 4.6578 12.7371 4.71296 12.6677 4.78248L9.00017 8.44248L5.33267 4.77498C5.26323 4.70555 5.1808 4.65047 5.09008 4.61289C4.99935 4.57531 4.90212 4.55597 4.80392 4.55597C4.70572 4.55597 4.60848 4.57531 4.51776 4.61289C4.42704 4.65047 4.3446 4.70555 4.27517 4.77498C4.20573 4.84442 4.15065 4.92685 4.11307 5.01758C4.07549 5.1083 4.05615 5.20554 4.05615 5.30373C4.05615 5.40193 4.07549 5.49917 4.11307 5.58989C4.15065 5.68062 4.20573 5.76305 4.27517 5.83248L7.94267 9.49998L4.27517 13.1675C4.20573 13.2369 4.15065 13.3194 4.11307 13.4101C4.07549 13.5008 4.05615 13.598 4.05615 13.6962C4.05615 13.7944 4.07549 13.8917 4.11307 13.9824C4.15065 14.0731 4.20573 14.1555 4.27517 14.225C4.3446 14.2944 4.42704 14.3495 4.51776 14.3871C4.60848 14.4247 4.70572 14.444 4.80392 14.444C4.90212 14.444 4.99935 14.4247 5.09008 14.3871C5.1808 14.3495 5.26323 14.2944 5.33267 14.225L9.00017 10.5575L12.6677 14.225C12.7371 14.2944 12.8195 14.3495 12.9103 14.3871C13.001 14.4247 13.0982 14.444 13.1964 14.444C13.2946 14.444 13.3919 14.4247 13.4826 14.3871C13.5733 14.3495 13.6557 14.2944 13.7252 14.225C13.7946 14.1555 13.8497 14.0731 13.8873 13.9824C13.9248 13.8917 13.9442 13.7944 13.9442 13.6962C13.9442 13.598 13.9248 13.5008 13.8873 13.4101C13.8497 13.3194 13.7946 13.2369 13.7252 13.1675L10.0577 9.49998L13.7252 5.83248C14.0102 5.54748 14.0102 5.06748 13.7252 4.78248Z"
        fill={color}
      />
    </svg>
  );
};

export default CloseIcon;
