import { NextArrowIcon } from "@assets/icons";
import { firstUpper, some } from "@utility/helper";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useState, useEffect } from "react";
import clsx from "clsx";
import { ROUTES } from "@utility/constants";
import { CategoryHeaderItem } from "./Header";
import { ProductCategoryHeader } from "@services/CategoryProductServices";
import { SubCategory } from "@services/Types/category";
import useI18n from "@hooks/useI18n";
import { data } from "autoprefixer";

type Props = {
  items: SubCategory[];
  id: number;
};

const CategoryDropdown = ({ items, navbar, name }: some) => {
  const navigate = useNavigate();
  const { isVn } = useI18n();
  const [searchParams, setSearchParams] = useSearchParams();
  const [navbarParam, setNavbarParam] = useState<string>("");
  const [categoryParam, setCategoryParam] = useState<string>("");
  const [detailParam, setDetailParam] = useState<string>("");
  const [hover, setHover] = useState<number | undefined>(items[0].id)


  useEffect(() => {
    setNavbarParam(searchParams.get("navbar") || "");
    setCategoryParam(searchParams.get("category") || "");
    setDetailParam(searchParams.get("detail") || "");
  }, [searchParams]);

  // console.log(items);

  return (
    <div className="header__dropdown min-h-fit">
      <div className="flex container pl-[150px] py-5  h-full w-full flex-wrap max-h-[50vh]  overflow-auto header-scrollbar">
        <div className="flex flex-col ">
          {items.map((item: SubCategory, i: any) => {
            return (
              <div className="flex flex-col " key={i}>
                <Link
                  key={item.id}
                  to={`/category/${name}/${item.id}`}
                  onMouseEnter={() => setHover(item.id)}
                  className={clsx("text-normal1 w-[17vw] border-r-aqua-aq01 border-r-[2px] py-2 capitalize", {
                    " !border-r-main !border-r-[4px] w-[17.055vw] z-10": hover === item.id,
                    "text-main":
                      detailParam == (item.id ? item.id : item.subCategoryNameVn) &&
                      categoryParam == (item.id ? item.id : item.subCategoryNameVn) &&
                      navbar == navbarParam,
                    "text-text":
                      detailParam != (item.id ? item.id : item.subCategoryNameVn) ||
                      categoryParam != (item.id ? item.id : item.subCategoryNameVn) ||
                      navbar != navbarParam,
                  })}
                >
                  {isVn ? item.subCategoryNameVn : item.subCategoryNameKr}
                </Link>
                {/* {item.imagesSubcategory.map((data) => {
                return <img src="https://imge.com/wp-content/uploads/2019/01/IMGE-CaseStudies-IMGEReveal-Logo-1a.jpg" alt="" />
              })} */}
                {/* ))} */}

              </div>
            );
          })}
        </div>
        <div className="ml-[16px]">
          <Link to={`/category/${name}/${items.filter((value: any) => value.id === hover)[0].id}`}>
            {items.filter((value: any) => value.id === hover)[0].imagesSubcategory.length > 0 &&
              <img src={items.filter((value: any) => value.id === hover)[0].imagesSubcategory[0].url} className="rounded-[12px] w-[409px] h-[277px] object-cover" alt="" />
            }
          </Link>
        </div>
      </div>
    </div>
  );
};

export default CategoryDropdown;
