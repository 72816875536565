import PlusLinerIcon from "@assets/iconElements/PlusLinerIcon";
import { AddImage, TrashIconAdvice } from "@assets/icons";
import GroupButton from "@components/Buttons/GroupButton";
import LinearButton from "@components/Buttons/LinearButton";
import { useShowMessage } from "@components/Modal/DialogMessage";
import { InputComponent } from "@components/input/InputComponent";
import TitleInput from "@components/input/TitleInput";
import useI18n from "@hooks/useI18n";
import ProductServices from "@services/ProductServices";
import TranslateService from "@services/TranslateService";
import { CategoryType } from "@services/Types/category";
import { Atribuite, ListAtribuite, ProductDetails, ProductItem } from "@services/Types/product";
import UploadImage from "@services/UploadImage";
import categoryServices from "@services/categoryService";
import clsx from "clsx";
import Editor from "commons/Editor";
import { TextError } from "commons/TextError";
import DropdowBox from "commons/components/DropdowBox";
import { useFormik } from "formik";
import {
  ChangeEvent,
  Fragment,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import SliderPreviewImages from "../../../components/AdminComponents/SliderPreviewImages";
import province_data from "../../../utility/province_date.json";
import ChoseCategory from "./ChoseCategory";
import AtributeItem from "./component/AtributeItem";
import { ModalContext } from "@contexts/contextModal";
import AddAtributeForm from "./component/AddAtributeForm";
import { ICDeleteTrashLight } from "@assets/iconElements/ICDeleteTrashLight";
import { ToastContex } from "@contexts/ToastContex";

function ProductEditComponent() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { setShowModal, closeModal, setContentModal } =
    useContext(ModalContext);
  const { showError, showSuccess, showWarning } = useShowMessage();
  const { isVn, t, changeLanguage } = useI18n();
  const [categories, setCategories] = useState<CategoryType[]>([]);
  const [categoryName, setCategoryName] = useState<string>("Chọn Phân loại");
  const [imagePreview, setImagePreview] = useState<string[]>([]);
  const [imagesUpdate, setImagesUpdate] = useState<string[]>([]);
  const [listImageActived, setListImageActived] = useState<string[]>([]);
  const [listProducts, setListProducts] = useState<ProductDetails[]>([]);
  const [videoUrl, setVideoUrl] = useState<string>();
  const [listAttributesImage, setListAttributesImage] = useState<ListAtribuite[]>([])
  const [listAttributesSelectImage, setListAttributesSelectImage] = useState<Atribuite[][]>([])
  const [listImageProducts, setListImageProducts] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [changeLanguageEditor, setChangeLanguageEditor] = useState<boolean>(isVn);

  const nameTable = [
    t("text.manage_detail_product.title_address_pdetail"),
    t("text.manage_detail_product.title_attribute_pdetail"),
    t("text.manage_detail_product.title_price_pdetail"),
    t("text.manage_detail_product.title_promo_pdetail"),
    t("text.manage_detail_product.title_stock_pdetail"),
  ];


  const [applyList, setApplyList] = useState({
    promo: 0,
    stockQuantity: 0
  })

  useEffect(() => {
    setChangeLanguageEditor(isVn)
  }, [isVn])

  const onChangeInput = async (value: string, fieldVn: string, fieldKr: string) => {
    setIsLoading(true)
    if (isVn) {
      setFieldValue(fieldVn, value)
      setFieldValue(fieldKr, await TranslateService.translateToKorea({ content: value }))
    } else {
      setFieldValue(fieldKr, value)
      setFieldValue(fieldVn, await TranslateService.translateToVietNam({ content: value }))
    }
    setIsLoading(false);
  }

  const [validForm, setValid] = useState<any>({ file: false, detail: false });
  const { onAddToast } = useContext(ToastContex)

  const formik = useFormik<ProductItem>({
    initialValues: {
      productCode: '',
      productNameVn: '',
      productNameKr: '',
      categoryId: null,
      subCategoryId: 0,
      cost: 0,
      price: 0,
      promo: 0,
      stockQuantity: 0,
      salientFeaturesVn: '',
      salientFeaturesKr: '',
      detailVn: '',
      detailKr: '',
      specVn: '',
      specKr: '',
      featured: 0,
      createAt: null,
      warehouse: [],
      atributies: [
      ],
      productDetails: [
      ]
    },
    validationSchema: Yup.object({
      productCode: Yup.string().trim().required(isVn ? "Không được để trống" : "비워둘 수 없습니다."),
      productNameVn: Yup.string().trim().required("Không được để trống"),
      productNameKr: Yup.string().trim().required("비워둘 수 없습니다."),
      price: Yup.number().required(isVn ? "Không được để trống" : "비워둘 수 없습니다.").min(1, isVn ? "Quá nhỏ" : "너무 작은"),
      promo: Yup.number().required(isVn ? "Không được để trống" : "비워둘 수 없습니다.").min(0, isVn ? "Quá nhỏ" : "너무 작은").max(100, isVn ? "Quá lớn" : "너무 큰"),
      salientFeaturesKr: Yup.string()
        .max(3000, "3000자 이내"),
      salientFeaturesVn: Yup.string()
        .max(3000, "Không quá 3000 kí tự"),
      detailVn: Yup.string()
        .max(3000, "Không quá 3000 kí tự"),
      detailKr: Yup.string()
        .max(3000, "3000자 이내"),
      categoryId: Yup.number().required(isVn ? "Phải chọn danh mục" : "카테고리를 선택해야 합니다."),
      warehouse: Yup.array().min(1, isVn ? "Tối thiểu 1 địa điểm" : "최소 1개 위치"),
      atributies: Yup.array().min(1, isVn ? "Tối thiểu 1 thuộc tính" : "최소 1개의 속성"),
    }),
    onSubmit: async () => {
      if (listProducts.length <= 0) {
        return onAddToast({ type: "error", message: isVn ? "Vui lòng thêm ít nhất 1 giá trị cho thuộc tính" : "속성에 값을 1개 이상 추가하세요." })
      }
      if (values.detailKr === "" || values.salientFeaturesKr === "" || values.detailVn === "" || values.salientFeaturesVn === "") {
        return onAddToast({ type: "error", message: isVn ? "Vui lòng nhập các trường còn thiếu. Bao gồm cả chuyển đổi ngôn ngữ để nhập" : "누락된 필드를 입력하세요. 입력을 위한 언어 전환 포함." })
      }
      try {
        setIsLoading(true);

        const convertedAttributeFes = values.atributies && values.atributies.map((item) => {
          const attributeFeValues = item.valueKr.map((valueKr, index) => ({
            valueVn: item.valueVn[index],
            valueKr,
          }));

          return {
            attributeFeValues,
            attributeFeNameKr: item.attributeNameKr,
            attributeFeNameVn: item.attributeNameVn,
          };
        });

        const dataSubmit = {
          productCode: values.productCode,
          productNameVn: values.productNameVn,
          productNameKr: values.productNameKr,
          categoryId: values.categoryId,
          subCategoryId: values.subCategoryId,
          cost: 0,
          price: values.price,
          promo: values.promo,
          stockQuantity: values.stockQuantity,
          salientFeaturesVn: values.salientFeaturesVn,
          salientFeaturesKr: values.salientFeaturesKr,
          detailVn: values.detailVn,
          detailKr: values.detailKr,
          specVn: values.specVn,
          specKr: values.specKr,
          featured: values.featured,
          warehouse: values.warehouse,
          attributeFes: convertedAttributeFes,
          productDetails: listProducts,
          images: listImageProducts,
          videoUrl: videoUrl
        }

        if (id) {
          await ProductServices.putProducById(id, dataSubmit);
          showSuccess("text.manage_detail_product.success_update_product")
          navigate("/admin/product")
        } else {
          await ProductServices.addProduct(dataSubmit)
          showSuccess("text.manage_detail_product.success_update_product")
          navigate("/admin/product")
        }
      } catch (error) {
        showError("text.manage_detail_product.error_update_product")
      }
      finally {
        setIsLoading(false);
      }
    },
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldError,
    setValues,
    handleChange,
    handleSubmit
  } = formik;

  const handleChangeEditor = () => {
    changeLanguageEditor ? setChangeLanguageEditor(false) : setChangeLanguageEditor(true)
    isVn ? changeLanguage("ksl") : changeLanguage("vi")
  }

  const handleChoseCategory = (id: number, subId?: number) => {
    setFieldValue("categoryId", id);
    setFieldValue("subCategoryId", subId);
    setFieldError("categoryId", undefined);
  };

  const handleShowAtributeEdit = (data?: ListAtribuite, index?: number) => {
    setContentModal(
      <AddAtributeForm
        handleEdit={handleAddAtribute}
        attibute={data}
        indexEdit={index}
      />
    );
    setShowModal(true);
  };

  const handleAddAtribute = (data: ListAtribuite, index: number) => {
    const attributeList = [...values.atributies!];
    let productDetails = [...values.productDetails];

    if (index >= 0) {
      productDetails = productDetails.map((detail, idx) => {
        if (detail.attributes.length > 0) {
          const listAtb = detail.attributes.map((subDetail, idx) => {
            if (
              subDetail.attributeNameVn.includes(
                attributeList[idx as number].attributeNameVn
              )
            ) {
              subDetail = {
                ...subDetail,
                attributeNameVn: data.attributeNameVn,
                attributeNameKr: data.attributeNameKr,
              };
            }
            return subDetail;
          });
          return { ...detail, attributes: listAtb };
        } else {
          detail.attributes.push({
            attributeNameVn: data.attributeNameVn,
            attributeNameKr: data.attributeNameKr,
            valueVn: "",
            valueKr: ""
          })
          return detail;
        }
      })

      attributeList[index] = data;
      setFieldValue("atributies", attributeList);
      setFieldValue("productDetails", productDetails);
      closeModal();
      return;
    }

    const checkName = attributeList.findIndex(
      (item) => item.attributeNameVn == data.attributeNameVn
    );
    if (checkName < 0) {
      attributeList.push(data);
      setFieldValue("atributies", attributeList);
      closeModal();
    } else {
      showError("text.manage_detail_product.error_duplicate_value");
    }
  };

  const handleDeleteAtb = (index: number, indexSub: number = -1) => {
    const attributeList = [...values.atributies!];
    if (indexSub > -1) {
      attributeList[index].valueVn.splice(indexSub, 1);
      attributeList[index].valueKr.splice(indexSub, 1);
    } else {
      attributeList.splice(index, 1);
    }

    setFieldValue("attributes", attributeList)
    handleAddValueAtribute("", -1, attributeList, values.warehouse);
  };

  const generateAttr = (attributeValues: any[][]): any[][] => {
    const attributes: any[][] = [];

    const generateProductsRecursive = (currentAttributes: any[], currentIdx: number) => {
      if (currentIdx === attributeValues.length) {
        attributes.push([...currentAttributes]);
        return;
      }

      const values = attributeValues[currentIdx];
      for (const value of values) {
        currentAttributes.push(value);

        generateProductsRecursive(currentAttributes, currentIdx + 1);

        currentAttributes.pop();
      }
    }

    generateProductsRecursive([], 0);

    return attributes;
  }

  const convertToAttributes = async (item: any) => {
    const listAtt: Atribuite[] = [];
    await Promise.all(
      item.map(async (i: any, index: number) => {
        const att: Atribuite = (isVn) ? {
          valueVn: i,
          valueKr: await TranslateService.translateToKorea({ content: i }),
          attributeNameVn: values.atributies![index].attributeNameVn,
          attributeNameKr: values.atributies![index].attributeNameKr
        } : {
          valueVn: await TranslateService.translateToVietNam({ content: i }),
          valueKr: i,
          attributeNameVn: values.atributies![index].attributeNameVn,
          attributeNameKr: values.atributies![index].attributeNameKr
        }
        listAtt.push(att)
      }))


    return listAtt.sort((a, b) => {
      const nameA = a.attributeNameVn.toLowerCase();
      const nameB = b.attributeNameVn.toLowerCase();
      if (nameA < nameB) {
        return -1;
      } else if (nameA > nameB) {
        return 1;
      } else {
        return 0;
      }
    });
  }

  const handleAddValueAtribute = async (value: string, idx: number, attributes?: ListAtribuite[], warehouses?: any) => {
    const attributeList = attributes ? attributes : [...values.atributies!];
    if (value != "" && idx != -1) {
      const checkDupicate = attributeList[idx]
        ? isVn
          ? attributeList[idx].valueVn.includes(value)
          : attributeList[idx].valueKr.includes(value)
        : false;
      if (checkDupicate) {
        return showError("text.manage_detail_product.error_duplicate_value");
      }

      const translated = isVn
        ? await TranslateService.tranSlateKr({ nameVn: value, nameKr: "" })
        : await TranslateService.tranSlateVn({ nameVn: "", nameKr: value });
      attributeList[idx].valueVn.push(translated.nameVn);
      attributeList[idx].valueKr.push(translated.nameKr);
    }

    setListAttributesImage(attributeList)

    const listOfValueVn = attributeList.map(item => item.valueVn);


    const listAttVn = generateAttr(listOfValueVn)


    const listProductDetails: ProductDetails[] = await Promise.all(listAttVn.map(async (it) => {
      const listAttributes = await convertToAttributes(it)
      return {
        priceDetail: values.price,
        promoDetail: values.promo,
        stockQuantity: 0,
        soldQuantity: 0,
        imageDetailUrl: "",
        addressWarehouse: "",
        images: [],
        attributes: listAttributes
      };
    }))

    const listProductDetailsWareHouse: ProductDetails[] = [];

    const whs = warehouses ? warehouses : values.warehouse;

    whs.forEach((wh: any) => {
      const listPrds = listProductDetails.map((prd: any) => ({
        ...prd,
        addressWarehouse: wh.address
      }));
      listProductDetailsWareHouse.push(...listPrds);
    });

    setFieldValue("atributies", attributeList);
    setListProducts(listProductDetailsWareHouse);
  };


  const getCategory = async () => {
    try {
      const result = await categoryServices.getAllCategory();
      setCategories(result);
    } catch (error) { }
  };

  const handleAddWarehowse = (province: string) => {
    const newListAddress = [...values.warehouse];
    const checkProvince = newListAddress.findIndex(
      (item) => item.address === province
    );
    if (checkProvince < 0) {
      newListAddress.push({ address: province });
    } else {
      newListAddress.splice(checkProvince, 1);
    }
    setFieldValue("warehouse", newListAddress);
    handleAddValueAtribute("", -1, values.atributies, newListAddress);
  };

  const checkProvinceActive = (province: string) => {
    const checkProvince = values.warehouse.findIndex(
      (item) => item.address === province
    );
    return checkProvince >= 0 ? true : false;
  };

  const handleChoseFile = async (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files!;
    if (
      imagePreview.length >= 9 ||
      files.length > 9 ||
      files.length + imagePreview.length > 9
    ) {
      showError("text.manage_detail_product.warn_add_image")
      return;
    }
    if (id && files.length > 9 - imagePreview.length) {
      showError("text.manage_detail_product.warn_add_image")
      return;
    }

    if (files) {
      const formData = new FormData();
      let listPrew: string[] = imagePreview;
      for (let i = 0; i < files.length; i++) {
        formData.append("file", files[i]);
      }

      const listImageproducts = files.length > 0
        ? await UploadImage.uploadListImages(formData)
        : [];

      if (id) {
        imagesUpdate.map((img) => {
          listImageproducts.push({ url: img })
        })
      }

      listImageproducts.map((img: any) => {
        if (!listPrew.includes(img.url)) {
          listPrew.push(img.url);
        }
      });

      setListImageProducts(listImageproducts)
      setImagePreview(listPrew);
      setValid({ ...validForm, file: false });
    }
    event.target.value = "";
  };

  const handleChoseVideo = async (event: ChangeEvent<HTMLInputElement>) => {
    const fileVideo = event.target.files![0];
    if (!fileVideo.type.includes("mp4") || fileVideo.size > 31457280) {
      showError("text.manage_detail_product.warn_add_video");
      return;
    }

    const formVideodata = new FormData();
    formVideodata.append("file", fileVideo!);
    const video: any =
      fileVideo && (await UploadImage.uploadVideos(formVideodata));
    const videoLink = video?.url;
    setVideoUrl(videoLink);
    event.target.value = "";
  };

  const handleApplyPrice = () => {
    const listPrds = listProducts.map((item) => {
      return {
        ...item,
        promoDetail: applyList.promo,
        stockQuantity: applyList.stockQuantity
      }
    })
    setListProducts(listPrds)
  };

  const removeProductDetail = (index: number) => {
    const prds = [...listProducts];
    prds.splice(index, 1);
    setListProducts(prds)
  }

  const handleEditPromoProductDetail = (index: number, value: number) => {
    if (value > 100) {
      value = 100
    }
    let prd = listProducts[index];
    prd = {
      ...prd,
      promoDetail: value
    }
    listProducts[index] = prd;
    const prds = [...listProducts]
    setListProducts(prds)
  }

  const handleEditPriceProductDetail = (index: number, value: number) => {
    let prd = listProducts[index];
    prd = {
      ...prd,
      priceDetail: value
    }
    listProducts[index] = prd;
    const prds = [...listProducts]
    setListProducts(prds)
  }

  const handleEditStockQuantityProductDetail = (index: number, value: any) => {
    let prd = listProducts[index];
    prd = {
      ...prd,
      stockQuantity: value
    }
    listProducts[index] = prd;

    const prds = [...listProducts]

    setListProducts(prds)
  }

  const handleSetColorIntoImage = (
    item: string,
    index: number,
    nameAttribute: string,
    isUnActive: boolean
  ) => {
    if (listAttributesSelectImage.length > 0) {
      let productDetails = [...listProducts];
      if (isUnActive) {
        setListImageActived(
          [...listImageActived].filter((items) => {
            return items != item;
          })
        );
        productDetails
          .filter((item) => item.attributes.map((it) => isVn ? it.valueVn : it.valueKr).join(" - ") === nameAttribute)
          .forEach((filteredItem) => {
            filteredItem.imageDetailUrl = "";
            filteredItem.images = []
          });
      } else {
        let newImageActived = [...listImageActived];
        if (listImageActived[index]) {
          newImageActived.splice(index, 1, item);
        } else {
          newImageActived.push(item);
        }
        setListImageActived([...newImageActived]);
        productDetails
          .filter((item) => item.attributes.map((it) => isVn ? it.valueVn : it.valueKr).join(" - ") === nameAttribute)
          .forEach((filteredItem) => {
            filteredItem.imageDetailUrl = item;
            filteredItem.images = [{ url: item }]
          });
      }
      setListProducts(productDetails)
    }
  };

  const handleDeleteImage = (pathImg: string) => {
    setImagePreview(
      imagePreview.filter((item) => {
        return item != pathImg;
      })
    );
    setImagesUpdate(
      imagesUpdate.filter((item) => {
        return item != pathImg;
      })
    );
    setListImageProducts(listImageProducts.filter((item: any) => {
      return item.url != pathImg
    }))
  };

  useEffect(() => {
    imagePreview.forEach((item) => {
      URL.revokeObjectURL(item);
    });
    getCategory();
  }, []);

  const getProducbyId = async (id: string) => {
    const product: any = await ProductServices.findProductById(id);
    const listImagesUrl: string[] = [];
    product.images?.sort().map((img: any) => {
      listImagesUrl.push(img.url)
    })

    const productDetails = product.productDetails;
    const reversedAttributes = product.attributeFes?.map((atb: any) => {
      const { attributeFeValues, attributeFeNameKr, attributeFeNameVn } = atb;

      const valueKr = attributeFeValues.map((attributeValue: any) => attributeValue.valueKr);
      const valueVn = attributeFeValues.map((attributeValue: any) => attributeValue.valueVn);

      return {
        attributeNameKr: attributeFeNameKr,
        attributeNameVn: attributeFeNameVn,
        valueKr,
        valueVn,
      };
    });
    setListAttributesImage(reversedAttributes)
    setValues({
      productCode: product.productCode,
      productNameVn: product.productNameVn,
      productNameKr: product.productNameKr,
      categoryId: product.categoryId,
      subCategoryId: product.subCategoryId,
      cost: product.cost,
      price: product.price,
      promo: product.promo,
      stockQuantity: product.stockQuantity,
      salientFeaturesVn: product.salientFeaturesVn,
      salientFeaturesKr: product.salientFeaturesKr,
      detailVn: product.detailVn,
      detailKr: product.detailKr,
      specVn: product.specVn,
      specKr: product.specKr,
      featured: product.featured,
      createAt: product.createAt,
      warehouse: product.warehouse,
      atributies: reversedAttributes,
      productDetails: [
      ],
    })

    setVideoUrl(product.videoUrl || "")
    setImagePreview(listImagesUrl);
    setImagesUpdate(listImagesUrl);
    setListImageProducts(listImagesUrl.map((it) => {
      return {
        url: it
      }
    }))
    setListProducts(productDetails)
  };
  useEffect(() => {
    if (id) {
      getProducbyId(id);
    }
  }, [id]);

  useEffect(() => {
    const name = categories.find((item) => item.id == values.categoryId);
    const sub = name?.subCategoryList.find((item) => item.id == values.subCategoryId);
    if (name) {
      setCategoryName(isVn ? `${name.categoryNameVn!} ${sub ? ' - ' + sub?.subCategoryNameVn : ""}` : `${name.categoryNameKr!} ${sub ? ' - ' + sub?.subCategoryNameKr : ""}`);
    } else {
      setCategoryName(t("text.manage_detail_product.placeholder_product_category"));
    }
  }, [values.categoryId, categories, values.subCategoryId]);

  useEffect(() => {
    const fetchListAttributeSelectImage = async () => {
      const listOfValueVn = listAttributesImage.map(item => item.valueVn);
      const listAttVn = generateAttr(listOfValueVn)
      const listAttributes = await Promise.all(listAttVn.map(async (it) => {
        return await convertToAttributes(it)
      }));
      setListAttributesSelectImage(listAttributes)
    }
    fetchListAttributeSelectImage()
  }, [listAttributesImage]);

  const handleChangeValue = (value: number, field: string) => {
    setFieldValue(field, value)
    if (listProducts && listProducts.length > 0) {
      if (field === "price") {
        const prds = listProducts.map((prd) => {
          return {
            ...prd,
            priceDetail: value
          }
        })
        setListProducts(prds)
      } else if (field === "promo") {
        const prds = listProducts.map((prd) => {
          return {
            ...prd,
            promoDetail: value
          }
        })
        setListProducts(prds)
        setApplyList({ promo: value, stockQuantity: 0 })
      }
    }
  }

  return (
    <div className="pt-9 pb-10px flex-1">
      <h2 className="titlePage mb-5">{t("text.manage_detail_product.title")}</h2>
      <div className="flex gap-x-14 gap-y-5 flex-row flex-wrap mb-3">
        <div>
          <p className="text-small">
            {t("text.manage_detail_product.image_label")} <span className="text-main">*</span>
          </p>
          <div className="flex gap-[10px] mt-3">
            {imagePreview.map((itemPreview, indexPreview) => {
              return (
                <div
                  key={indexPreview}
                  className="w-[100px] h-[100px] rounded-md bg-slate-400 relative"
                >
                  <img
                    src={itemPreview}
                    alt=""
                    className="w-full h-full rounded-md"
                  />
                  <div
                    onClick={() => {
                      handleDeleteImage(itemPreview);
                    }}
                    className="absolute top-2 cursor-pointer right-1 z-50"
                  >
                    <TrashIconAdvice />
                  </div>
                </div>
              );
            })}
            <label
              className={
                "w-[100px] h-[100px] p-2 rounded-md border-[2px] border-dashed flex items-center flex-col justify-end cursor-pointer " +
                (validForm?.file && "border-main")
              }
            >

              <input
                type="file"
                multiple
                className="hidden"
                onChange={(event) => handleChoseFile(event)}
              />
              <AddImage width={36} />
              <p className="text-xs tracking-[.03] text-gray-300 text-center">
                {t("text.manage_detail_product.add_image", { number: imagePreview.length })}
              </p>
            </label>
          </div>
        </div>

        <div className="flex flex-col gap-3 ">
          <p className="text-small">{t("text.manage_detail_product.video_label")}</p>
          <div className="flex items-center gap-3 w-fit rounded-md  border-[2px] border-dashed h-[100px] px-3">
            <label className="w-[100px] px-2 flex items-center flex-col justify-center cursor-pointer">
              <input
                type="file"
                name="video"
                className="hidden"
                onChange={(event) => handleChoseVideo(event)}
              />
              <AddImage width={36} />
              <p className="text-xs tracking-[.03] text-gray-300 text-center">
                {t("text.manage_detail_product.add_video", { number: videoUrl != "" && videoUrl != undefined && videoUrl != null ? 1 : 0 })}
              </p>
            </label>
            <p className="max-w-[200px] text-left font-normal text-small text-gray-300">
              {t("text.manage_detail_product.add_video_des")}
            </p>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-x-[34px] gap-y-3">
        <div>
          <TitleInput isNormal={true} isRequired={true} name={t("text.manage_detail_product.product_code_label")} />
          <InputComponent
            name="productCode"
            value={values.productCode}
            onChange={handleChange}
            placeholder={t("text.manage_detail_product.placeholder_product_code")}
            className="!rounded-sm"
          />
          {errors.productCode && touched.productCode && (
            <TextError message={errors.productCode} />
          )}
        </div>
        <div>
          <TitleInput isNormal={true} isRequired={true} name={t("text.manage_detail_product.product_name_label")} />
          <InputComponent
            name={isVn ? "productNameVn" : "productNameKr"}
            value={isVn ? values.productNameVn : values.productNameKr}
            onChange={(e) => onChangeInput(e.target.value, "productNameVn", "productNameKr")}
            placeholder={t("text.manage_detail_product.placeholder_product_name")}
            className="!rounded-sm"
          />
          {
            isVn ? (errors.productNameVn && touched.productNameVn && (
              <TextError message={errors.productNameVn} />
            )) : errors.productNameKr && touched.productNameKr && (
              <TextError message={errors.productNameKr} />
            )
          }
        </div>

        <div>
          <TitleInput
            isNormal={true}
            isRequired={true}
            name={t("text.manage_detail_product.category_product_label")}
          />
          <DropdowBox nameBox={categoryName}>
            <div className="h-[200px] w-full overflow-y-auto">
              {categories.map((item, index) => {
                return (
                  <ChoseCategory
                    itemCategory={item}
                    key={index}
                    categoryCheck={{
                      id: values.categoryId,
                      idSub: values.subCategoryId,
                    }}
                    handleClick={handleChoseCategory}
                  />
                );
              })}
            </div>
          </DropdowBox>
          {errors.categoryId && touched.categoryId && (
            <TextError message={errors.categoryId} />
          )}
        </div>
        <div>
          <TitleInput isNormal={true} isRequired name={t("text.manage_detail_product.address_warehouse_product_label")} />
          <DropdowBox
            nameBox={
              values.warehouse[0]
                ? values.warehouse[0].address + "..."
                : t("text.manage_detail_product.placeholder_product_warehouse")
            }
          >
            <div className="h-[300px] w-full overflow-y-auto flex flex-wrap gap-2 p-5">
              {province_data.map((province, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => handleAddWarehowse(province.Name)}
                    className={clsx(
                      "p-2 w-fit text-text-main flex items-center rounded-sm",
                      {
                        "bg-aqua-aq03 text-main": checkProvinceActive(
                          province.Name
                        ),
                        "border border-neutra-neutra80": !checkProvinceActive(
                          province.Name
                        ),
                      }
                    )}
                  >
                    {province.Name}
                  </div>
                );
              })}
            </div>
          </DropdowBox>
          {errors.warehouse && touched.warehouse && (
            <TextError message={errors.warehouse.toString()} />
          )}
        </div>

        <div>

          <TitleInput isNormal={true} isRequired name={t("text.manage_detail_product.price_label")} />
          <InputComponent
            name="price"
            type="number"
            value={values.price == 0 ? "" : values.price}
            onChange={(e) => handleChangeValue(Number(e.target.value), "price")}
            placeholder={t("text.manage_detail_product.placeholder_product_price")}
            required={true}
            unit={"VNĐ"}
            className="!rounded-sm"
          />
          {errors.price && touched.price && (
            <TextError message={errors.price} />
          )}
        </div>
        <div>
          <TitleInput isNormal={true} isRequired={false} name={t("text.manage_detail_product.promo_label")} />
          <InputComponent
            name="promo"
            type="number"
            value={values.promo == 0 ? "" : values.promo}
            placeholder={t("text.manage_detail_product.placeholder_product_promo")}
            onChange={(e) => handleChangeValue(Number(e.target.value), "promo")}
            className="!rounded-sm"
            unit={"%"}
          />
          {errors.promo && touched.promo && (
            <TextError message={errors.promo} />
          )}
        </div>
      </div>
      <div className="py-10 flex flex-col gap-7">
        <p className="text-title font-semibold text-main">{t("text.manage_detail_product.title_description")}</p>
        <p><span className="flex items-center text-xs justify-center gap-1 p-1 w-[200px] bg-btn text-white rounded-full !rounded-none false cursor-pointer" onClick={() => handleChangeEditor()}>{changeLanguageEditor ? "Đổi trình chỉnh sửa sang tiếng Hàn" : "편집기를 베트남어로 변경하세요"}</span></p>
        <span className="text-sm">{t("text.manage_detail_product.noitice_change_editor")}</span>
        <div className="z-1">
          <TitleInput isNormal={true} isRequired name={t("text.manage_detail_product.spec_label")} />
          {
            changeLanguageEditor && <>
              <Editor
                content={values?.salientFeaturesVn || ""}
                onChange={(data) => setFieldValue("salientFeaturesVn", data)}
              />
              {errors.salientFeaturesVn && touched.salientFeaturesVn && (
                <TextError message={errors.salientFeaturesVn} />
              )}
            </>
          }
          {
            !changeLanguageEditor && <>
              <Editor
                content={values?.salientFeaturesKr || ""}
                onChange={(data) => setFieldValue("salientFeaturesKr", data)}
              />
              {errors.salientFeaturesKr && touched.salientFeaturesKr && (
                <TextError message={errors.salientFeaturesKr} />
              )}
            </>
          }
        </div>
        <div className="z-1">
          <TitleInput isNormal={true} isRequired name={t("text.manage_detail_product.detail_label")} />
          {
            changeLanguageEditor && <>
              <Editor
                content={values?.detailVn || ""}
                onChange={(data) => setFieldValue("detailVn", data)}
              />
              {errors.detailVn && touched.detailVn && (
                <TextError message={errors.detailVn} />
              )}
            </>
          }
          {
            !changeLanguageEditor &&
            <>
              <Editor
                content={values?.detailKr || ""}
                onChange={(data) => setFieldValue("detailKr", data)}
              />
              {errors.detailKr && touched.detailKr && (
                <TextError message={errors.detailKr} />
              )}
            </>
          }
        </div>
        {/* <div className="z-1">
          <TitleInput
            isNormal={true}
            isRequired={false}
            name={t("text.manage_detail_product.sailent_label")}
          />
          {
            changeLanguageEditor && <>
              <Editor
                content={values?.specVn || ""}
                onChange={(data) => setFieldValue("specVn", data)}
              />
              {errors.specVn && touched.specVn && (
                <TextError message={errors.specVn} />
              )}
            </>
          }
          {
            !changeLanguageEditor && <>
              <Editor
                content={values?.specKr || ""}
                onChange={(data) => setFieldValue("specKr", data)}
              />
              {errors.specKr && touched.specKr && (
                <TextError message={errors.specKr} />
              )}
            </>
          }
        </div> */}
      </div>
      <div className="mb-10">
        <p className="text-title font-semibold text-main mb-5">{t("text.manage_detail_product.title_attribute")}</p>
        {values.atributies && values.atributies!.length > 0 &&
          values.atributies!.map((item: any, index: number) => {
            return (
              <AtributeItem
                key={index}
                indexAtribute={index}
                handleAddValue={handleAddValueAtribute}
                atribute={item}
                handleDelete={handleDeleteAtb}
                handleEditAtb={() => {
                  handleShowAtributeEdit(item, index);
                }}
              />
            );
          })}
        <LinearButton
          text="button.add_attribute"
          iconLeft={<PlusLinerIcon />}
          className="w-[170px] h-10 !rounded !text-sm overflow-hidden"
          className_child="rounded !text-sm "
          onClick={() => handleShowAtributeEdit()}
        />
        <input type="hidden" value={values.atributies?.length} name="atributies" />
        {errors.atributies && touched.atributies && (
          <TextError message={errors.atributies} />
        )}
      </div>

      <div className="w-4/5">
        <p className="text-small font-semibold mb-5">
          {t("text.manage_detail_product.list_classify_label")} <span className="text-[#FF3535]">*</span>
        </p>
        <div className="w-full flex gap-spc30 mb-10">
          <div className="h-10 flex items-center w-[70%] 2xl:w-[78%] border rounded-sm">
            <InputComponent
              type="number"
              placeholder={t("text.manage_detail_product.placeholder_apply_promo")}
              name="promo"
              className="rounded-l-sm rounded-r-none border"
              unit={"%"}
              value={applyList.promo > 0 ? applyList.promo : ""}
              onChange={(event) => {
                if (Number(event.target.value) >= 0 && Number(event.target.value) <= 100) {
                  setApplyList({
                    ...applyList,
                    [event.target.name]: event.target.value,
                  })
                } else if (Number(event.target.value) > 100) {
                  setApplyList({
                    ...applyList,
                    [event.target.name]: 100,
                  })
                } else {
                  setApplyList({
                    ...applyList,
                    [event.target.name]: 0,
                  })
                }
              }
              }
            />
            <InputComponent
              type="number"
              min={0}
              placeholder={t("text.manage_detail_product.placeholder_apply_stock")}
              name="stockQuantity"
              className="rounded-r-sm rounded-l-none border"
              value={applyList.stockQuantity > 0 ? applyList.stockQuantity : ""}
              onChange={(event) =>
                setApplyList({
                  ...applyList,
                  [event.target.name]: event.target.value,
                })
              }
            />
          </div>
          <div
            className="btn-normal justify-center px-0 items-center w-[30%] 2xl:w-[22%] text-sm leading-18 cursor-pointer"
            onClick={handleApplyPrice}
          >
            {t("text.manage_detail_product.button_apply_all")}
          </div>
        </div>

        {/* bảng chọn size và giá */}
        <div className="mb-4 overflow-y-auto max-h-[600px]">
          <table className="w-full border-collapse border border-neutra-neutra80">
            <thead>
              <tr className="">
                {nameTable.map((itemName, index) => {
                  return (
                    <th
                      key={index}
                      className={clsx(
                        "text-sm font-normal py-3 text-center min-w-[128px] border border-neutra-neutra80"
                      )}
                    >
                      {itemName}
                    </th>
                  );
                })}
              </tr>
            </thead>
            <tbody >
              {listProducts && listProducts.map((items, index) => {
                return (
                  <Fragment key={index}>
                    <tr className="relative">
                      <td className="py-6 text-center text-sm font-semibold uppercase min-w-[170px] border border-neutra-neutra80">
                        {items.addressWarehouse}
                      </td>
                      <td className="py-6 text-center text-sm font-semibold uppercase min-w-[170px] border border-neutra-neutra80">
                        {items.attributes.map((atb) => (isVn ? atb.valueVn : atb.valueKr)).join(" - ")}
                      </td>
                      <td className="border border-neutra-neutra80">
                        <input
                          value={items.priceDetail}
                          type="number"
                          onChange={(e) => handleEditPriceProductDetail(index, Number(e.target.value))}
                          className="text-sm text-center font-semibold px-6 placeholder:text-gray-200 w-full"
                        />
                      </td>
                      <td className="border border-neutra-neutra80 p-3">
                        <input
                          value={items.promoDetail}
                          type="number"
                          min={0}
                          max={100}
                          onChange={(e) => handleEditPromoProductDetail(index, Number(e.target.value))}
                          className="text-sm text-center font-semibold placeholder:text-gray-200 w-full"
                        />
                      </td>
                      <td className="border border-neutra-neutra80 p-3">
                        <input
                          value={items.stockQuantity}
                          type="number"
                          min={0}
                          onChange={(e) => handleEditStockQuantityProductDetail(index, Number(e.target.value))}
                          className="text-small text-center font-semibold placeholder:text-gray-200 w-full"
                        />
                      </td>
                      {items.attributes.length > 1 && (
                        <td className="border border-neutra-neutra80">
                          <div className="p-5" onClick={() => removeProductDetail(index)}><ICDeleteTrashLight /></div>
                        </td>
                      )}
                    </tr>
                  </Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* chọn màu */}
        <div className="mb-9">
          <TitleInput
            isNormal={true}
            isRequired={true}
            name={t("text.manage_detail_product.image_label_calssify")}
          />
          {imagePreview.length > 0 && listAttributesSelectImage.length > 0 && (
            <div className="border-2 border-gray-200 w-[80%]">
              {listAttributesSelectImage.map((item, index) => {
                const nameColor = item.map((it) => isVn ? it.valueVn : it.valueKr).join(" - ");
                const imageActive = listProducts.find((item) => item.attributes.map((it) => isVn ? it.valueVn : it.valueKr).join(" - ") === nameColor)!?.imageDetailUrl || ""
                return (
                  <SliderPreviewImages
                    key={index}
                    classNavigate={"slideProduct" + index}
                    indexSlide={index}
                    nameColor={nameColor}
                    lisImages={imagePreview}
                    listImageActived={listImageActived}
                    imageActived={imageActive}
                    handleActiveImage={handleSetColorIntoImage}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
      <div className="flex item-center justify-end my-7">
        <GroupButton
          onSubmit={handleSubmit}
          isLoading={isLoading}
          onCancel={() => navigate("/admin/product")}
        />
      </div>
    </div >
  );
}



export default ProductEditComponent;
