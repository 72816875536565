// import MainSidebar from "./components/MainSidebar";

import FabComponent from "@components/fab";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "./components/Footer";
import Header from "./components/Header";
import HeaderAdmin from "./components/HeaderAdmin";

const DefaultLayout = ({ children }: { children: JSX.Element }) => {
  const router = useLocation().pathname.includes("admin");
  const passwordRetrieval = useLocation().pathname.includes("password-retrieval");
  const register = useLocation().pathname.includes("register");
  const path = useLocation();
  console.log(path)
  return (

    <>
      {!register ?
        <div className="min-h-screen bg-background max-w-[1920px] mx-auto relative">

          {
            passwordRetrieval ? <>
              {children}
            </> : <>

              {!router ? <Header /> : <HeaderAdmin />}

              <div className="min-h-[800px]">
                {/* new changes 22/4 2022*/}
                {/* <Outlet /> */}
                {children}
              </div>
              {!path.pathname.includes("product") &&
                <Footer />
              }

              {!router ? <FabComponent /> : <></>}

            </>
          }

        </div>

        :
        <div className=""> {children}</div>
      }
    </>

  );
};

export default DefaultLayout;
