import React from "react";
import { colors } from "@utility/colors";
import { IconProps } from "@utility/types";
export const ICUploadImage = ({
  width = 106,
  height = 106,
  color = colors.blue01,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 27 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity="0.4"
        d="M26.4991 8.84244V19.6664C26.5779 20.3421 26.5028 21.0268 26.2794 21.6693C26.056 22.3118 25.6901 22.8954 25.2091 23.3764C24.7281 23.8574 24.1445 24.2233 23.502 24.4468C22.8595 24.6702 22.1748 24.7453 21.4991 24.6664H5.49912C4.82347 24.7453 4.13874 24.6702 3.49623 24.4468C2.85373 24.2233 2.27011 23.8574 1.78911 23.3764C1.30811 22.8954 0.942205 22.3118 0.718802 21.6693C0.495399 21.0268 0.420295 20.3421 0.499118 19.6664V6.3331C0.420295 5.65745 0.495399 4.97272 0.718802 4.33022C0.942205 3.68771 1.30811 3.1041 1.78911 2.6231C2.27011 2.1421 2.85373 1.77619 3.49623 1.55279C4.13874 1.32938 4.82347 1.25428 5.49912 1.3331H17.2325C17.2915 1.33465 17.3494 1.34923 17.4021 1.3758C17.4548 1.40237 17.5009 1.44027 17.5373 1.48679C17.5736 1.53331 17.5992 1.58729 17.6122 1.64486C17.6252 1.70242 17.6253 1.76216 17.6125 1.81977C17.2979 3.37768 17.6143 4.99675 18.4922 6.32163C19.3701 7.64651 20.7378 8.56893 22.2951 8.88644C23.5277 9.13285 24.8065 8.98594 25.9511 8.46644C26.0121 8.44213 26.0782 8.43322 26.1435 8.44051C26.2088 8.44779 26.2713 8.47105 26.3254 8.50821C26.3796 8.54538 26.4238 8.59531 26.4541 8.6536C26.4844 8.71189 26.4998 8.77675 26.4991 8.84244Z"
        fill={color}
      />
      <path
        d="M24.3827 15.8837L18.4467 9.94771C18.1953 9.69726 17.8549 9.55664 17.5 9.55664C17.1451 9.55664 16.8047 9.69726 16.5533 9.94771L9.96667 16.5344C9.84205 16.6565 9.6745 16.725 9.5 16.725C9.3255 16.725 9.15795 16.6565 9.03333 16.5344L7.78 15.281C7.5286 15.0306 7.1882 14.89 6.83333 14.89C6.47847 14.89 6.13807 15.0306 5.88667 15.281L2.61733 18.5504C2.5423 18.6253 2.50009 18.727 2.5 18.833V19.6677C2.5 21.7744 3.39333 22.6677 5.5 22.6677H21.5C23.6067 22.6677 24.5 21.7744 24.5 19.6677V16.1664C24.4999 16.0603 24.4577 15.9587 24.3827 15.8837Z"
        fill={color}
      />
      <path
        d="M8.16486 10.6673C7.946 10.6681 7.72911 10.6258 7.5266 10.5427C7.32409 10.4597 7.13992 10.3376 6.9846 10.1834C6.67091 9.87197 6.49379 9.44868 6.4922 9.00665C6.49061 8.56462 6.66468 8.14007 6.97611 7.82638C7.28755 7.5127 7.71084 7.33558 8.15287 7.33398H8.16486C8.60689 7.33398 9.03082 7.50958 9.34338 7.82214C9.65594 8.1347 9.83153 8.55862 9.83153 9.00065C9.83153 9.44268 9.65594 9.8666 9.34338 10.1792C9.03082 10.4917 8.60689 10.6673 8.16486 10.6673Z"
        fill={color}
      />
      <path
        d="M26.2052 2.29386L24.2052 0.293857C24.0175 0.106915 23.7634 0.00195312 23.4985 0.00195312C23.2336 0.00195312 22.9795 0.106915 22.7918 0.293857L20.7918 2.29386C20.6152 2.48342 20.519 2.73415 20.5236 2.99322C20.5282 3.25229 20.6331 3.49947 20.8163 3.68269C20.9995 3.8659 21.2467 3.97085 21.5058 3.97542C21.7649 3.97999 22.0156 3.88383 22.2052 3.70719L22.4985 3.41519V6.33386C22.4985 6.59907 22.6038 6.85343 22.7914 7.04096C22.9789 7.2285 23.2333 7.33386 23.4985 7.33386C23.7637 7.33386 24.0181 7.2285 24.2056 7.04096C24.3931 6.85343 24.4985 6.59907 24.4985 6.33386V3.41519L24.7918 3.70719C24.9814 3.88383 25.2321 3.97999 25.4912 3.97542C25.7503 3.97085 25.9974 3.8659 26.1807 3.68269C26.3639 3.49947 26.4688 3.25229 26.4734 2.99322C26.478 2.73415 26.3818 2.48342 26.2052 2.29386Z"
        fill={color}
      />
    </svg>
  );
};
