import vi from "./vi";
import ksl from "./ksl";

export const resources = {
  vi: {
    translation: {
      ...vi
    },
  },
  ksl: {
    translation: {
      ...ksl
    }
  }
};
