import { colors } from "@utility/colors";
import { IconProps } from "@utility/types";
import React from "react";

const OrderIcon = ({
  width = 24,
  height = 24,
  color = colors.black02,
}: IconProps) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1 2C1 1.44772 1.44772 1 2 1H12C12.5043 1 12.9297 1.37554 12.9923 1.87597L13.3828 5H22C22.5523 5 23 5.44772 23 6V22C23 22.5523 22.5523 23 22 23H10.5C9.99568 23 9.57028 22.6245 9.50772 22.124L9.11722 19H2C1.44772 19 1 18.5523 1 18V2ZM11.1328 19L11.2157 19.6634L11.7962 19H11.1328ZM12.8672 17H3V3H11.1172L12.8672 17ZM12.7038 21H21V7H13.6328L13.8828 9H17.5C18.0523 9 18.5 9.44772 18.5 10C18.5 10.5523 18.0523 11 17.5 11H14.1328L14.3828 13H17.5C18.0523 13 18.5 13.4477 18.5 14C18.5 14.5523 18.0523 15 17.5 15H14.6328L14.9923 17.876C15.0277 18.1591 14.9404 18.4438 14.7526 18.6585L12.7038 21ZM4 6C4 5.44772 4.44772 5 5 5H8.5C9.05228 5 9.5 5.44772 9.5 6C9.5 6.55228 9.05228 7 8.5 7H5C4.44772 7 4 6.55228 4 6ZM4 10C4 9.44772 4.44772 9 5 9H9C9.55228 9 10 9.44772 10 10C10 10.5523 9.55228 11 9 11H5C4.44772 11 4 10.5523 4 10ZM4 14C4 13.4477 4.44772 13 5 13H9.5C10.0523 13 10.5 13.4477 10.5 14C10.5 14.5523 10.0523 15 9.5 15H5C4.44772 15 4 14.5523 4 14Z"
        fill={color}
      />
    </svg>
  );
};

export default OrderIcon;
