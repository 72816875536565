import Pagination from "@components/Pagination";
import { ModalContext } from "@contexts/contextModal";
import { useAppDispatch, useAppSelector } from "@hooks/reduxHook";
import { useContext, useEffect, useState, Ref, ReactNode } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { setCurrentPage } from "redux/reducer/voucherSlice";
import { ButtonFilter } from "./Products/ManageProduct";
import VoucherServices from "@services/voucherService";
import { IVoucher } from "@services/Types/voucher";
import { ICDeleteTrashLight } from "@assets/iconElements/ICDeleteTrashLight";
import { colors } from "@utility/colors";
import { ICAdd } from "@assets/iconElements/ICAdd";
import { useTranslation } from "react-i18next";
import TableVoucher from "./ComponentVoucher/TableVoucher";
import { ICFilter } from "@assets/iconElements/ICFIlter";
import clsx from "clsx";
import InputChecboxElement from "commons/components/InputComponent/InputChecboxElement";
import { useShowConfirm } from "@components/Modal/DiglogComfirm";
import { useShowMessage } from "@components/Modal/DialogMessage";
import useI18n from "@hooks/useI18n";

interface Props {
  refCheckboxAll?: Ref<HTMLInputElement>;
}

export const ColumnHeaders = (props: { title: string, icon?: ReactNode }) => {
  const { t } = useTranslation();
  return (
    <div className="text-start text-content font-bold text-normal flex flex-row gap-x-2 justify-start items-center">
      {t(props.title)}
      {props.icon}
    </div>
  )
}

export const ItemTable = (props: { isProduct?: boolean, img?: string, title?: string, isPriece: boolean, prieceOld?: any, prieceNew?: any }) => {
  const { isProduct = false, img, isPriece, prieceNew, prieceOld, title } = props;
  return (
    <div className={clsx('flex justify-center items-center text-wap-regular2 font-normal text-aqua-aq02',
      {
        '!text-grey-222124': isProduct,
        '!font-bold': !isProduct
      }
    )}>
      {
        isProduct && (
          <div className="flex flex-row gap-x-[18px]">
            <img src={img} alt="product" className="w-[46px] h-10 object-cover" />
            <p>{title}</p>
          </div>
        )
      }
      {
        isPriece && (
          <div className="flex flex-row">
            <p>{prieceNew}</p>
            <p className="!text-wap-regular1 font-normal">/{prieceOld}</p>
          </div>
        )
      }
    </div>
  )
}
export type STATUS = null | "FINISHED" | "HAPPENING" | "NOT_HAPPEN";
function ManageVoucher(props: Props) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { currentPage } =
    useAppSelector((state) => state.vouchers);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [listIdAddvoucher, setListIdAddvoucher] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState<boolean>(false);
  const { showConfirm } = useShowConfirm();
  const { showSuccess } = useShowMessage();
  const [filterVoucher, setFilterVoucher] = useState<STATUS>(null);
  const { t, isVn } = useI18n();
  const ListVoucherFilter: { name: string, activeKey: STATUS }[] = [
    {
      name: isVn ? "Tất cả" : "모두",
      activeKey: null,
    },
    {
      name: isVn ? "Đang diễn ra" : "일어나는 일",
      activeKey: "HAPPENING",
    },
    {
      name: isVn ? "Sắp diễn ra" : "예정",
      activeKey: "NOT_HAPPEN",
    },
    {
      name: isVn ? "Kết thúc" : "끝",
      activeKey: "FINISHED",
    },
  ];

  const [dataVoucher, setDataVoucher] = useState<IVoucher[]>([]);

  const handleFilter = (value: STATUS) => {
    navigate("");
    dispatch(setCurrentPage(1));
    setFilterVoucher(value);
  };

  useEffect(() => {
    if (searchParams.get("page")) {
      setCurrentPage(Number(searchParams.get("page")));
    }
  }, [searchParams]);

  const handleAddListItem = (id: any) => {
    let newListId = [...listIdAddvoucher];
    const indexItem = listIdAddvoucher.findIndex((item) => {
      return item === id;
    });
    if (indexItem > -1) {
      newListId.splice(indexItem, 1);
    } else {
      newListId.push(id);
    }
    setListIdAddvoucher(newListId);
  }

  const handleCheckAll = (check: boolean) => {
    if (check) {
      setListIdAddvoucher(dataVoucher.map((item) => item.id ?? 0));
    } else {
      setListIdAddvoucher([]);
    }
    setCheckAll(check)
  }

  const getAllVoucher = async (currentPage: number, status: STATUS) => {
    const params = {
      page: currentPage - 1,
      size: 20,
      status: status,
      sort: 'id,desc'
    };
    try {
      const res = await VoucherServices.getAllVoucher(params);
      if (res) {
        setDataVoucher(res.content);
        setTotalPage(res.totalPages);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleConfirmRemove = () => {
    showConfirm("voucher.message.confirm.delete", handleRemoveVoucher);
  }
  const handleRemoveVoucher = async () => {
    try {
      const res = await VoucherServices.removeListVoucher(listIdAddvoucher);
      showSuccess("voucher.form.message.success.remove")
      getAllVoucher(currentPage, filterVoucher);
    } catch (error) {
      showSuccess("voucher.form.message.error.remove")
    }
  }

  useEffect(() => {
    getAllVoucher(currentPage, filterVoucher);
  }, [currentPage, filterVoucher])

  return (
    <div className="pt-9">
      <div className="flex items-center mb-2 gap-4 border-b-[2px] border-gray-200">
        {ListVoucherFilter.map((itemFilter, indexFilter) => {
          return (
            <ButtonFilter
              key={indexFilter}
              name={itemFilter.name}
              isActive={filterVoucher === itemFilter.activeKey}
              handleSubmit={() => handleFilter(itemFilter.activeKey as any)}
            />
          )
        })}
      </div>
      <div className="pt-10">
        <div className="flex justify-between items-center">
          <p className="text-normal2 font-bold font-NunitoSans text-grey-222124">
            {t("voucher.form.table.title_table")}
          </p>
          <div className="flex gap-x-6">
            <button
              disabled={listIdAddvoucher.length == 0}
              onClick={handleConfirmRemove}
              className="flex justify-center items-center gap-x-[10px] px-4 py-3 border-[1px] border-error-500 text-error-500 font-bold text-wap-regular2"
            >
              <ICDeleteTrashLight color={colors.error500} />
              <p>{t("voucher.button.delete")}</p>
            </button>
            <button
              onClick={() => navigate("add")}
              className="flex justify-center items-center gap-x-[10px] px-4 py-3 border-[1px] border-main text-main font-bold text-wap-regular2"
            >
              <ICAdd />
              <p>{t("voucher.button.add")}</p>
            </button>
          </div>

        </div>
      </div>
      <div className="flex flex-col pt-[30px] gap-y-7">
        <div className="flex flex-row">
          <button
            className="flex items-start"
          >
            <InputChecboxElement
              isCheck={checkAll}
              name="check-all"
              onHandleChange={() => handleCheckAll(!checkAll)}
              sizeBox="w-4 h-4 rounded-[4px]"
            />
          </button>
          <div className="ml-[9px] h-10 flex-1 items-start justify-between grid grid-cols-[100px_1.5fr_90px_1fr_1fr_2fr_1.5fr] gap-x-2 font-semibold">
            <ColumnHeaders title={t("voucher.form.table.voucher_code")} />
            <ColumnHeaders title={t("voucher.form.table.voucher_name")} />
            <ColumnHeaders title={t("voucher.form.table.promo_value")} />
            <ColumnHeaders title={t("voucher.form.table.total_voucher")} />
            <ColumnHeaders title={t("voucher.form.table.total_apply")} />
            <ColumnHeaders title={t("voucher.form.table.status")}/>
            <ColumnHeaders title={t("voucher.form.table.action")} />
          </div>
        </div>
        <div className="flex flex-col gap-y-7 max-h-[680px] h-[680px] overflow-y-auto">
          {(dataVoucher ?? []).map((it, idx) => {
            return (
              <div className="flex flex-row" key={idx}>
                <>
                  <button
                    className="flex items-start"
                  >
                    <InputChecboxElement
                      isCheck={listIdAddvoucher.includes(
                        it.id
                      )}
                      name={it.voucherName}
                      onHandleChange={() => {
                        it.id && handleAddListItem(it.id);
                        checkAll && setCheckAll(false);
                      }}
                      sizeBox="w-4 h-4 rounded-[4px]"
                    />
                  </button>
                </>
                <TableVoucher
                  data={it}
                />
              </div>
            )
          })}
        </div>
        <div className="pt-[30px] pb-[53px] flex justify-end">
          <Pagination
            currenPage={currentPage}
            setCurrentPage={(page: number) => dispatch(setCurrentPage(page))}
            total={totalPage}
          />
        </div>
      </div>
    </div >
  );
}

export default ManageVoucher;
