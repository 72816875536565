import { TickIcon, RemoveCartIcon } from "@assets/icons";
import AmountChange from "@components/common/AmountChange";
import { CartItemNew, useCart } from "@contexts/CartContext";
import useI18n from "@hooks/useI18n";
import ProductServices from "@services/ProductServices";
import { ROUTES } from "@utility/constants";
import { formatMoney } from "@utility/helper";
import clsx from "clsx";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";

interface Props {
  itemCart: CartItemNew,
  change?: any,
  size: string,
  border?: any,
  className?: string,
  payment?: boolean
}

const CartProductNew = ({ className, itemCart, size, border, payment }: Props) => {
  const { onChangeItem, chooseProduct } = useCart()
  const [quantityDescActive, setQuantityDescActive] = useState<boolean>(false);
  const [quantityAscActive, setQuantityAscActive] = useState<boolean>(true);
  const [quantity, setQuantity] = useState<number>(itemCart.quantitySelected);
  const { isVn } = useI18n();
  const [quantityInStock, setQuantityInStock] = useState<number>(itemCart.stockQuantity);
  const [itemChoose, setItemChoose] = useState<boolean | undefined>(true);

  useEffect(() => {
    setQuantity(itemCart.quantitySelected)
    if (itemCart.stockQuantity > 0) {
      setQuantityDescActive(true)
    } else {
      setQuantityDescActive(false)
    }

    if (itemCart.stockQuantity <= itemCart.quantitySelected) {
      setQuantityAscActive(false)
    } else {
      setQuantityAscActive(true)
    }
  }, [itemCart.stockQuantity])


  useEffect(() => {
    setItemChoose(itemCart.choose)
  }, [itemCart.choose])

  useEffect(() => {
    if (quantityInStock <= 0) {
      chooseProduct(itemCart.productDetailId, false)
      setItemChoose(false)
    }
  }, [itemChoose])

  const handleIncrease = () => {
    if (quantity + 1 <= itemCart.stockQuantity && quantityInStock > 0) {
      setQuantity((prev) => 1 + prev);
      itemCart.quantitySelected += 1
      onChangeItem(itemCart.productDetailId, itemCart)
      setQuantityDescActive(true)
      if (quantity + 1 == itemCart.stockQuantity) {
        setQuantityAscActive(false)
      }
    }
  };

  const handleDecrease = () => {
    setQuantity((prev) => prev - 1);
    itemCart.quantitySelected -= 1
    onChangeItem(itemCart.productDetailId, itemCart)
  };

  const handleRemoveFromCart = () => {
    itemCart.quantitySelected = 0
    onChangeItem(itemCart.productDetailId, itemCart)
  }

  const handleChecked = (e: any) => {
    chooseProduct(itemCart.productDetailId, e.target.checked)
  }

  return (
    <div className={`${quantityInStock == 0 ? "opacity-[0.4]" : ""} w-full`} >
      <div className={`${className} ${size == 'sm' && 'py-6'} ${size == 'cart' && 'py-4'} py-5 flex items-center `}>
        <div
          className={clsx({
            "gap-6": size == 'sm',
            "gap-4": size == 'cart',
            "gap-8": size == 'normal',

          }, `flex-1 flex flex-row`)}
        >

          {
            size === 'normal' && <div className="grid grid-cols-[300px_200px_100px_100px_80px_100px] items-center justify-between pl-1 w-full">
              <div className="flex items-center gap-2">
                {
                  !payment && <div>
                    <label>
                      <input
                        className="hidden"
                        type="checkbox"
                        checked={itemChoose}
                        onChange={handleChecked}
                        disabled={quantityInStock <= 0}
                      />
                      <div
                        className={`w-6 sm:w-7 p-1 aspect-square border-2 flex items-center justify-center rounded-sm ${itemChoose ? "bg-main" : ""
                          }`}
                      >
                        {<TickIcon />}
                      </div>
                    </label>
                  </div>
                }
                <img
                  className={`false max-w-[64px] lg:w-[240px] aspect-square object-contain pr-2 border-r-2`}
                  src={itemCart.imageDetailUrl}
                  alt=""
                />
                <Link to={ROUTES.product.detail(itemCart.productId)} className={clsx("hover:cursor-pointer text-normal text-black line-clamp-2")}>
                  {isVn ? itemCart.productDetailNameVn : itemCart.productDetailNameKr}
                </Link>
              </div>
              <span className={`text-[#939393] line-clamp-2`}>{`${itemCart.addressWarehouse}, ${isVn ? itemCart.attributes.map((it: any) => it.valueVn).join(', ') : itemCart.attributesKr.map((it: any) => it.valueKr).join(', ')}`}</span>
              <div className="text-main font-bold mt-1">{formatMoney(itemCart.actualPriceDetail)}</div>
              <AmountChange
                className="text-wap-regular2"
                quantity={quantity}
                descActive={quantityDescActive}
                ascActive={quantityAscActive}
                handleIncrease={handleIncrease}
                handleDecrease={handleDecrease}
              />
              <div className="text-main font-bold mt-1">{formatMoney(itemCart.actualPriceDetail * itemCart.quantitySelected)}</div>
              <div className="flex justify-end">
                <RemoveCartIcon onClick={handleRemoveFromCart} fill={`${quantityInStock <= 0 ? "#EE0000" : ""}`} />
              </div>
            </div>
          }

          {size == 'cart' &&
            <div className="w-full flex">
              <div className="flex justify-between items-center pl-1 gap-2 ">
                {
                  !payment && <div>
                    <label>
                      <input
                        className="hidden"
                        type="checkbox"
                        checked={itemChoose}
                        onChange={handleChecked}
                        disabled={quantityInStock <= 0}
                      />
                      <div
                        className={`w-6 sm:w-7 p-1 aspect-square border-2 flex items-center justify-center rounded-sm ${itemChoose ? "bg-main" : ""
                          }`}
                      >
                        {<TickIcon />}
                      </div>
                    </label>
                  </div>
                }
                <img
                  className={`false max-w-[64px] lg:w-[240px] aspect-square object-contain lg:mt-7 pr-2 border-r-2`}
                  src={itemCart.imageDetailUrl}
                  alt=""
                />
              </div>
              <div className="flex flex-col ml-2 w-full">
                <div className="flex flex-col">
                  <Link to={ROUTES.product.detail(itemCart.productId)} className={clsx("hover:cursor-pointer text-normal text-black line-clamp-2", { "mb-1 text-wap-regular2": size == 'cart' })}>
                    {isVn ? itemCart.productDetailNameVn : itemCart.productDetailNameKr}
                  </Link>
                  <span className={`text-[#939393] line-clamp-2`}>{`${itemCart.addressWarehouse}, ${isVn ? itemCart.attributes.map((it: any) => it.valueVn).join(', ') : itemCart.attributesKr.map((it: any) => it.valueKr).join(', ')}`}</span>
                  <div className="text-main font-bold mt-1">{formatMoney(itemCart.actualPriceDetail * itemCart.quantitySelected)}</div>
                </div>
                <div className="flex mt-2 justify-between items-center">
                  <AmountChange
                    className="text-wap-regular2"
                    quantity={quantity}
                    descActive={quantityDescActive}
                    ascActive={quantityAscActive}
                    handleIncrease={handleIncrease}
                    handleDecrease={handleDecrease}
                  />
                  <RemoveCartIcon onClick={handleRemoveFromCart} fill={`${quantityInStock <= 0 ? "#EE0000" : ""}`} />
                </div>
              </div>
            </div>
          }

          {size == 'sm' &&
            <div className="w-full flex items-center">
              <img
                className={`false max-w-[64px] lg:w-[240px] aspect-square object-contain pr-2 border-r-2`}
                src={itemCart.imageDetailUrl}
                alt=""
              />
              <div className="flex flex-col ml-2 w-full">
                <div className="flex flex-col">
                  <Link to={ROUTES.product.detail(itemCart.productId)} className={clsx("hover:cursor-pointer text-normal text-black line-clamp-2")}>
                    {isVn ? itemCart.productDetailNameVn : itemCart.productDetailNameKr}
                  </Link>
                  <span className={`text-[#939393] line-clamp-2`}>{`${itemCart.addressWarehouse}, ${isVn ? itemCart.attributes.map((it: any) => it.valueVn).join(', ') : itemCart.attributesKr.map((it: any) => it.valueKr).join(', ')}`}</span>
                  <div className="text-main font-bold mt-1 flex justify-between">
                    <div>x {itemCart.quantitySelected}</div>
                    <div >{formatMoney(itemCart.actualPriceDetail * itemCart.quantitySelected)}</div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
      {quantityInStock <= 0 && <div className="font-bold text-main">{isVn ? "Hết hàng" : "품절"}</div>}
    </div>
  );
};

export default CartProductNew;
