import React from "react";
import { colors } from "@utility/colors";
import { IconProps } from "@utility/types";

export const ICSuccessMessage = ({
  width = 106,
  height = 106,
  color = colors.success,
}: IconProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 106 106"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M38 53L48 63L70.5 40.5M29.6688 12.0935C33.688 11.7727 37.5036 10.1923 40.5723 7.5771C47.7336 1.4743 58.2664 1.4743 65.4277 7.5771C68.4964 10.1923 72.312 11.7727 76.3312 12.0935C85.7103 12.8419 93.1581 20.2897 93.9065 29.6688C94.2273 33.688 95.8077 37.5036 98.4229 40.5723C104.526 47.7336 104.526 58.2664 98.4229 65.4277C95.8077 68.4964 94.2273 72.312 93.9065 76.3312C93.1581 85.7103 85.7103 93.1581 76.3312 93.9065C72.312 94.2273 68.4964 95.8077 65.4277 98.4229C58.2664 104.526 47.7336 104.526 40.5723 98.4229C37.5036 95.8077 33.688 94.2273 29.6688 93.9065C20.2897 93.1581 12.8419 85.7103 12.0935 76.3312C11.7727 72.312 10.1923 68.4964 7.5771 65.4277C1.4743 58.2664 1.4743 47.7336 7.5771 40.5723C10.1923 37.5036 11.7727 33.688 12.0935 29.6688C12.8419 20.2897 20.2897 12.8419 29.6688 12.0935Z"
        stroke={color}
        strokeWidth="6"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
