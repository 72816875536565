import RightArrow from "@assets/iconElements/RightArrow";
import CricleButton from "@components/Buttons/CricleButton";
import useI18n from "@hooks/useI18n";
import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import SwiperComponent from "commons/SwiperComponent";
import React, { memo } from "react";
import { Link } from "react-router-dom";
import { Autoplay, Navigation, Pagination } from "swiper";
import { SwiperSlide } from "swiper/react";
interface Props {
  item?: any,
  stt: number
}
// const images = [
//   "https://cdn-images.kiotviet.vn/newsport700/f289fdb9aa724218a4ba44110af7a27c.jpg",
//   "https://cdn-images.kiotviet.vn/newsport700/5f968997b68640a3a705b546e4291499.jpg",
//   "https://supershop.vn/api/image/1691138373713",
//   "https://supershop.vn/api/image/1691149478543",
// ];
const CartISlideImage = memo(({ item, stt }: Props) => {
  const {
    navigationPrevRef,
    navigationNextRef,
    handleNext,
    handlePre,
    NavigationElement,
    currentIndex,
    onActiveIndexChange,
    activeThumb,
    setThumbActive,
  } = useSwiperNavigationRef();

  const { isVn, t } = useI18n();

  return (
    <div className="p-3 lg:pt-6 bg-white flex flex-col sm:gap-4 gap-2 rounded-[20px] max-sm:rounded-[8px] shadow-shd020 xl:h-[460px] max-md:h-[250px]">
      <div className="flex items-center justify-between xl:my-1 ">
        <span className="font-bold xl:text-header2 text-xl text-gray-400">
          {stt + 1 < 10 && "0"}
          {stt + 1}
        </span>
        <Link to={`/product/${item?.id}`}>
          <CricleButton
            // disabled={loading || currentPage >= totalPage}
            className="xl:w-12 xl:h-12 w-spc30 h-spc30 z-max"
            onClick={() => handleNext()}
            icon={<RightArrow />}
          /></Link>
      </div>
      <Link to={`/product/${item?.id}`}>

        <p className="xl:text-lg text-lg font-bold line-clamp-2 hover:text-main max-sm:text-[16px] ">
          <p className="max-sm:leading-[20px] max-sm:text-[14px]"> {isVn ? item.productNameVn : item.productNameKr}</p>
        </p>  </Link>
      <div className="flex justify-center  xl:h-[280px] lg:h-[200px] md:h-[200px] sm:h-[140px] h-[105px]">
        <img
          src={item.image}
          alt=""
          className="xl:h-[280px] rounded-[12px] w-fit  h-full object-contain shadow-shd020"
        />
        {/* {NavigationElement} */}
      </div>
    </div>
  );
});

export default CartISlideImage;
