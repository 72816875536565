import { IconFeaturedProduct, SwiperSlideButton } from "@assets/icons";
import { ToastContex } from "@contexts/ToastContex";
import useI18n from "@hooks/useI18n";
import ProductServices from "@services/ProductServices";
import { ProductDetails } from "@services/Types/product";
import { formatMoney } from "@utility/helper";
import InputChecboxElement from "commons/components/InputComponent/InputChecboxElement";
import { Fragment, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  item: any;
  isCheck: boolean;
  handleDelete: (id: string) => void;
  handleDeleteOneProduct: (id: string) => void;
  showEditLink?: boolean;
}

function ProducSizeItem(props: Props) {
  const { item, isCheck, showEditLink = true, handleDelete, handleDeleteOneProduct } = props;
  const navigator = useNavigate();
  const { isVn, t } = useI18n();
  const [showAll, setShowAll] = useState(false);
  const [isFeature, setIsFeature] = useState<number>(item.featured)
  const { onAddToast } = useContext(ToastContex);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const handleEditProduct = (id: number | string) => {
    navigator(`/admin/product/edit/${id}`);
  };

  const handleSetFeatureProduct = async () => {
    const value = isFeature == 1 ? 0 : 1;
    try {
      await ProductServices.putFeaturedProductById(item.id, value);
      setIsFeature(value)
      onAddToast({ type: "success", message: t("success.updated") })
    } catch (error) {
      onAddToast({ type: "error", message: t("error.update_error") })
    }
  }

  const itemsToRender = showAll ? item.productDetails : item.productDetails.slice(0, 2);

  if (item) {
    return (
      <Fragment>
        <div className="flex items-center border-2 rounded-t-md mb-4">
          <div className="flex items-center pl-4 pr-7 w-2/5 h-full">
            <InputChecboxElement
              isCheck={isCheck}
              name={isVn ? item.productNameVn : item.productNameKr}
              onHandleChange={() => {
                item.id && handleDelete(item.id);
              }}
              sizeBox="w-4 h-4 rounded-[4px] mb-10"
            />

            <div className="flex items-center flex-wrap pl-4">
              <div className="flex items-center w-full ">
                <img
                  src={item.images && item.images.length > 0 ? item.images[0].url : ""}
                  alt={isVn ? item.productNameVn : item.productNameKr}
                  className="w-[72px] h-[72px] border border-gray-200 rounded-[10px] mr-3 shadow"
                />
                <div className="flex flex-col gap-1">
                  <div className="italic">
                    {item.productCode}
                  </div>
                  <div className="font-bold">
                    {isVn ? item.productNameVn : item.productNameKr}
                  </div>
                  <div className="flex">
                    <IconFeaturedProduct className="cursor-pointer" fill={isFeature == 1 ? "#FF7D03" : "none"} onClick={handleSetFeatureProduct} />
                    <span className="pl-1 text-small">{t("text.manage_product.select_featured_product")}</span>
                  </div>
                </div>
              </div>
              <div className="w-full">
                <div className="w-[72px] flex justify-between">
                  {showEditLink && (
                    <p
                      className="font-semibold text-small text-main mt-6 cursor-pointer"
                      onClick={() => handleEditProduct(item.id!)}
                    >
                      {t("text.manage_product.edit")}
                    </p>
                  )}
                  {showEditLink && (
                    <p
                      className="font-semibold text-small text-main mt-6 cursor-pointer"
                      onClick={() => handleDeleteOneProduct(item.id!)}
                    >
                      {t("text.manage_product.delete")}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className={`w-3/5 ${itemsToRender.length == 1 ? "mb-[10%]" : "mb-10"}`}>
            {itemsToRender.map((detail: ProductDetails, index: number) => {
              return (
                <div className="flex font-bold">
                  <div className="flex border-b-2 border-l-2 w-1/5 justify-center p-5 text-sm">
                    <span>{detail.attributes.map((it) => isVn ? it.valueVn : it.valueKr).join(" - ")}</span>
                  </div>
                  <div className="flex border-b-2 border-l-2 w-1/5 justify-center p-5 text-sm">
                    {formatMoney(detail.priceDetail)}
                  </div>
                  <div className="flex border-b-2 border-l-2 w-1/5 justify-center p-5 text-sm text-center">
                    {detail.addressWarehouse}
                  </div>
                  <div className="flex border-b-2 border-l-2 w-1/5 justify-center p-5 text-sm">
                    {detail.stockQuantity}
                  </div>
                  <div className="flex border-b-2 border-l-2 w-1/5 justify-center p-5 text-sm">
                    {detail.soldQuantity}
                  </div>
                </div>
              )
            })}

            {
              item.productDetails.length > 2 && <div onClick={toggleShowAll} className="flex border border-[#F45538] rounded-md text-sm font-bold mt-7 w-[30%] justify-between items-center  cursor-pointer">
                <span className="pl-1">{showAll ? t("text.manage_product.hide") : t("text.manage_product.text_attribute_other", { number: item.productDetails.length - 2 })}</span>
                <SwiperSlideButton width={8} className={`${showAll ? "-rotate-90" : "rotate-90"} mr-2`} />
              </div>
            }
          </div>
        </div>
      </Fragment>
    );
  } else {
    return null;
  }
}

export default ProducSizeItem;
