import { ProvinceType, initProvince } from "@components/LocationComponent/LocationBox";
import { createSlice } from "@reduxjs/toolkit";
const initialState = initProvince
const provinceSlice = createSlice({
  name: 'provinceChoose',
  initialState,
  reducers: {
    update: (state, action) => {
      state.Id = action.payload.Id
      state.name = action.payload.name
    }
  }
})


export const { update } = provinceSlice.actions
export default provinceSlice.reducer
