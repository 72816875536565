import { some } from "@utility/helper";
import api from "./api";
import { data } from "autoprefixer";
import { IRespone } from "./Types/respone";

const apiPolicy = "/policy"
export type DataPolicy = {
    id: number,
    titleKr: string,
    titleVn: string,
    contentKr: string,
    contentVn: string,
    describeKr: string,
    describeVn: string
}

export interface PagePolicy {
    content: DataPolicy[],
    size: number,
    totalElements: number,
    totalPages: number
}

const PolicyServices = {
    post: async (data: some) => {
        return api.post(apiPolicy, data)
    },
    // get: async (option: some) => {
    //     return api.get(apiPolicy, {
    //         params: {
    //             page: option.page - 1 >= 0 ? option.page - 1 : 0,
    //             size: option.limit,
    //             key: option.key,
    //             sort: `createdAt,${option.sort ? option.sort : 'desc'}`
    //         }
    //     })
    // },
    get: async (option: some) => {
        return api.get(apiPolicy)
    },
    getById: async (id: string | undefined) => {
        return api.get(apiPolicy + `/${id}`)
    }
    ,
    put: async (id: string, data: some) => {
        return api.put(apiPolicy + `/${id}`, data)
    },
    delete: async (id: string) => {
        return api.delete(apiPolicy + `/${id}`)
    },
    getAll: async (): Promise<PagePolicy> => {
        // let categoryPath = pathName
        // if(param){
        //     categoryPath = `${categoryPath}?${queryString.stringify(param)}`;
        // }
        return await api.get(apiPolicy);
    },
    getByIdPublic: async (id: string | undefined): Promise<DataPolicy> => {
        return api.get(apiPolicy + `/${id}`)
    }
}


export default PolicyServices;