import React, { memo, useEffect, useState } from "react";
import SlideProductPaginate from "./SlideProductPaginate";
import clsx from "clsx";
import ProductServices, { ProductItem } from "@services/ProductServices";
import { useSwiperNavigationRef } from "@hooks/useSwiperNavigationRef";
import TitleSession from "@components/common/TitleSession";
import { PaginationCompt } from "commons/Paginnation";
import { IRespone } from "@services/Types/respone";

interface Props {
  isbg?: boolean;
  className?: string;
  categoryId?: string
}

const ProductSpecial = memo(({ isbg = true, className, categoryId }: Props) => {
  const [listproducts, setListProducts] = useState<ProductItem[]>([]);
  const [totalElements, setTotalElements] = useState<number>(0);
  const [totalPage, setTotalPage] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(false);

  const [listproduct, setListProduct] = useState<IRespone[]>([]);

  const callApi = async () => {
    try {
      const json = localStorage.getItem("province");
      setLoading(true);
      const result = await ProductServices.getProductFeatured({
        page: currentPage - 1,
        size: 12,
        categoryId: categoryId !== undefined ? categoryId : "",
        address: json === null ? "Thành phố Hà Nội" : JSON.parse(json).name
      });
      setListProduct(result.content);
      setTotalPage(result.totalPages);
      setLoading(false);
    } catch (error) { }
  };

  useEffect(() => {
    callApi();
  }, [currentPage, categoryId]);

  const breackPoinSlide = {
    360: {
      slidesPerView: 2.6,
      spaceBetween: 8,
    },
    480: {
      slidesPerView: 3.6,
      spaceBetween: 8,
    },
    640: {
      slidesPerView: 3.6,
      spaceBetween: 12,
    },
    992: {
      slidesPerView: 4.6,
      spaceBetween: 8,
    },
    1024: {
      slidesPerView: 4.6,
      spaceBetween: 12,
    },
    1280: {
      slidesPerView: 4.6,
      spaceBetween: 20,
    },
  };
  const breackPoinIntro = {
    350: {
      slidesPerView: 2.4,
      spaceBetween: 8,
    },
    480: {
      slidesPerView: 3.4,
      spaceBetween: 8,
    },
    640: {
      slidesPerView: 3.4,
      spaceBetween: 12,
    },
    992: {
      slidesPerView: 4.4,
      spaceBetween: 8,
    },
    1024: {
      slidesPerView: 4.4,
      spaceBetween: 12,
    },
    1280: {
      slidesPerView: 3.4,
      spaceBetween: 20,
    },
    1536: {
      slidesPerView: 3.4,
      spaceBetween: 20,
    },
  };

  return (
    <div className={className}>
      <div className="relative min-h-spc300 pt-5  ">
        <div
          className={clsx("absolute top-0 right-0 w-full -z--1", {
            "bg-footer h-2/3 max-sm:h-[168px] ": isbg,
          })}
        ></div>
        <div className="flex relative z-1 items-center justify-between product_box py-10 max-sm:py-1">
          <TitleSession
            isBox={true}
            text="text.section.special_product"
            className={`uppercase  w-full font-bold ${isbg ? "text-white" : ""} `}
          />
          <div className="shadow rounded-lg xl:block">
            <PaginationCompt
              currentPage={currentPage == 0 ? currentPage + 1 : currentPage}
              totalPages={totalPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        </div>
        <div
          className={clsx("absolute top-10 left-[1/3] w-full -z--1", {
            "bg-aqua-aq03 h-3/4": !isbg,
          })}
        ></div>
        <div
          className={clsx("z-1 relative product_left ", {
            "flex flex-wrap justify-between mt-4": !isbg,
            "pt-[22px]": isbg
          })}
        >

          {!isbg && (
            <div className="xl:pl-1 xl:w-[340px] w-full  mt-5">
              <p className="text-2xl font-bold text-main mb-5">
                모아밥솥 12A032 1.2L
              </p>
              <ul className="flex flex-col gap-3 list-disc pl-6">
                <li>1.2L 용량으로 2~4인이 사용하기에 적합합니다</li>
                <li>
                  건강을 위해 안전한 고품질 붙지 않는 입히는 알루미늄 합금 남비.
                </li>
                <li>
                  모던하고 고급스러운 디자인, 우아한 색상, 심플하고 편리한
                  컨트롤 버튼.
                </li>
                <li>
                  다방면의 보온으로 밥맛이 좋아지고 더 오래 보온됩니 다방면의
                  보온으로 밥맛이 좋아지고 더 오래 보온됩니
                </li>
                <li>일본 기술 - 베트남산!</li>
              </ul>
            </div>
          )}
          <div
            className={clsx("", {
              "xl:w-[calc(100%_-_360px)] w-full ": !isbg,
              "pt-[22px]": !isbg,
            })}
          >
            <SlideProductPaginate
              slideItems={listproduct}
              breackPoin={isbg ? breackPoinSlide : breackPoinIntro}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default ProductSpecial;
