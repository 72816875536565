import { SearchActive, SearchIcon } from "@assets/icons";
import useFocusOut from "@hooks/useFocusOut";
import useI18n from "@hooks/useI18n";
import SearchDropdown from "@layout/components/SearchDropDown";
import { ROUTES } from "@utility/constants";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// interface Props {
//   className?: string;
//   placeHolder?: string;
//   value: string;
//   onChange: (event: ChangeEvent<HTMLInputElement>) => void;
// }

const HeaderSearch = () => {
  const { t } = useI18n();
  const { clickShow, setClickShow, ref: searchRef } = useFocusOut();
  const [keyword, setkeyword] = useState("");
  const [show, setShow] = useState<Boolean>(true)
  const navigate = useNavigate();



  const onChange = (e: any) => {
    setkeyword(e.target.value)
    console.log(e.target.value.trim() !== "")
    if (e.target.value.trim() !== "") {
      setShow(false)
    } else
      setShow(true)
    setClickShow(true)
  }
  const handClick = () => {
    navigate("/search/" + keyword)
    setClickShow(false)
  }
  const handleKeyword = (item: any) => {
    setkeyword(item)
  }
  return (
    <div className="relative flex justify-start" ref={searchRef}>
      {clickShow && (
        <SearchDropdown keyword={keyword} setKeyword={handleKeyword} close={() => setClickShow(false)} show={show} />
      )}
      <div className={`relative`}>
        <input
          value={keyword}
          onChange={(e: any) => onChange(e)}
          className={`rounded-full h-[32px] 1.5xl:w-[562px]  max-sm:hidden outline-none border-main focus:border text-wap-regular2 pl-5 pr-20`}
          placeholder={t("header.search.placehoder") as string}
          onFocus={() => setClickShow(true)}

        />
        <SearchIcon className="absolute top-[0px] right-[10px] z-10 max-sm:hidden cursor-pointer" onClick={handClick} />

        <Link
          to={`${ROUTES.search.sub}`}
          className="flex justify-center items-center top-0 h-full xl:absolute right-0 pl-5 sc480:pr-5 pr-0 bg-transparent"
        >
          <SearchActive className="hidden max-sm:block" />
          {/* <SearchDropdown keyword={keyword} close={() => setClickShow(false)} /> */}
        </Link>
      </div>
    </div>
  );
};

export default HeaderSearch;
