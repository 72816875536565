import React from 'react'

const PasswordIcon = ({ hover }: { hover: boolean }) => {
    return (
        <svg className='ml-[7px]' xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 20 25" fill={hover ? '#FF7D03' : 'black'}>
            <path d="M10 19.0476C9.33696 19.0476 8.70107 18.7968 8.23223 18.3503C7.76339 17.9037 7.5 17.2981 7.5 16.6667C7.5 15.3452 8.6125 14.2857 10 14.2857C10.663 14.2857 11.2989 14.5366 11.7678 14.9831C12.2366 15.4296 12.5 16.0352 12.5 16.6667C12.5 17.2981 12.2366 17.9037 11.7678 18.3503C11.2989 18.7968 10.663 19.0476 10 19.0476ZM17.5 22.619V10.7143H2.5V22.619H17.5ZM17.5 8.33333C18.163 8.33333 18.7989 8.58418 19.2678 9.0307C19.7366 9.47721 20 10.0828 20 10.7143V22.619C20 23.2505 19.7366 23.8561 19.2678 24.3026C18.7989 24.7491 18.163 25 17.5 25H2.5C1.83696 25 1.20107 24.7491 0.732233 24.3026C0.263392 23.8561 0 23.2505 0 22.619V10.7143C0 9.39286 1.1125 8.33333 2.5 8.33333H3.75V5.95238C3.75 4.37371 4.40848 2.8597 5.58058 1.74341C6.75269 0.627124 8.3424 0 10 0C10.8208 0 11.6335 0.153963 12.3918 0.453098C13.1501 0.752233 13.8391 1.19068 14.4194 1.74341C14.9998 2.29614 15.4602 2.95233 15.7742 3.6745C16.0883 4.39668 16.25 5.1707 16.25 5.95238V8.33333H17.5ZM10 2.38095C9.00544 2.38095 8.05161 2.75723 7.34835 3.427C6.64509 4.09677 6.25 5.00518 6.25 5.95238V8.33333H13.75V5.95238C13.75 5.00518 13.3549 4.09677 12.6517 3.427C11.9484 2.75723 10.9946 2.38095 10 2.38095Z" fill={hover ? '#FF7D03' : 'black'} />
        </svg>

    )
}

export default PasswordIcon